export default {
    values: [
      {value:"new",                text:{en:"New",               cn:"未结算",  }},  
      {value:"request",            text:{en:"Request Pay",       cn:"申请结算",  }},  
      {value:"finish",             text:{en:"Paid",              cn:"已结算"  }},
    ],
    kinds: [
      {value:"P",                text:{en:"增值税普通发票",              cn:"增值税普通发票",  }},  
      {value:"Z",                text:{en:"增值税专用发票",              cn:"增值税专用发票"  }},
    ],
  };
  