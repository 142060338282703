function showDialog(current, dialogRef, schema, value, callback) {
  if (schema["externalAction"] == "ownerId") {
    showUser(current, dialogRef, schema, value, callback);
  } else if (schema["externalAction"] == "accountId") {
    showAccount(current, dialogRef, schema, value, callback);
  } else if (schema["externalAction"] == "contactId") {
    showContact(current, dialogRef, schema, value, callback);
  } else if (schema["externalAction"] == "opportunityId") {
    showOpportunity(current, dialogRef, schema, value, callback);
  } else if (schema["externalAction"] == "campaignId") {
    showCampaign(current, dialogRef, schema, value, callback);
  } else if (schema["externalAction"] == "leadsId") {
    showLeads(current, dialogRef, schema, value, callback);
  }
}

function showAccount(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "name",
      value: "name",
      type: "string",
      required: true,
      searchable: true,
    },
    {
      name: "website",
      value: "website",
      type: "string",
      searchable: true,
    }
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/accounts", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["name"]
      })
    })
    callback(value["id"]);
  });
}

function showUser(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "nickname",
      value: "nickname",
      type: "string",
      searchable: true,
    },
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "name",
      value: "name",
      type: "string",
      required: true,
      searchable: true,
    },
    {
      name: "email",
      value: "email",
      type: "string",
      searchable: true,
    }
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/users", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["nickname"]
      })
    })
    callback(value["id"]);
  });
}

function showContact(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "fullName",
      value: "fullName",
      type: "string",
      required: true,
    },
    {
      name: "email",
      value: "email",
      type: "string",
      required: true,
    },
    {
      name: "firstName",
      value: "firstName",
      type: "string",
      searchable: true,
      show: false,
    },
    {
      name: "lastName",
      value: "lastName",
      type: "string",
      show: false,
      searchable: true,
    }
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/contacts", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["fullName"]
      })
    })
    callback(value["id"]);
  });
}

function showOpportunity(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "name",
      value: "name",
      type: "string",
      required: true,
      searchable: true,
    },
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/opportunities", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["name"]
      })
    })
    callback(value["id"]);
  });
}

function showLeads(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "name",
      value: "name",
      type: "string",
      required: true,
      searchable: true,
    },
    {
      name: "fullName",
      value: "fullName",
      type: "string",
      required: true,
    },
    {
      name: "email",
      value: "email",
      type: "string",
      required: true,
    },
    {
      name: "firstName",
      value: "firstName",
      type: "string",
      searchable: true,
      show: false,
    },
    {
      name: "lastName",
      value: "lastName",
      type: "string",
      show: false,
      searchable: true,
    }
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/leadses", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["name"]
      })
    })
    callback(value["id"]);
  });
}

function showCampaign(current, dialogRef, schema, value, callback) {
  let userSchemas = [
    {
      name: "id",
      value: "id",
      show: false,
      type: "string",
      required: true,
    },
    {
      name: "name",
      value: "name",
      type: "string",
      required: true,
      searchable: true,
    },
  ];

  current.localizeSchema(userSchemas);
  dialogRef.open("/api/campaigns", [], userSchemas, (value, items) => {
    schema.dropdown = [];
    items.forEach((item) => {
      schema.dropdown.push({
        value: item["id"],
        text: item["name"]
      })
    })
    callback(value["id"]);
  });
}

export default showDialog;