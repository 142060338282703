export default {
  types: [
    {
      value: "user",
      text: {
        cn: "用户",
        en: "User",
      },
    },
    {
      value: "lawyer",
      text: {
        cn: "律师",
        en: "Lawyer",
      },
    },
  ],
};
