module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "expiredTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "expiredContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session has timed out. Click \"OK\" to login again."])}
      },
      "cn": {
        "expiredTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接超时"])},
        "expiredContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接超时. 点击\"确定\"重新登录."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"expiredTitle":"Timeout","expiredContent":"Your session has timed out. Click \\"OK\\" to login again."},"cn":{"expiredTitle":"连接超时","expiredContent":"连接超时. 点击\\"确定\\"重新登录."}}')
  delete Component.options._Ctor
  
}
