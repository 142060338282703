<template>
  <v-container fluid>
    <div class="main-container" fill-height>
      <entity-view
        :item-schemas="itemSchemas"
        :items="items"
        :title="$t('notice')"
        :pagination="pagination"
        :externalActions="externalActions"
        :options="options"
        @items-refresh="itemsRefresh"
        @item-action="itemAction"
        @page-change="pageChange"
        @double-click-row="clickRow"
      >
      </entity-view>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5 ml-6">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <form-view
            :itemSchemas="itemSchemas"
            :reverse="false"
            :colPerRow="colPerRow"
            ref="dialogView"
          >
          </form-view>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog = false;
              save(action);
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("delete_description", [this.selectItem["id"]]) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              deleteDialog = false;
              remove();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import FormView from "../components/FormView.vue";
import NoticeDropDown from "../common/notice_dropdown";

export default {
  components: { EntityView, FormView },
  data: () => ({
    title: "Product",
    select: true,
    expendOpen: false,
    readonly: true,
    dialog: false,
    dialogTitle: "Insert",
    deleteDialog: false,
    selectItem: "",
    action: "insert",
    colPerRow: 1,
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
        readonly: true,
      },
      {
        name: "message",
        value: "message",
        type: "string",
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: NoticeDropDown.types,
        localized: true,
      },
      {
        name: "startAt",
        value: "startAt",
        type: "date",
      },
      {
        name: "endAt",
        value: "endAt",
        type: "date",
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "datetime",
      },
    ],
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: true,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let postData = {
        pagination: this.pagination,
        orders: [
          {
            column: "created_at",
            order: "desc"
          }
        ]
      };
      this.post("/api/notices", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.itemUpdate(item);
    },
    remove() {
      let _this = this;
      let option = {
        callback: function () {
          _this.refresh();
        },
      };
      this.delete("/api/notice/" + this.selectItem.id, option);
    },
    itemCreate() {
      this.dialog = true;
      this.action = "insert";
      this.dialogTitle = this.$t("create");
    },
    itemUpdate(item) {
      this.selectItem = item;
      this.$set(this, "dialog", true);
      this.action = "update";
      this.dialogTitle = this.$t("update");
      this.$nextTick(() => {
        this.$refs.dialogView.loadEntity(item);
      });
    },
    itemDelete(item) {
      this.selectItem = item;
      this.deleteDialog = true;
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name, item) {
      if (name === "insert") {
        this.itemCreate();
      } else if (name === "update") {
        this.itemUpdate(item);
      }
      if (name === "delete") {
        this.itemDelete(item);
      }
    },
    save(action) {
      let _this = this;
      let entity = this.$refs.dialogView.commitEntity();
      let option = {
        callback: function () {
          _this.refresh();
        },
      };
      if (action == "insert") {
        this.post("/api/notice/", entity, option);
      } else if (action == "update") {
        this.put("/api/notice/" + this.selectItem.id, entity, option);
      }
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
  "en": {
    "notice": "Notice",
    "c_message": "Message",
    "c_startAt": "Start At",
    "c_endAt": "End At"
  },
  "cn": {
    "notice": "通知",
    "c_message": "通知内容",
    "c_startAt": "开始生效时间",
    "c_endAt": "结束生效时间"
  }
}
</i18n>
