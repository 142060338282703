<template>
  <v-card class="form-container" ref="account">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="editMode"
        color="primary"
        @click="save('editMode', $refs.mainView)"
      >
        {{ $t("save") }}
      </v-btn>
      <v-btn v-if="!editMode" color="primary" @click="edit('editMode')">
        {{ $t("edit") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <form-view
        :itemSchemas="itemSchemas"
        :readonly="!editMode"
        @item-action="itemAction"
        ref="mainView"
      ></form-view>
    </v-card-text>
  </v-card>
</template>

<script>
import FormView from "../components/FormView.vue";
import CommonTypes from "../common/common_type";

export default {
  components: { FormView },
  props: {
    id:{},
  },
  data: () => ({
    title: "SystemSetting",
    entity: {},
    editMode: true,
    itemSchemas: [
      {
        name: "smtpServer",
        value: "smtpServer",
        type: "string",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "smtpPort",
        value: "smtpPort",
        type: "string",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "useSsl",
        value: "useSsl",
        type: "dropdown",
        localized: true,
        dropdown: CommonTypes.booleans
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "sendMailAccount",
        value: "sendMailAccount",
        type: "string",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "sendMailPassword",
        value: "sendMailPassword",
        type: "password",
      },
    ],
  }),
   mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {},
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      this.get("/api/settings",
        {
          callback: function (data) {
            _this.$refs.mainView.loadEntity(data.data);
          },
        }
      );
    },
    itemAction(schema, value, callback) {
      console.log(schema, value, callback);
      // showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
    save() {
      // let _this = this;
      let entity = this.$refs.mainView.commitEntity();
      let option = {
        callback: function () {
          // _this.$refs.mainView.loadEntity(data.data);
        },
      };
      this.post("/api/setting/", entity, option);
    },
  }
}
</script>

<i18n>
{
  "en": {
    "c_smtpServer": "SMTP Server",
    "c_smtpPort": "SMTP Port",
    "c_useSsl": "Use SSL",
    "c_sendMailAccount": "Account",
    "c_sendMailPassword": "Password"
  },
  "cn": {
    "c_smtpServer": "SMTP服务器",
    "c_smtpPort": "SMTP端口",
    "c_useSsl": "使用SSL",
    "c_sendMailAccount": "用户名",
    "c_sendMailPassword": "密码"
  }
}
</i18n>
