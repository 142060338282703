module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接单律师"])}
      },
      "cn": {
        "c_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接单律师"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_enable":"接单律师"},"cn":{"c_enable":"接单律师"}}')
  delete Component.options._Ctor
  
}
