<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <!-- <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ entity.fullName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn
                color="primary mr-4"
                fab
                small
                @click="$router.go(-1)"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              <!-- <v-dialog
                v-if="!isForbidden"
                v-model="forbiddenDialog"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="warning ml-4" v-bind="attrs" v-on="on">
                    {{ $t("forbidden") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("forbidden_title") }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t("forbidden_description", [this.entity["name"]]) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="forbiddenDialog = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="
                        forbiddenDialog = false;
                        forbidden(true);
                      "
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-if="isForbidden"
                v-model="revertDialog"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="warning ml-4" v-bind="attrs" v-on="on">
                    {{ $t("revert") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("revert_title") }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t("revert_description", [this.entity["name"]]) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="revertDialog = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="
                        revertDialog = false;
                        forbidden(false);
                      "
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="ma-4" ref="cart">
          <cart-part :userId="id"></cart-part>
        </v-row>
        <v-row class="ma-4" ref="order">
          <order-part :userId="id"></order-part>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbEx from '../components/BreadcrumbEx.vue';
import FormView from "../components/FormView.vue";
import CartPart from './CartPart.vue';
import OrderPart from './OrderPart.vue';
import showDialog from "./show-dialog.js";
// import CommonType from "../common/common_type";

export default {
  components: { FormView, CartPart, OrderPart, BreadcrumbEx},
  props: {
    id: {},
  },
  data: () => ({
    title: "DraftLawyer",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    colPerRow: 1,
    isCreate: false,
    isForbidden: false,
    forbiddenDialog: false,
    revertDialog: false,
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "Cart",
        text: "Cart",
        tag: "cart",
      },
      {
        name: "Order",
        text: "Order",
        tag: "order",
      },
    ],
    itemSchemas: [
      {
        name: "nickname",
        value: "nickname",
        type: "string",
      },
      {
        name: "phoneNumber",
        value: "phoneNumber",
        type: "string",
      },
      {
        name: "openid",
        value: "openid",
        type: "string",
      },
      {
        name: "email",
        value: "email",
        type: "string",
      },
      // {
      //   name: "isActive",
      //   value: "isActive",
      //   type: "dropdown",
      //   dropdown: CommonType.booleans,
      //   localized: true,
      // },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.refresh();
    this.isCreate = this.id == undefined;
    console.log("Customer Id: " + this.id);
  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      if (this.id == undefined) {
        return;
      }
      this.get("/api/user/" + this.id, {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
          _this.isForbidden = data.data["forbidden"];
        },
      });
    },
    forbidden(forbidden) {
      let option = {
        callback: function () {},
      };
      this.get(`/api/lawyer/` + this.id + "/forbidden?forbidden=" + forbidden, option);
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {
    "c_firstName": "First Name",
    "c_lastName": "Last Name",
    "c_name": "Contact Name",
    "c_check": "Check",
    "c_phone": "Phone",
    "c_website": "WebSite",
    "c_industry": "Industry",
    "c_owner": "Owner",
    "c_updated": "Updated",
    "c_tax": "Tax Code",
    "c_revenue": "Annual Revenue",
    "c_address": "Address",
    "c_billAddress": "Bill Address",
    "c_shippingAddress": "Ship Address",
    "c_modifiedAt": "Modified At",
    "delete_title": "Delete",
    "delete_description": "Do you want to delete \"{0}\"?"
  },
  "cn": {
    "c_firstName": "名",
    "c_lastName": "姓",
    "c_name": "联系人",
    "c_check": "选中",
    "c_phone": "电话",
    "c_website": "网站",
    "c_industry": "行业",
    "c_owner": "所有人",
    "c_updated": "更新时间",
    "c_tax": "税号",
    "c_revenue": "年收入",
    "c_address": "地址",
    "c_billAddress": "账单地址",
    "c_shippingAddress": "邮寄地址",
    "c_modifiedAt": "修改时间",
    "delete_title": "删除",
    "delete_description": "是否要删除\"{0}\"?"
  }
}
</i18n>
