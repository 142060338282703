"use strict";

class Utils {
  constructor(vue) {
    this.vm = vue;
  }

  updateLocaleText(schemaItem) {
    schemaItem.forEach((schema) => {
      if (schema.name != undefined) {
        schema.text = this.vm.$t("c_" + schema.name);
      }
  
      if (schema.type == "dropdown") {
        schema.itemText = "name." + this.vm.$i18n.locale;
      }
    });
  }

  getLocaleText(datas, value) {
    let returnValue = value;
    datas.forEach(item => {
      if (item.value == value) {
        returnValue = item["text"][this.vm.$i18n.locale];
      }
    });
    return returnValue;
  }

  getValueByText(datas, text) {
    let returnValue = text;
    datas.forEach(item => {
      if (item["text"][this.vm.$i18n.locale] == text) {
        returnValue = item.value;
      }
    });
    return returnValue;
  }
}

// Utils.prototype.vue;

// Utils.prototype.updateLocaleText = function(schemaItem) {
//   schemaItem.forEach((schema) => {
//     if (schema.name != undefined) {
//       schema.text = vm.$t("c_" + schema.name);
//     }

//     if (schema.type == "dropdown") {
//       schema.itemText = "name." + vm.$i18n.locale;
//     }
//   });
// }

// Utils.prototype.getLocaleText = function(datas, value) {
//   let returnValue = value;
//   data.forEach(item => {
//     if (item.value == value) {
//       returnValue = datas["text"][vm.$i18n.locale];
//     }
//   });
//   return returnValue;
// }

export default Utils;
