module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票信息"])},
        "c_invoiceTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])},
        "c_invoiceCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位地址"])},
        "c_invoiceTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "c_invoiceBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_invoiceBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行账户"])},
        "c_toName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票收件人"])},
        "c_toAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票邮寄地址"])},
        "c_toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票邮寄电话"])},
        "c_toEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票电子邮箱"])}
      },
      "cn": {
        "c_invoiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票信息"])},
        "c_invoiceTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])},
        "c_invoiceCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位地址"])},
        "c_invoiceTelephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "c_invoiceBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_invoiceBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行账户"])},
        "c_toName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票收件人"])},
        "c_toAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票邮寄地址"])},
        "c_toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票邮寄电话"])},
        "c_toEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票电子邮箱"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_invoiceTitle":"发票信息","c_invoiceTaxNumber":"税号","c_invoiceCompanyAddress":"单位地址","c_invoiceTelephone":"电话","c_invoiceBankName":"开户行","c_invoiceBankAccount":"银行账户","c_toName":"发票收件人","c_toAddress":"发票邮寄地址","c_toPhone":"发票邮寄电话","c_toEmail":"发票电子邮箱"},"cn":{"c_invoiceTitle":"发票信息","c_invoiceTaxNumber":"税号","c_invoiceCompanyAddress":"单位地址","c_invoiceTelephone":"电话","c_invoiceBankName":"开户行","c_invoiceBankAccount":"银行账户","c_toName":"发票收件人","c_toAddress":"发票邮寄地址","c_toPhone":"发票邮寄电话","c_toEmail":"发票电子邮箱"}}')
  delete Component.options._Ctor
  
}
