export default {
    values: [
      {value:"new",                text:{en:"Waiting for Accept",   cn:"待接单",  }},  
      {value:"accept",             text:{en:"Waiting for Pay",      cn:"待付款",  }},  
      {value:"prepay",             text:{en:"Paying",               cn:"正在付款"  }},
      {value:"reject_pay",         text:{en:"Reject Pay",           cn:"付款驳回"   }},
      {value:"approve_pay",        text:{en:"Approve Pay",          cn:"待批准"   }},
      {value:"process",            text:{en:"Processing",           cn:"进行中",  }},  
      {value:"close",              text:{en:"Close",                cn:"关闭",    }},  
      {value:"finish",             text:{en:"Finish",               cn:"完成",    }},  
      {value:"pay_back",           text:{en:"Pay Back",             cn:"退款中",  }},  
      {value:"feedback",           text:{en:"Feedback",             cn:"已反馈",  }},  
    ],
  };
  