<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn color="primary mr-4" fab small @click="$router.go(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              {{ $t("status") + ": " + this.status }}
              <v-spacer></v-spacer>
              <!-- <v-btn v-if="(entity['status'] == 'finish' || entity['status'] == 'feedback') && entity['paidForLawyer'] != true" class="primary ml-4" @click="openDialog('pay_to_lawyer')">
                {{ $t("pay_to_lawyer") }}
              </v-btn> -->
               <v-btn v-if="entity['status'] == 'pay_back'" class="primary ml-4" @click="openDialog('pay_back')">
                {{ $t("pay_back") }}
              </v-btn>
              <v-btn class="warning ml-4" @click="sendNotification">
                {{ $t("send_notification") }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="ma-4" ref="lawyer">
          <order-related-lawyer-part
            ref="relatedLawyerList"
            :id="id"
          ></order-related-lawyer-part>
        </v-row>
        <v-row class="ma-4" ref="payment">
          <payment-part ref="payment" :orderId="id" ></payment-part>
        </v-row>
        <v-row class="ma-4" ref="history">
          <order-history-part ref="historyList" :id="id"></order-history-part>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogDescription }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog = false;
              commitAction();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormView from "../components/FormView.vue";
import OrderHistoryPart from "./OrderHistoryPart.vue";
import ProductDropdowns from "../common/product_dropdown";
import OrderStatusDropdowns from "../common/order_status";
import showDialog from "./show-dialog.js";
import OrderRelatedLawyerPart from "./OrderRelatedLawyerPart.vue";
import Utils from "../common/utils";
import PaymentPart from './PaymentPart.vue';
import BreadcrumbEx from '../components/BreadcrumbEx.vue';

const PAY_TO_LAWYER = "pay_to_lawyer";
const PAY_BACK = "pay_back";

export default {
  components: { FormView, OrderHistoryPart, OrderRelatedLawyerPart, PaymentPart, BreadcrumbEx },
  props: {
    id: {},
  },
  data: () => ({
    title: "OrderDetail",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    colPerRow: 1,
    isCreate: false,
    forbiddenDialog: false,
    revertDialog: false,
    status: "",
    dialogTitle: "",
    dialogDescription: "",
    dialogAction: "",
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "lawyer",
        text: "lawyer",
        tag: "lawyer",
      },
      {
        name: "Payment",
        text: "Payment",
        tag: "payment",
      },
      {
        name: "History",
        text: "History",
        tag: "history",
      },
    ],
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: ProductDropdowns.types,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        dropdown: OrderStatusDropdowns.values,
        localized: true,
        readonly: true,
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
        hasfilter: true,
        condition: {},
      },
      {
        name: "deadline",
        value: "deadline",
        type: "date",
        hasfilter: true,
        condition: {},
      },
      {
        name: "orderNo",
        value: "orderNo",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "date",
      },
      {
        name: "modifiedAt",
        value: "modifiedAt",
        type: "date",
        hasfilter: true,
        condition: {},
      },
      {
        name: "paybackReason",
        value: "paybackReason",
        type: "string",
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.refresh();
    this.isCreate = this.id == undefined;
    console.log("Order Id: " + this.id);
  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      let utils = new Utils(this);
      if (this.id == undefined) {
        return;
      }
      this.get("/api/order/" + this.id, {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
          _this.status = utils.getLocaleText(OrderStatusDropdowns.values, data.data["status"]);
        },
      });
    },
    sendNotification() {
      let option = {
        callback: function () {},
      };
      this.get(
        `/api/order/` + this.id + "/notification",
        option
      );
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
    openDialog(action) {
      if (action == PAY_TO_LAWYER) {
        this.dialogAction = action;
        this.dialogTitle = this.$t("pay_to_lawyer_title");
        this.dialogDescription = this.$t("pay_to_lawyer_description");
        this.dialog = true;
      } else if (action == PAY_BACK) {
        this.dialogAction = action;
        this.dialogTitle = this.$t("pay_back_title");
        this.dialogDescription = this.$t("pay_back_description");
        this.dialog = true;
      }
    },
    commitAction() {
      if (this.dialogAction == PAY_TO_LAWYER) {
        let action = {
          name: "OrderAction",
          action: "pay_to_lawyer"
        }
        let option = {
          callback: function () {},
        };
        this.post(`/api/order/` + this.id + "/action", action, option);
      } else if (this.dialogAction == PAY_BACK) {
        let action = {
          name: "OrderAction",
          action: "close"
        }
        let option = {
          callback: function () {},
        };
        this.post(`/api/order/` + this.id + "/action", action, option);
      }
    }
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {

  },
  "cn": {

  }
}
</i18n>
