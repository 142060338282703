import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Lawyer from "../views/Lawyer.vue";
import LawyerDetail from "../views/LawyerDetail.vue";
import DraftLawyer from "../views/DraftLawyer.vue";
import DraftLawyerDetail from "../views/DraftLawyerDetail.vue";
import Customer from "../views/Customer.vue";
import CustomerDetail from "../views/CustomerDetail.vue";
import Order from "../views/Order.vue";
import OrderDetail from "../views/OrderDetail.vue";
import Product from "../views/Product.vue";
import Payment from "../views/Payment.vue";
import PaymentDetail from "../views/PaymentDetail.vue";
import LawyerInvoice from "../views/LawyerInvoice.vue";
import UserInvoice from "../views/UserInvoice.vue";
import InvoiceDetail from "../views/InvoiceDetail.vue";
import Feedback from "../views/Feedback.vue";
import Notice from "../views/Notice.vue";
import Case from "../views/Case.vue";
import Setting from "../views/Setting.vue";
import User from "../views/UserPart.vue";
import Template from "../views/TemplatePart.vue";
import SettingPart from "../views/SettingPart.vue";
import System from "../views/SystemPart.vue";
import Profile from "../views/Profile.vue";
import General from "../views/GeneralPart.vue";
import ResetPassword from "../views/ResetPassword.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/lawyer",
    name: "Lawyer",
    component: Lawyer,
  },
  {
    path: "/lawyerdetail",
    name: "LawyerDetail",
    component: LawyerDetail,
    props: true,
    meta: {
      back: { name: "DraftLawyer", text: "律师", href: "/DraftLawyer" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "Lawyer", text: "律师", disabled: false, href: "Lawyer" },
        { name: "LawyerDetail", text: "律师信息", disabled: true, href: "LawyerDetail" },
      ],
    },
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    meta: {
      back: { name: "Back", text: "首页", path: "/Home" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "/Home" },
        { name: "Customer", text: "用户", disabled: true, href: "/Customer" },
      ],
    },
  },
  {
    path: "/customerDetail",
    name: "CustomerDetail",
    component: CustomerDetail,
    props: true,
    meta: {
      back: { name: "Customer", text: "用户", href: "/Customer" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "Customer", text: "用户", disabled: false, href: "Customer" },
        { name: "CustomerDetail", text: "用户信息", disabled: true, href: "CustomerDetail" },
      ],
    },
  },
  {
    path: "/draftLawyer",
    name: "DraftLawyer",
    component: DraftLawyer,
  },

  {
    path: "/draftLawyerDetail",
    name: "DraftLawyerDetail",
    component: DraftLawyerDetail,
    props: true,
    meta: {
      back: { name: "DraftLawyer", text: "待批准律师", href: "/DraftLawyer" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "DraftLawyer", text: "待批准律师", disabled: false, href: "DraftLawyer" },
        { name: "DraftLawyerDetail", text: "待批准律师信息", disabled: true, href: "DraftLawyerDetail" },
      ],
    },
  },
  {
    path: "/Order",
    name: "Order",
    component: Order,
  },

  {
    path: "/OrderDetail",
    name: "OrderDetail",
    component: OrderDetail,
    props: true,
    meta: {
      back: { name: "Order", text: "订单", href: "/Order" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "Order", text: "订单", disabled: false, href: "Order" },
        { name: "OrderDetail", text: "待批准律师信息", disabled: true, href: "OrderDetail" },
      ],
    },
  },
  {
    path: "/Product",
    name: "Product",
    component: Product,
  },
  {
    path: "/Payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/PaymentDetail",
    name: "PaymentDetail",
    component: PaymentDetail,
    props: true,
    meta: {
      back: { name: "Payment", text: "收款", href: "/Payment" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "Payment", text: "收款", disabled: false, href: "Payment" },
        { name: "PaymentDetail", text: "收款信息", disabled: true, href: "PaymentDetail" },
      ],
    },
  },
  {
    path: "/UserInvoice",
    name: "UserInvoice",
    props: true,
    component: UserInvoice,
  },
  {
    path: "/LawyerInvoice",
    name: "LawyerInvoice",
    props: true,
    component: LawyerInvoice,
  },
  {
    path: "/UserInvoiceDetail",
    name: "UserInvoiceDetail",
    component: InvoiceDetail,
    props: true,
    meta: {
      back: { name: "Invoice", text: "对账单", href: "/Invoice" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "UserInvoice", text: "对账单", disabled: false, href: "UserInvoice" },
        { name: "UserInvoiceDetail", text: "对账单信息", disabled: true, href: "UserInvoiceDetail" },
      ],
    },
  },
  {
    path: "/LawyerInvoiceDetail",
    name: "LawyerInvoiceDetail",
    component: InvoiceDetail,
    props: true,
    meta: {
      back: { name: "Invoice", text: "对账单", href: "/Invoice" },
      list: [
        { name: "Home", text: "首页", disabled: false, href: "Home" },
        { name: "LawyerInvoice", text: "对账单", disabled: false, href: "LawyerInvoice" },
        { name: "LawyerInvoiceDetail", text: "对账单信息", disabled: true, href: "LawyerInvoiceDetail" },
      ],
    },
  },
  {
    path: "/Feedback",
    name: "Feedback",
    component: Feedback,
    props: true,
  },
  {
    path: "/Notice",
    name: "Notice",
    component: Notice,
    props: true,
  },
  {
    path: "/Case",
    name: "Case",
    component: Case,
    props: true,
  },
  {
    path: "/Setting",
    name: "Setting",
    component: Setting,
    children: [
      {
        path: "User",
        component: User,
      },
      {
        path: "System",
        component: System,
      },
      {
        path: "Template",
        component: Template,
      },
      {
        path: "SettingPart",
        component: SettingPart,
      },
    ],
  },
  {
    path: "/Profile",
    name: "Profile",
    component: Profile,
    children: [
      {
        path: "General",
        component: General,
      },
      {
        path: "ResetPassword",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if(to.path == from.path) {
//     next(false);
//   } else {
//     next();
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.meta.list) {
    let list = to.meta.list;
    store.commit("changeBreadcumb", { list });
  }
  if (to.meta.back) {
    let back = to.meta.back;
    store.commit("changeBack", { back });
  }
  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
