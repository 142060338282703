<template>
  <div>
    <entity-view
      :item-schemas="itemSchemas"
      :select="select"
      :items="items"
      :title="$t('user')"
      :pagination="pagination"
      :externalActions="externalActions"
      :options="options"
      @items-refresh="itemsRefresh"
      @item-action="itemAction"
      @page-change="pageChange"
    >
      <template v-slot:expanded-action="{item, hover}">
        <v-btn
          class="div-space"
          fab
          outlined
          x-small
          :class="hover ? '' : 'd-hidden'"
          color="primary"
          v-on:click.stop="itemAction('reset', item)"
        >
          <v-icon>mdi-key-outline</v-icon>
        </v-btn>
      </template>
    </entity-view>
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5 ml-6">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <form-view
            :itemSchemas="dialogSchemas"
            :readonly="!editMode"
            :reverse="false"
            :colPerRow="colPerRow"
            ref="commenView"
          >
          </form-view>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog = false;
              save();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("delete_description", [this.deleteItem["note"]]) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              deleteDialog = false;
              remove();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("reset_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("reset_description", [this.deleteItem["note"]]) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="resetDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              resetDialog = false;
              reset();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import FormView from "../components/FormView.vue";

export default {
  components: { EntityView, FormView },
  data: () => ({
    title: "User",
    select: false,
    dialog: false,
    deleteDialog: false,
    resetDialog: false,
    dialogTitle: "",
    editMode: true,
    deleteItem: "",
    resetItem: "",
    colPerRow: 1,
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      {
        name: "nickname",
        value: "nickname",
        type: "string",
      },
      {
        name: "openid",
        value: "openid",
        type: "string",
      },
      {
        name: "email",
        value: "email",
        type: "string",
      },
      {
        name: "phoneNumber",
        value: "phoneNumber",
        type: "string",
      },
      {
        name: "roles",
        value: "roleStr",
        type: "string",
      },
    ],
    dialogSchemas: [
      {
        name: "id",
        value: "id",
        type: "number",
        hidden: true,
        readonly: true,
      },
      {
        name: "nickname",
        value: "nickname",
        type: "string",
        flex: 2,
      },
      {
        name: "email",
        value: "email",
        type: "string",
        flex: 2,
      },
      {
        name: "phoneNumber",
        value: "phoneNumber",
        type: "string",
        flex: 2,
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateComponent();
    this.title = this.$t(this.title);
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: true,
        showUpdate: false
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.localizeSchema(this.dialogSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      let postData = {};
      postData.pagination = this.pagination;
      if (
        this.criterias != undefined &&
        this.criterias.length != 0 &&
        this.criterias.length != undefined
      ) {
        postData.criterias = this.criterias;
      }
      this.post("/api/users?type=admin", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name, item) {
      if (name === "insert") {
        this.updateId = undefined;
        this.dialogTitle = this.$t("create");
        this.dialog = true;
      } else if (name === "update") {
        this.updateId = item["id"];
        this.dialogTitle = this.$t("update");
        this.dialog = true;
        this.$refs.commenView.loadEntity(item);
      } else if (name === "delete") {
        this.updateId = item["id"];
        this.deleteDialog = true;
        this.deleteItem = item;
      } else if (name === "reset") {
        this.resetItem = item;
        this.resetDialog = true;
      }
    },
    save() {
      let _this = this;
      let entity = this.$refs.commenView.commitEntity();
      entity.isAdmin = true;
      let option = {
        callback: function () {
          _this.itemsRefresh();
        },
      };
      if (this.updateId == undefined) {
        this.post(`/api/user/`, entity, option);
      } else {
        this.put(`/api/user/` + this.updateId, entity, option);
      }
    },
    remove() {
      let _this = this;
      let option = {
        callback: function () {
          _this.itemsRefresh();
        },
      };
      if (this.updateId != undefined) {
        this.delete(`/api/user/` + this.updateId, option);
      }
    },
    reset() {
      let _this = this;
      let option = {
        callback: function () {
          _this.itemsRefresh();
        },
      };
      let entity = {
        openid: this.resetItem.openid
      }
      this.post(`/api/resend`, entity, option);
    },
  },
};
</script>

<style scoped>
.d-hidden {
  visibility: hidden;
}

.div-space {
  margin-left: 4px;
}
</style>

<i18n>
{
  "en": {
    "c_name": "Name"
  },
  "cn": {
    "c_name": "用户名"
  }
}
</i18n>
