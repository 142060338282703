<template>
  <v-card>
    <v-card-title class="col-item justify-center title-color">
      {{title}}
    </v-card-title>
    <div class="d-flex justify-center col-item">
      <h1 class="col-item text-center text-h3">
        <div class="number-grow-warp">
          <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>
        </div>
      </h1>
      <h4 class="col-item text-h6 title-color align-self-end ">
        {{unit}}
      </h4>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 720000
    },
    unit: {
      type: String
    },
    title: {
      type: String
    }
  },
  watch: {
    value: function() {
      this.numberGrow(this.$refs.numberGrow);
    }
  },
  methods: {
    numberGrow (ele) {
      let _this = this;
      let step = (_this.value * 10) / (_this.time * 100)
      let current = 0
      let start = 0
      let t = setInterval(function () {
        start += step
        if (start > _this.value) {
          clearInterval(t)
          start = _this.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = parseInt(current);
      }, 10)
    }
  },
  mounted () {
    this.numberGrow(this.$refs.numberGrow)
  }
}
</script>

<style>
.number-grow-warp{
  transform: translateZ(0);
}
.number-grow {
  display: block;
}
/* .col-item {
  background-color: #100c2a;
} */
.title-color {
  color: rgba(0, 0, 0, 0.6);
}
</style>