module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_smtpServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Server"])},
        "c_smtpPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Port"])},
        "c_useSsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use SSL"])},
        "c_sendMailAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "c_sendMailPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
      },
      "cn": {
        "c_smtpServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP服务器"])},
        "c_smtpPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP端口"])},
        "c_useSsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用SSL"])},
        "c_sendMailAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
        "c_sendMailPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_smtpServer":"SMTP Server","c_smtpPort":"SMTP Port","c_useSsl":"Use SSL","c_sendMailAccount":"Account","c_sendMailPassword":"Password"},"cn":{"c_smtpServer":"SMTP服务器","c_smtpPort":"SMTP端口","c_useSsl":"使用SSL","c_sendMailAccount":"用户名","c_sendMailPassword":"密码"}}')
  delete Component.options._Ctor
  
}
