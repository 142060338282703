<template>
  <v-container fluid>
    <div class="main-container" fill-height>
      <entity-view
        :item-schemas="itemSchemas"
        :items="items"
        :title="$t('case')"
        :pagination="pagination"
        :externalActions="externalActions"
        :options="options"
        @items-refresh="itemsRefresh"
        @item-action="itemAction"
        @page-change="pageChange"
        @double-click-row="clickRow"
        @change="onChange"
      >
      </entity-view>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5 ml-6">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <form-view :itemSchemas="itemSchemas" :reverse="false" :colPerRow="colPerRow" @change="onChange" ref="dialogView"> </form-view>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="save(action)">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("delete_title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("delete_description", [this.selectItem["id"]]) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              deleteDialog = false;
              remove();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import FormView from "../components/FormView.vue";
import kinds from "../common/kinds";
import criminalLaw from "../common/criminal_law";
const plainItems = kinds.buildPaint();
const criminalLawItems = criminalLaw.buildPaint();

export default {
  components: { EntityView, FormView },
  data: () => ({
    title: "Product",
    select: true,
    expendOpen: false,
    readonly: true,
    dialog: false,
    dialogTitle: "Insert",
    deleteDialog: false,
    selectItem: "",
    action: "insert",
    colPerRow: 1,
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
        readonly: true,
        show: function (entity) {
          return entity.id !== undefined;
        },
      },
      {
        name: "index",
        value: "index",
        type: "string",
        readonly: true,
      },
      {
        name: "hidden",
        value: "tags",
        type: "string",
        show: false,
      },
      {
        name: "kind",
        value: "kind",
        type: "dropdown",
        dropdown: [
          {
            value: "CivilLaw",
            text: "民商事案件",
          },
          {
            value: "CriminalLaw",
            text: "刑事案件",
          }
        ],
        externalAction: ["refresh"],
      },
      {
        name: "tags",
        value: "index",
        type: "dropdown",
        dropdown: function(item) {
          if (item.kind == "CivilLaw") {
            return plainItems;
          } else {
            return criminalLawItems;
          }
        },
        externalAction: ["refresh"],
      },
      {
        name: "caseContent",
        value: "content",
        type: "text",
        readonly: false,
        formatter: (item, value) => {
          return value == undefined ? "" : value.length > 20 ? value.substring(0, 20) + "..." : value;
        },
      },
      {
        name: "modifiedAt",
        value: "modifiedAt",
        type: "datetime",
      },
    ],
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: true,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let postData = {
        pagination: this.pagination,
        orders: [
          {
            column: "created_at",
            order: "desc",
          },
        ],
      };
      this.post("/api/cases", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.itemUpdate(item);
    },
    remove() {
      let _this = this;
      let option = {
        callback: function () {
          _this.refresh();
        },
      };
      this.delete("/api/case/" + this.selectItem.id, option);
    },
    itemCreate() {
      this.dialog = true;
      this.action = "insert";
      this.dialogTitle = this.$t("create");
      this.$nextTick(() => {
        this.$refs.dialogView.resetErrorMessage();
        this.$refs.dialogView.loadEntity({});
      });
    },
    itemUpdate(item) {
      this.selectItem = item;
      this.$set(this, "dialog", true);
      this.action = "update";
      this.dialogTitle = this.$t("update");
      this.$nextTick(() => {
        this.$refs.dialogView.loadEntity(item);
      });
    },
    itemDelete(item) {
      this.selectItem = item;
      this.deleteDialog = true;
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name, item) {
      if (name === "insert") {
        this.itemCreate();
      } else if (name === "update") {
        this.itemUpdate(item);
      }
      if (name === "delete") {
        this.itemDelete(item);
      }
    },
    onChange(item, schema) {
      let tags = 0;
      schema.dropdown.forEach((element) => {
        if (element.value == item.index) {
          tags = element.text;
        }
      });
      item.tags = tags;
    },
    save(action) {
      let _this = this;
      //Valid Index
      let entity = this.$refs.dialogView.commitEntity();
      let option = {
        callback: function () {
          _this.refresh();
        },
      };
      if (action == "update") {
        this.dialog = false;
        this.put("/api/case/" + this.selectItem.id, entity, option);
      } else if (action == "insert") {
        let validationOptions = {
          callback: function (res) {
            let option = {
              callback: function () {
                _this.refresh();
              },
            };
            console.log(entity);
            if (res.data != null) {
              _this.$refs.dialogView.setErrorMessage(`"${entity.tags}"案由解释已经存在`);
            } else {
              _this.dialog = false;
              _this.post("/api/case/", entity, option);
            }
          },
        };
        this.get("/api/case/index/" + entity.index, validationOptions);
      }
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
  "en": {
    "notice": "Notice",
    "c_message": "Message",
    "c_startAt": "Start At",
    "c_endAt": "End At"
  },
  "cn": {
    "notice": "通知",
    "c_message": "通知内容",
    "c_startAt": "开始生效时间",
    "c_endAt": "结束生效时间"
  }
}
</i18n>
