module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Online"])},
        "c_userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserId"])}
      },
      "cn": {
        "c_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否在线"])},
        "c_userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户ID"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_enable":"Is Online","c_userId":"UserId"},"cn":{"c_enable":"是否在线","c_userId":"客户ID"}}')
  delete Component.options._Ctor
  
}
