<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <!-- <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ entity.fullName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn
                color="primary mr-4"
                fab
                small
                @click="$router.go(-1)"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="entity['status'] == 'notpay' && entity['type'] == 'bill'"
                color="primary"
                @click="approve()"
              >
                {{ $t("approve") }}
              </v-btn>
              <v-btn
                v-if="entity['status'] == 'notpay' && entity['type'] == 'bill'"
                class="warning ml-4"
                @click="reject()"
              >
                {{ $t("reject") }}
              </v-btn>
              <v-btn
                v-if="entity['type'] == 'wechat'"
                color="primary"
                @click="openStatus()"
              >
                {{ $t("status") }}
              </v-btn>
              <v-btn
                v-if="entity['status'] == 'success' && entity['type'] == 'wechat'"
                class="warning ml-4"
                @click="openDialog('refund')"
              >
                {{ $t("refund") }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="ma-4" ref="history">
          <payment-history-part
            ref="historyList"
            :id="id"
          ></payment-history-part>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5 ml-6">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <form-view
            :itemSchemas="dialogSchemas"
            :readonly="dialogReadonly"
            :reverse="false"
            :colPerRow="colPerRow"
            ref="commenView"
          >
          </form-view>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              dialog = false;
              commit();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statusDialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5 ml-6">
          {{ $t('dialogStatusTitle') }}
        </v-card-title>
        <v-card-text>
          <form-view
            :itemSchemas="dialogStatusSchemas"
            :readonly="true"
            :reverse="false"
            :colPerRow="colPerRow"
            ref="statusView"
          >
          </form-view>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="statusDialog = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="actionDialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogDescription }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="actionDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              actionDialog = false;
              commitAction();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormView from "../components/FormView.vue";
import showDialog from "./show-dialog.js";
import PayementTypeDropdown from "../common/payment_type";
import PayementStatusDropdown from "../common/payment_status";
import PaymentHistoryPart from "./PaymentHistoryPart.vue";
import BreadcrumbEx from '../components/BreadcrumbEx.vue';

export default {
  components: { FormView, PaymentHistoryPart, BreadcrumbEx },
  props: {
    id: {},
  },
  data: () => ({
    title: "payment",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    actionDialog: false,
    colPerRow: 1,
    isCreate: false,
    dialogTitle: "",
    dialogDescription: "",
    status: {},
    statusDialog: false,
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "history",
        text: "history",
        tag: "history",
      },
    ],
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
        hidden: true,
      },
      {
        name: "orderId",
        value: "orderId",
        type: "string",
      },
      {
        name: "paymentNo",
        value: "paymentNo",
        type: "string",
      },
      {
        name: "imageUrl",
        value: "imageUrl",
        type: "image",
        required: true,
        getURL: function (value) {
          return process.env.VUE_APP_API_URL + value;
        },
        show: (entity) => {
          return entity.type == "bill";
        }
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: PayementTypeDropdown.values,
        localized: true,
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
      },
      {
        name: "phoneNumber",
        value: "phoneNumber",
        type: "string",
        show: (entity) => {
          return entity.type == "bill";
        }
      },
      {
        name: "accountName",
        value: "accountName",
        type: "string",
        show: (entity) => {
          return entity.type == "bill";
        }
      },
      {
        name: "accountNumber",
        value: "accountNumber",
        type: "string",
        show: (entity) => {
          return entity.type == "bill";
        }
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        dropdown: PayementStatusDropdown.values,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "datetime",
        hasfilter: true,
        condition: {},
      },
    ],
    dialogSchemas: [
      {
        name: "comment",
        value: "comment",
        type: "text",
        flex: 2,
      },
    ],
    dialogStatusSchemas: [
      {
        name: "appid",
        value: "appid",
        type: "string",
        flex: 2,
      },
      {
        name: "outTradeNo",
        value: "out_trade_no",
        type: "string",
        flex: 2,
      },
      {
        name: "tradeState",
        value: "trade_state",
        type: "string",
        flex: 2,
      },
      {
        name: "tradeStateDesc",
        value: "trade_state_desc",
        type: "string",
        flex: 2,
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.refresh();
    this.isCreate = this.id == undefined;
    console.log("Payment Id: " + this.id);
  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.localizeSchema(this.dialogSchemas);
      this.localizeSchema(this.dialogStatusSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    openStatus() {
      this.statusDialog = true;
      this.getStatus();
    },
    getStatus() {
      let _this = this;
      if (this.id == undefined) {
        return;
      }
      this.get("/api/payment/" + this.id + "/status", {
        callback: function (data) {
          _this.status = data;
          if (_this.$refs.statusView != undefined) {
            _this.$refs.statusView.loadEntity(data.data);
          }
        },
      });
    },
    refresh() {
      let _this = this;
      if (this.id == undefined) {
        return;
      }
      this.get("/api/payment/" + this.id, {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
        },
      });
    },
    approve() {
      this.dialogTitle = this.$t("approve");
      this.isApprove = true;
      this.dialog = true;
    },
    reject() {
      this.dialogTitle = this.$t("reject");
      this.isApprove = false;
      this.dialog = true;
    },
    openDialog(action) {
      this.action = action;
      if (action == "refund") {
        this.dialogTitle = this.$t("refund_title");
        this.dialogDescription = this.$t("refund_description");
      }
      this.actionDialog = true;
    },
    commitAction () {
      if (this.action == "refund") {
        let entity = {
          reason: "管理员操作退款",
        }
        let option = {
          callback: function () {},
        };
        this.post(`/api/payment/` + this.id + "/refund", entity, option);
      }
    },
    commit() {
      let entity = this.$refs.commenView.commitEntity();
      entity["approve"] = this.isApprove;
      let option = {
        callback: function () {},
      };
      this.post(`/api/payment/` + this.id + "/action", entity, option);
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {
    "c_accountName": "Account Name",
    "c_accountNumber": "Account Number",
    "c_appid":"App Id",
    "c_outTradeNo":"Trade No",
    "c_tradeState":"Trade State",
    "c_tradeStateDesc":"Trade State Description",
    "c_imageUrl": "Transfer Screenshot",
    "dialogStatusTitle": "Payment Status"
  },
  "cn": {
    "c_accountName": "账户名",
    "c_accountNumber": "账户号",
    "c_appid":"应用ID",
    "c_outTradeNo":"商户订单号",
    "c_tradeState":"交易状态",
    "c_tradeStateDesc":"交易状态描述",
    "c_imageUrl": "转账截图",
    "dialogStatusTitle": "交易状态"
  }
}
</i18n>
