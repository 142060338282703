import Vue from "vue";

const install = function (Vue) {
  Vue.prototype.log = function (message) {
    console.log(`LOG:   ${message.type}\t ${message.message}`)
    // instance.$root.$emit('showInfo', message);
  };
  Vue.prototype.info = function (message) {
    console.log(`INFO:  ${message.type}\t ${message.message}`)
    // instance.$root.$emit('showInfo', message);
  };
  Vue.prototype.error = function (message) {
    console.log(`ERROR: ${message.type}\t ${message.message}`);
    // if (err.response != null && err.response.status == 401 && err.response.data.message.indexOf("Expired Token") !== -1) {
    //   instance.$root.$emit('showExpiredDialog')
    // } else {
    //   instance.$root.$emit('showError', err.response.data)
    // }
  };
};

Vue.use(install);

export default install;
