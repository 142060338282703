<template>
  <v-breadcrumbs class="pa-0" :items="breadcumb.list">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item @click="goto(item.href)" :disabled="item.disabled">
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "breadcrumb-ex",
  computed: {
    ...mapState(["breadcumb"]),
  },
  methods: {
    goto(url) {
       this.$router.push({
        name: url,
        // params: { id: item.id + "" },
      });
    }
  }
};
</script>
