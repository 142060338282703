<template>
  <v-expansion-panels flat>
    <v-expansion-panel v-for="schema in filterItemSchemas" :key="schema.name">
      <v-expansion-panel-header>
        <template v-slot:default="{ expendOpen }">
          <v-row no-gutters>
            <v-col cols="3"> {{ schema.text }}</v-col>
            <v-col cols="9" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span v-if="expendOpen" key="0"> {{ schema.text }}</span>
                <span v-else key="1">
                  {{ getDisplayContent(schema) }}
                </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col cols="8" class="text--secondary">
            <v-select
              :items="getFilterOperations(schema.type)"
              item-text="text"
              item-value="value"
              dense
              outlined
              return-object
              v-model="schema.condition.operator"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-if="schema.type == 'string'"
              v-model="schema.condition.value"
              :class="isHidden(schema) ? 'd-hidden' : ''"
              dense
              :label="$t('filter_label')"
            >
            </v-text-field>
            <v-text-field
              v-if="schema.type == 'number'"
              v-model="schema.condition.value"
              :class="isHidden(schema) ? 'd-hidden' : ''"
              dense
              :rules="getRules(schema)"
              :label="$t('filter_label')"
            >
            </v-text-field>
            <v-select
              :items="boolItems"
              item-text="text"
              item-value="value"
              v-if="schema.type == 'bool'"
              v-model="schema.condition.value"
              :class="isHidden(schema) ? 'd-hidden' : ''"
              dense
              :label="$t('filter_label')"
            >
            </v-select>
            <v-select
              :items="
                typeof schema.dropdown === 'function'
                  ? schema.dropdown(entity)
                  : schema.dropdown
              "
              :item-text="
                schema.itemText == undefined ? 'text' : schema.itemText
              "
              :item-value="
                schema.itemValue == undefined ? 'value' : schema.itemValue
              "
              v-if="schema.type == 'dropdown'"
              v-model="schema.condition.value"
              :class="isHidden(schema) ? 'd-hidden' : ''"
              dense
              :label="$t('filter_label')"
            >
            </v-select>
            <v-menu
              v-if="schema.type == 'date'"
              v-model="schema.menu"
              :ref="`menu-${schema.value}`"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="schema.condition.value"
                  readonly
                  dense
                  :class="isHidden(schema) ? 'd-hidden' : ''"
                  v-bind="attrs"
                  v-on="on"
                  :label="getLabel(schema, 'start')"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="schema.condition.value"
                @change="dateChanged(schema)"
                :locale="getPickerLocale()"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-if="showEnd(schema)"
              v-model="schema.menuEnd"
              :ref="`menu-end-${schema.value}`"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="schema.condition.valueEnd"
                  readonly
                  dense
                  :class="isHidden(schema) ? 'd-hidden' : ''"
                  v-bind="attrs"
                  v-on="on"
                  :label="getLabel(schema, 'end')"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="schema.condition.valueEnd"
                @change="dateEndChanged(schema)"
                :locale="getPickerLocale()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
      <v-divider></v-divider>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  components: {},
  props: {},
  computed: {},
  data: () => ({
    filterItemSchemas: [],
    boolItems: [
      { name: "true", value: true },
      { name: "false", value: false },
    ],
    filterItems: [],
    rules: {},
  }),
  watch: {
    // itemSchemas: {
    //   deep: true,
    //   handler: function(newValue) {
    //     console.log("ItemSchema changed");
    //     let filterItemSchemas = [];
    //     newValue.forEach(element => {
    //       if (element.hasfilter) {
    //         let item = {};
    //         Object.assign(item, element)
    //         filterItemSchemas.push(item);
    //       }
    //     });
    //     this.$set(this, "filterItemSchemas", filterItemSchemas);
    //   }
    // }
  },
  mounted() {
    this.updateSchemas();
  },
  methods: {
    getPickerLocale() {
      return this.$vuetify.lang.current == "zhHans" ? "zh-cn" : "en";
    },
    updateSchemas() {
      this.boolItems.forEach((schema) => {
        if (schema.name != undefined) {
          schema.text = this.$t("filter_" + schema.name);
        }
      });

      this.rules = {
        required: (value) => !!value || this.$t("required"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("rule_email"),
        number: (v) =>
          /\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*/.test(v) ||
          this.$t("rule_number"),
      };
    },
    isHidden(schema) {
      if (schema.condition != undefined) {
        if (schema.condition.operator != undefined) {
          if (
            schema.condition.operator.value == "is null" ||
            schema.condition.operator.value == "is not null"
          ) {
            return true;
          }
        }
      }
      return false;
    },
    showEnd(schema) {
      if (schema.type == "date" && schema.condition.operator != undefined) {
        if (
          schema.condition.operator.value == "between" ||
          schema.condition.operator.value == "notbetween"
        ) {
          return true;
        }
      }
      return false;
    },
    getDisplayContent(schema) {
      let displayContent = "";
      if (schema.condition.operator != undefined) {
        displayContent = schema.condition.operator.text;
      }
      if (schema.condition.value != undefined) {
        if (
          schema.condition.operator.value == "between" ||
          schema.condition.operator.value == "notbetween"
        ) {
          displayContent +=
            "  " +
            this.getText(schema, schema.condition.value) +
            " - " +
            this.getText(schema, schema.condition.valueEnd);
        } else if (
          schema.condition.operator.value != "none" &&
          schema.condition.operator.value != "is null" &&
          schema.condition.operator.value != "is not null"
        ) {
          displayContent += "  " + this.getText(schema, schema.condition.value);
        }
      }
      return displayContent;
    },
    loadSchema(schemas) {
      let filterItemSchemas = [];
      schemas.forEach((element) => {
        if (element.hasfilter) {
          let item = {};
          Object.assign(item, element);
          filterItemSchemas.push(item);
        }
      });
      this.$set(this, "filterItemSchemas", filterItemSchemas);
    },
    getFilterOperations(type) {
      let operators = [];
      operators.push({ name: "", value: "none" });
      if (type == "string" || type == "text") {
        operators.push({ name: "equal", value: "=" });
        operators.push({ name: "notequal", value: "<>" });
        operators.push({ name: "contains", value: "in" });
        operators.push({ name: "notcontains", value: "not in" });
        operators.push({ name: "startwith", value: "start with" });
        operators.push({ name: "endwith", value: "end with" });
        operators.push({ name: "empty", value: "is null" });
        operators.push({ name: "notempty", value: "is not null" });
      } else if (type == "bool") {
        operators.push({ name: "equal", value: "=" });
        operators.push({ name: "null", value: "is null" });
        operators.push({ name: "notnull", value: "is not null" });
      } else if (type == "number") {
        operators.push({ name: "equal", value: "=" });
        operators.push({ name: "equalgreater", value: ">=" });
        operators.push({ name: "equalsmaller", value: "<=" });
        operators.push({ name: "greater", value: "<" });
        operators.push({ name: "smaller", value: ">" });
        operators.push({ name: "null", value: "is null" });
        operators.push({ name: "notnull", value: "is not null" });
      } else if (type == "dropdown") {
        operators.push({ name: "equal", value: "=" });
        operators.push({ name: "null", value: "is null" });
        operators.push({ name: "notnull", value: "is not null" });
      } else if (type == "date") {
        operators.push({ name: "equaldate", value: "samedate" });
        operators.push({ name: "between", value: "between" });
        operators.push({ name: "notbetween", value: "notbetween" });
        operators.push({ name: "null", value: "is null" });
        operators.push({ name: "notnull", value: "is not null" });
      }

      operators = this.updateFilterText(operators);
      return operators;
    },
    getText(schema, value) {
      if (schema.dropdown != undefined) {
        let returnValue = value;
        schema.dropdown.forEach((item) => {
          if (item.value == value) {
            if (schema.localized) {
              returnValue = item["text"][this.$i18n.locale];
            } else {
              returnValue = item["text"];
            }
          }
        });
        return returnValue;
      } else {
        return value;
      }
    },
    updateFilterText(operators) {
      let _this = this;
      operators.forEach((operator) => {
        if (operator.name == "") {
          operator.text = "";
        } else {
          operator.text = _this.$t("op_" + operator.name);
        }
      });
      return operators;
    },
    getCriterias() {
      let criterias = [];
      this.filterItemSchemas.forEach((schema) => {
        if (
          schema.condition != undefined &&
          schema.condition.operator != null &&
          schema.condition.operator.value != "none"
        ) {
          let type = schema.type;
          if (type == "dropdown") {
            type = "string";
          }
          if (schema.condition.operator.value == "between" ) {
            criterias.push({
              operator: ">=",
              type: type,
              value: schema.condition.value,
              column: schema.value,
            });
            criterias.push({
              operator: "<",
              type: type,
              value: schema.condition.valueEnd,
              column: schema.value,
            });
          } else if (schema.condition.operator.value == "notbetween") {
            criterias.push({
              operator: "<",
              type: type,
              value: schema.condition.value,
              column: schema.value,
            });
            criterias.push({
              operator: ">=",
              type: type,
              value: schema.condition.valueEnd,
              column: schema.value,
            });
          } else if (schema.condition.operator.value == "samedate") {
            criterias.push({
              operator: "=",
              type: type,
              value: schema.condition.value,
              column: schema.value,
            });
          } else {
            criterias.push({
              operator: schema.condition.operator.value,
              type: type,
              value: schema.condition.value,
              column: schema.value,
            });
          }
        }
      });
      return criterias;
    },
    clear() {
      this.filterItemSchemas.forEach((schema) => {
        if (schema.condition) {
          schema.condition.operator = undefined;
          schema.condition.value = undefined;
        }
      });
    },
    getRules(schema) {
      let rules = [];
      if (schema.type == "number") {
        rules.push(this.rules.number);
      }

      if (schema["rules"] != null) {
        schema["rules"].forEach((rule) => {
          rules.push(this.rules[rule]);
        });
      }

      return rules;
    },
    dateChanged(schema) {
      this.$refs[`menu-${schema.value}`][0].isActive = false;
    },
    dateEndChanged(schema) {
      this.$refs[`menu-end-${schema.value}`][0].isActive = false;
    },
    getLabel(schema, label) {
      if (
        schema.condition.operator != undefined &&
        (schema.condition.operator.value == "between" ||
          schema.condition.operator.value == "notbetween")
      ) {
        if (label == "end") {
          return this.$t("filter_label_end");
        } else {
          return this.$t("filter_label_start");
        }
      } else {
        return this.$t("filter_label");
      }
    },
    getRange(schema) {
      if (
        schema.condition == undefined ||
        schema.condition.operator == undefined
      ) {
        return false;
      }

      if (
        schema.condition.operator.value == "between" ||
        schema.condition.operator.value == "notbetween"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.d-hidden {
  visibility: hidden;
}
</style>
