module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "repassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Password"])}
      },
      "cn": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "repassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在输入一次密码"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"title":"Login","name":"Name","login":"Log In","register":"Register","email":"Email","password":"Password","repassword":"Re-Password"},"cn":{"title":"登录","name":"名字","login":"登录","register":"注册","email":"电子邮箱","password":"密码","repassword":"在输入一次密码"}}')
  delete Component.options._Ctor
  
}
