module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "c_lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "c_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
        "c_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
        "c_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "c_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WebSite"])},
        "c_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industry"])},
        "c_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
        "c_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated"])},
        "c_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Code"])},
        "c_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Revenue"])},
        "c_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "c_billAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Address"])},
        "c_shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship Address"])},
        "c_modifiedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified At"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
        "delete_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do you want to delete \"", _interpolate(_list(0)), "\"?"])}
      },
      "cn": {
        "c_firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
        "c_lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
        "c_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人"])},
        "c_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选中"])},
        "c_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "c_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站"])},
        "c_industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行业"])},
        "c_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有人"])},
        "c_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新时间"])},
        "c_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])},
        "c_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年收入"])},
        "c_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
        "c_billAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单地址"])},
        "c_shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮寄地址"])},
        "c_modifiedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改时间"])},
        "delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "delete_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["是否要删除\"", _interpolate(_list(0)), "\"?"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_firstName":"First Name","c_lastName":"Last Name","c_name":"Contact Name","c_check":"Check","c_phone":"Phone","c_website":"WebSite","c_industry":"Industry","c_owner":"Owner","c_updated":"Updated","c_tax":"Tax Code","c_revenue":"Annual Revenue","c_address":"Address","c_billAddress":"Bill Address","c_shippingAddress":"Ship Address","c_modifiedAt":"Modified At","delete_title":"Delete","delete_description":"Do you want to delete \\"{0}\\"?"},"cn":{"c_firstName":"名","c_lastName":"姓","c_name":"联系人","c_check":"选中","c_phone":"电话","c_website":"网站","c_industry":"行业","c_owner":"所有人","c_updated":"更新时间","c_tax":"税号","c_revenue":"年收入","c_address":"地址","c_billAddress":"账单地址","c_shippingAddress":"邮寄地址","c_modifiedAt":"修改时间","delete_title":"删除","delete_description":"是否要删除\\"{0}\\"?"}}')
  delete Component.options._Ctor
  
}
