<template>
  <v-card :elevation="elevation" style="width: 100%">
    <!-- <v-card-title>
      {{ title }}
      <v-spacer></v-spacer> -->
    <!--        <v-text-field-->
    <!--            v-model="search"-->
    <!--            append-icon="mdi-magnify"-->
    <!--            label="Search"-->
    <!--            single-line-->
    <!--            hide-details-->
    <!--        ></v-text-field>-->
    <!-- </v-card-title> -->
    <v-data-table
      :headers="headers"
      :show-select="select"
      :single-select="singleSelect"
      :items="items"
      :dense="dense"
      :no-data-text="$t('no_data_text')"
      v-model="selection"
      @click:row="clickRow"
      @dblclick:row="dbclickRow"
      hide-default-footer
    >
      <template v-if="!hiddenHeader" v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!readonly"
            class="mx-2"
            fab
            dark
            color="primary"
            small
            v-on:click.stop="executeOp(undefined, 'insert')"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            dark
            color="primary"
            small
            v-on:click.stop="refresh()"
          >
            <v-icon dark> mdi-refresh </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template
        v-for="(h, index) in itemSchemas"
        v-slot:[`item.${h.value}`]="{ item, header, value }"
      >
        <slot v-if="h.hasTemplate" :name="h.template" v-bind:value="value" v-bind:header="header" v-bind:item="item">
        </slot>
        <div v-if="!h.hasTemplate" :key="index">
          {{ formatValue(header, item, value) }}
        </div>
      </template>
      <template v-slot:[`item.${actionName}`]="{ item }">
        <v-hover v-slot="{ hover }">
          <v-row align="center" justify-space-around no-gutters>
            <v-col class="d-flex justify-center">
              <v-btn
                v-if="options != undefined ? (options.showUpdate == undefined ? true :  options.showUpdate) : true"
                class="div-space"
                fab
                outlined
                x-small
                :class="hover ? '' : 'd-hidden'"
                color="primary"
                v-on:click.stop="executeOp(item, 'update')"
              >
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="options != undefined ? (options.showDelete == undefined ? true :  options.showDelete) : true"
                class="div-space"
                fab
                outlined
                x-small
                :class="hover ? '' : 'd-hidden'"
                color="primary"
                v-on:click.stop="executeOp(item, 'delete')"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
              <slot name="expanded-action" v-bind:item="item" v-bind:hover="hover">
              </slot>
            </v-col>
          </v-row>
        </v-hover>
      </template>
    </v-data-table>
    <template>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="this.pagination.pageCount"
          :total-visible="7"
          circle
        ></v-pagination>
      </div>
    </template>
  </v-card>
    <!--    <input-dialog-->
    <!--      ref="modifydialog"-->
    <!--      v-bind:operator="operator"-->
    <!--      v-bind:title="dialogTitle"-->
    <!--      v-bind:itemSchemas="itemSchemas"-->
    <!--      v-bind:actions="dialogActions"-->
    <!--      v-on:dialog-commit="saveDialog"-->
    <!--      v-on:dialog-action="invokeAction"-->
    <!--    >-->
    <!--    </input-dialog>-->
</template>

<script>
import moment from "moment";

export default {
  name: "EntityView",
  props: {
    itemSchemas: {
      type: Array,
    },
    dense: {
      default: false,
    },
    items: {},
    pagination: {},
    searchData: {},
    title: {},
    select: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type:Number,
      default:2
    },
    options: {},
    readonly: {
      type: Boolean,
      default: false
    },
    itemKey: {
      default: "Id",
    },
    hiddenHeader: {
      type: Boolean,
      default: false
    },
    actionName: {
      type: String,
      default: "action"
    }
  },
  data() {
    return {
      page:0,
      headers: [],
      selection: [],
    };
  },
  watch: {
    page: {
      handler: function(newValue) {
        this.pagination.current = newValue;
        this.$emit("page-change", this.pagination);
      }
    },
    selection: function(newValue) {
      this.$emit("selection-change", newValue);
    }
    // items: {
    //   handler: function(newValue) {
    //     console.log("Item Count: " + newValue.length);
    //   }
    // },
    // itemSchemas: {
    //   handler: function(newValue) {
    //     console.log("Schemas Count: " + newValue.length);
    //   }
    // }
  },
  mounted() {
    this.appendAction();
    this.loadHeaders();
    this.page = this.pagination.current;
  },
  methods: {
    appendAction() {
      if (this.options != undefined && !this.options.showAction) {
        return;
      }

      this.itemSchemas.push({
        text: this.$t("action"),
        align: "center",
        value: this.actionName,
      });
    },
    loadHeaders() {
      const headers = this.headers;
      this.itemSchemas.forEach((schema) => {
        if (schema.show == undefined || schema.show == true) {
          headers.push(schema);
        }
      });
    },
    formatValue(header, item, value) {
      if (header.formatter != undefined) {
        return header.formatter(item, value);
      } else {
        if (header.type == "date") {
          let pattern = "YYYY-MM-DD HH:mm:ss";
          if(header.pattern != undefined) {
            pattern = header.pattern;
          } else if(this.options != undefined) {
            if (this.options.datePattern != undefined) {
              pattern = this.options.datePattern;
            } else if (this.options.dateTimePattern != undefined) {
              pattern = this.options.dateTimePattern;
            }
          }
          if (typeof value == 'string' && value.endsWith('Z')) {
            var stillUtc = moment.utc(value).toDate();
            return moment(stillUtc).local().format(pattern);
          } else {
            return moment(value).local().format(pattern);
          }
        } else if (header.type == "datetime") {
          let pattern = "YYYY-MM-DD HH:mm:ss";
          if(header.pattern != undefined) {
            pattern = header.pattern;
          } else if(this.options != undefined) {
            if (this.options.dateTimePattern != undefined) {
              pattern = this.options.dateTimePattern;
            }
          }

          if (typeof value == 'string' && value.endsWith('Z')) {
            var stillUtcDT = moment.utc(value).toDate();
            return moment(stillUtcDT).local().format(pattern);
          } else {
            return moment(value).local().format(pattern);
          }
        } else if (header.type == "dropdown") {
          let displayName = value;
          if (header.dropdown != undefined) {
            let items = [];
            if (typeof header.dropdown === 'function') {
              items = header.dropdown(item);
            } else {
              items = header.dropdown;
            }
            items.forEach(element => {
              if (element.value == value) {
                if (header.itemText != undefined) {
                  let values = header.itemText.split(".");
                  displayName = element;
                  values.forEach(nameText => {
                    displayName = displayName[nameText];
                  })
                } else {
                  displayName = element.text;
                }
              }
            });
          }
          return displayName;
        } else {
          return value;
        }
      }
    },
    executeOp(item, op) {
      this.$emit("item-action", op, item);
    },
    refresh() {
      this.$emit("items-refresh");
    },
    clickRow(item) {
      this.$emit("click-row", item);
    },
    dbclickRow(evt, item) {
      this.$emit("double-click-row", item.item);
    },
    // Douglas the onPageChange could not use the this variable.
    onPageChange(page) {
      this.pagination.current = page;
      this.$emit("page-change", this.pagination);
    },
    itemSelected(item, selected) {
      this.$emit("item-selected", item, selected);
    }
  },
};
</script>

<style scoped>
.div-space {
  margin-left: 4px;
}

.d-hidden {
  visibility: hidden;
}
</style>

<i18n>
{
  "en": {
    "action": "Action",
    "no_data_text" : "No data available"
  },
  "cn": {
    "action": "操作",
    "no_data_text" : "没有数据"
  }
}
</i18n>
