<template>
  <v-container fluid>
    <v-navigation-drawer app fixed clipped width="400">
      <template v-slot:prepend :elevation="3">
        <v-list-item>
          <v-list-item-title>{{ $t("search") }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item>
          <v-text-field prepend-inner-icon="mdi-magnify" rounded solo>
          </v-text-field>
        </v-list-item> -->
        <v-divider></v-divider>
      </template>
      <template v-slot:append :elevation="3">
        <v-divider></v-divider>
        <div class="d-flex pa-4">
          <v-btn color="primary" class="mr-4" @click="filter()">{{
            $t("apply_filter")
          }}</v-btn>
          <v-btn color="primary" @click="clearFilter()">{{
            $t("cancel")
          }}</v-btn>
        </div>
      </template>

      <div class="pa-2">
        <filter-part ref="filterView"> </filter-part>
      </div>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <entity-view
        :item-schemas="itemSchemas"
        :readonly="readonly"
        :items="items"
        :title="$t('lawyer')"
        :pagination="pagination"
        :externalActions="externalActions"
        :options="options"
        @items-refresh="itemsRefresh"
        @item-action="itemAction"
        @page-change="pageChange"
        @double-click-row="clickRow"
      >
        <template v-slot:status="item">
          <!-- <v-icon v-if="item.value" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-if="!item.value" small color="error">
            mdi-close-circle
          </v-icon> -->
          <v-chip :color="item.value == true ? 'red': 'green'" class="mr-4 white--text">{{ item.value == true ? "在线" : "离线" }}</v-chip>
        </template>
        <template v-slot:forbidden="item">
          <!-- <v-icon v-if="item.value" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-if="!item.value" small color="error">
            mdi-close-circle
          </v-icon> -->
          <v-chip :color="item.value == true ? 'green': 'red'" class="mr-4 white--text">{{ item.value == true ? "禁止登录" : "允许登录" }}</v-chip>
        </template>
      </entity-view>
    </div>
    <input-dialog></input-dialog>
  </v-container>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import InputDialog from "../components/InputDialog.vue";
import FilterPart from "./FilterPart.vue";

export default {
  components: { EntityView, InputDialog, FilterPart },
  data: () => ({
    title: "Lawyer",
    select: true,
    expendOpen: false,
    readonly: true,
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "name",
        value: "name",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "licenseNo",
        value: "licenseNo",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "office",
        value: "office",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "enable",
        value: "enable",
        type: "boolean",
        hasfilter: true,
        condition: {},
        template: "status",
        hasTemplate: true,
      },
      {
        name: "forbidden",
        value: "forbidden",
        type: "boolean",
        hasfilter: true,
        condition: {},
        template: "forbidden",
        hasTemplate: true,
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: false,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.$refs.filterView.loadSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let postData = {
        pagination: this.pagination,
        criterias: this.$refs.filterView.getCriterias(),
      };
      this.post("/api/lawyers", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.$router.push({
        name: "LawyerDetail",
        params: { id: item.id + "" },
      });
    },
    itemCreate() {
      this.$router.push({ name: "LawyerDetail" });
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name) {
      if (name === "insert") {
        this.itemCreate();
      }
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
    "en": {
    "c_enable": "Is Online",
    "c_userId": "UserId"
  },
  "cn": {
    "c_enable": "是否在线",
    "c_userId": "客户ID"
  }
}
</i18n>
