<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <!-- <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ entity.fullName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn color="primary mr-4" fab small @click="$router.go(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              {{ $t("status") + ": " + this.status }}
              <v-spacer></v-spacer>
              <v-btn
                v-if="entity['status'] == 'request'"
                class="warning ml-4"
                @click="openDialog('paid')"
              >
                {{ role == "lawyer" ? $t("paid") : $t("make_invoice") }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
          <v-card class="form-container mt-4" ref="taxInfo">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("taxInfo") }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="taxSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="taxView"
              ></form-view>
            </v-card-text>
          </v-card>
          <v-card v-if="role == 'lawyer'" class="form-container mt-4" ref="pay">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("pay") }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="paySchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="payView"
              ></form-view>
            </v-card-text>
          </v-card>
          <v-card class="form-container mt-4" ref="address">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("address") }}</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="addressSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="addressView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="actionDialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogDescription }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="actionDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              actionDialog = false;
              commitAction();
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormView from "../components/FormView.vue";
import showDialog from "./show-dialog.js";
import BreadcrumbEx from "../components/BreadcrumbEx.vue";
import InvoiceStatus from "../common/invoice_status";
import Utils from "../common/utils";

export default {
  components: { FormView, BreadcrumbEx },
  props: {
    id: {},
    role: {},
  },
  data: () => ({
    title: "payment",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    actionDialog: false,
    colPerRow: 1,
    isCreate: false,
    dialogTitle: "",
    dialogDescription: "",
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "taxInfo",
        text: "taxInfo",
        tag: "taxInfo",
      },
      {
        name: "pay",
        text: "pay",
        tag: "pay",
      },
      {
        name: "address",
        text: "address",
        tag: "address",
      },
    ],
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      {
        name: "orderNo",
        value: "orderNo",
        type: "string",
      },
      {
        name: "orderId",
        value: "orderId",
        type: "string",
      },
      {
        name: "userId",
        value: "userId",
        type: "string",
        show: (item) => {
          return item["userId"] != null;
        },
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
        show: (item) => {
          return item["userName"] != null;
        },
      },
      {
        name: "lawyerId",
        value: "lawyerId",
        type: "string",
        show: (item) => {
          return item["lawyerId"] != null;
        },
      },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
        show: (item) => {
          return item["lawyerName"] != null;
        },
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
      },
      {
        name: "sharePercent",
        value: "sharePercent",
        type: "number",
        show: (item) => {
          return item["sharePercent"] != null;
        },
      },
      {
        name: "revenueShare",
        value: "revenueShare",
        type: "number",
        show: (item) => {
          return item["revenueShare"] != null;
        },
      },
      {
        name: "lawyerShare",
        value: "lawyerShare",
        type: "number",
        show: (item) => {
          return item["lawyerShare"] != null;
        },
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "datetime",
      },
    ],
    taxSchemas: [
      {
        name: "invoiceKind",
        value: "invoiceKind",
        type: "dropdown",
        localized: true,
        dropdown: InvoiceStatus.kinds
      },
      {
        name: "title",
        value: "title",
        type: "string",
      },
      {
        name: "taxNumber",
        value: "taxNumber",
        type: "string",
      },
      {
        name: "companyAddress",
        value: "companyAddress",
        type: "string",
      },
      {
        name: "telephone",
        value: "telephone",
        type: "string",
      },
      {
        name: "bankName",
        value: "bankName",
        type: "string",
      },
      {
        name: "bankAccount",
        value: "bankAccount",
        type: "string",
      },
    ],
    addressSchemas: [
      {
        name: "toName",
        value: "toName",
        type: "string",
      },
      {
        name: "toAddress",
        value: "toAddress",
        type: "string",
      },
      {
        name: "toPhone",
        value: "toPhone",
        type: "string",
      },
      {
        name: "toEmail",
        value: "toEmail",
        type: "string",
      },
    ],
    paySchemas: [
      {
        name: "payName",
        value: "payName",
        type: "string",
      },
      {
        name: "payBankName",
        value: "payBankName",
        type: "string",
      },
      {
        name: "payBankAccount",
        value: "payBankAccount",
        type: "string",
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.refresh();
    this.isCreate = this.id == undefined;
    console.log(`Invoice Id: ${this.id}, Role:${this.role}`);
  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.localizeSchema(this.taxSchemas);
      this.localizeSchema(this.addressSchemas);
      if (this.role == "lawyer") {
        this.localizeSchema(this.paySchemas);
      }
    },
    updateComponent() {
      this.refresh();
    },
    openStatus() {
      this.statusDialog = true;
      this.getStatus();
    },
    refresh() {
      let utils = new Utils(this);
      let _this = this;
      if (this.id == undefined) {
        return;
      }
      this.get("/api/invoice/" + this.id, {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
            _this.$refs.taxView.loadEntity(data.data);
            _this.$refs.addressView.loadEntity(data.data);
            if (_this.role == "lawyer") {
              _this.$refs.payView.loadEntity(data.data);
            }
          }
          _this.status = utils.getLocaleText(
            InvoiceStatus.values,
            data.data["status"]
          );
        },
      });
    },
    openDialog(action) {
      this.action = action;
      if (action == "paid") {
        if (this.role == "lawyer") {
          this.dialogTitle = this.$t("invoice_title");
          this.dialogDescription = this.$t("invoice_description");
        } else {
          this.dialogTitle = this.$t("make_invoice_title");
          this.dialogDescription = this.$t("make_invoice_description");
        }
      }
      this.actionDialog = true;
    },
    commitAction() {
      let that = this;
      if (this.action == "paid") {
        let option = {
          callback: function () {
            that.refresh();
          },
        };
        this.get(`/api/invoice/` + this.id + "/paid", option);
      }
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {
    "taxInfo": "发票信息",
    "c_taxNumber": "税号",
    "c_companyAddress": "单位地址",
    "c_telephone":"电话",
    "c_bankName":"开户行",
    "c_bankAccount":"银行账户",
    "c_payName": "收款人姓名",
    "c_payBankName": "开户行",
    "c_payBankAccount": "开户行账号",
    "c_toName": "收件人姓名",
    "c_toAddress": "收件人地址",
    "c_toPhone": "收件人电话",
    "c_toEmail": "收件人邮箱",
    "c_invoiceKind": "发票类型"
  },
  "cn": {
    "taxInfo": "发票信息",
    "c_taxNumber": "税号",
    "c_companyAddress": "单位地址",
    "c_telephone":"电话",
    "c_bankName":"开户行",
    "c_bankAccount":"银行账户",
    "c_payName": "收款人姓名",
    "c_payBankName": "开户行",
    "c_payBankAccount": "开户行账号",
    "c_toName": "收件人姓名",
    "c_toAddress": "收件人地址",
    "c_toPhone": "收件人电话",
    "c_toEmail": "收件人邮箱",
    "c_invoiceKind": "发票类型"
  }
}
</i18n>
