<template>
  <v-card class="form-container" ref="genernal">
    <v-card-title>
      <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="editMode"
        color="primary"
        @click="save('editMode', $refs.mainView)"
      >
        {{ $t("save") }}
      </v-btn>
      <v-btn v-if="!editMode" color="primary" @click="edit('editMode')">
        {{ $t("edit") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <form-view
        :itemSchemas="itemSchemas"
        :readonly="!editMode"
        ref="mainView"
        :colPerRow="colPerRow"
      ></form-view>
    </v-card-text>
  </v-card>
</template>

<script>
import FormView from "../components/FormView.vue";

export default {
  components: { FormView },
  props: {
  },
  data: () => ({
    title: "Genernal",
    entity: {},
    editMode: false,
    editModeNames: ["editMode"],
    dialog: false,
    isCreate: false,
    colPerRow: 1,
    itemSchemas: [
      {
        name: "email",
        value: "email",
        type: "string",
        readonly: true,
      },
      {
        name: "phoneNumber",
        value: "phoneNumber",
        type: "string",
        // readonly: true,
      },
      {
        name: "nickname",
        value: "nickname",
        type: "string",
      },
      // {
      //   name: "language",
      //   value: "language",
      //   type: "dropdown",
      //   dropdown: [
      //     {
      //       value: "cn",
      //       text: "简体中文"
      //     },
      //     {
      //       value: "en",
      //       text: "English"
      //     }
      //   ],
      // },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {},
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      this.get("/api/user/current", {
        callback: function (data) {
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
          _this.entity = data.data;
        },
      });
    },
    edit(editModeName) {
      this.setEditMode(editModeName, true);
    },
    save(editModeName, refView) {
      this.setEditMode(editModeName, false);
      this.saveInternal(refView);
    },
    setEditMode(editModeName, value) {
      let _this = this;
      this.editModeNames.forEach((name) => {
        if (editModeName == name) {
          _this[name] = value;
        }
      });
    },
    saveInternal(refView) {
      let _this = this;
      let entity = refView.commitEntity();
      let option = {
        callback: function (data) {
          _this.entity = data.data;
        },
      };
      this.put("/api/user/current", entity, option);
    },
  },
};
</script>

