export default {
  Kinds: {
      "items": [{
        "name": "人格权纠纷",
        "children": [
            {
                "name": "人格权纠纷",
                "children": [
                    {
                        "name": "生命权、身体权、健康权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 1
                    },
                    {
                        "name": "姓名权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 2
                    },
                    {
                        "name": "名称权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 3
                    },
                    {
                        "name": "肖像权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 4
                    },
                    {
                        "name": "声音保护纠纷",
                        "children": [],
                        "level": 3,
                        "index": 5
                    },
                    {
                        "name": "名誉权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 6
                    },
                    {
                        "name": "荣誉权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 7
                    },
                    {
                        "name": "隐私权、个人信息保护纠纷",
                        "children": [
                            {
                                "name": "隐私权纠纷",
                                "level": 4,
                                "index": "8.1"
                            },
                            {
                                "name": "个人信息保护纠纷",
                                "level": 4,
                                "index": "8.2"
                            }
                        ],
                        "level": 3,
                        "index": 8
                    },
                    {
                        "name": "婚姻自主权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 9
                    },
                    {
                        "name": "人身自由权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 10
                    },
                    {
                        "name": "一般人格权纠纷",
                        "children": [
                            {
                                "name": "平等就业权纠纷",
                                "level": 4,
                                "index": "11.1"
                            }
                        ],
                        "level": 3,
                        "index": 11
                    }
                ],
                "level": 2,
                "index": 1
            }
        ],
        "level": 1,
        "index": 1
    },
    {
        "name": " 婚姻家庭、继承纠纷",
        "children": [
            {
                "name": "婚姻家庭纠纷",
                "children": [
                    {
                        "name": "婚约财产纠纷",
                        "children": [],
                        "level": 3,
                        "index": 12
                    },
                    {
                        "name": "婚内夫妻财产分割纠纷",
                        "children": [],
                        "level": 3,
                        "index": 13
                    },
                    {
                        "name": "离婚纠纷",
                        "children": [],
                        "level": 3,
                        "index": 14
                    },
                    {
                        "name": "离婚(后)财产纠纷",
                        "children": [],
                        "level": 3,
                        "index": 15
                    },
                    {
                        "name": "离婚(后)损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 16
                    },
                    {
                        "name": "婚姻无效纠纷",
                        "children": [],
                        "level": 3,
                        "index": 17
                    },
                    {
                        "name": "撤销婚姻纠纷",
                        "children": [],
                        "level": 3,
                        "index": 18
                    },
                    {
                        "name": "夫妻财产约定纠纷",
                        "children": [],
                        "level": 3,
                        "index": 19
                    },
                    {
                        "name": "同居关系纠纷",
                        "children": [
                            {
                                "name": "同居关系析产纠纷",
                                "level": 4,
                                "index": "20.1"
                            },
                            {
                                "name": "同居关系子女抚养纠纷",
                                "level": 4,
                                "index": "20.2"
                            }
                        ],
                        "level": 3,
                        "index": 20
                    },
                    {
                        "name": "亲子关系纠纷",
                        "children": [
                            {
                                "name": "确认亲子关系纠纷",
                                "level": 4,
                                "index": "21.1"
                            },
                            {
                                "name": "否认亲子关系纠纷",
                                "level": 4,
                                "index": "21.2"
                            }
                        ],
                        "level": 3,
                        "index": 21
                    },
                    {
                        "name": "抚养纠纷",
                        "children": [
                            {
                                "name": "抚养费纠纷",
                                "level": 4,
                                "index": "22.1"
                            },
                            {
                                "name": "(变更)抚养关系纠纷",
                                "level": 4,
                                "index": "22.2"
                            }
                        ],
                        "level": 3,
                        "index": 22
                    },
                    {
                        "name": "扶养纠纷",
                        "children": [
                            {
                                "name": "扶养费纠纷",
                                "level": 4,
                                "index": "23.1"
                            },
                            {
                                "name": "(变更)扶养关系纠纷",
                                "level": 4,
                                "index": "23.2"
                            }
                        ],
                        "level": 3,
                        "index": 23
                    },
                    {
                        "name": "赡养纠纷",
                        "children": [
                            {
                                "name": "赡养费纠纷",
                                "level": 4,
                                "index": "24.1"
                            },
                            {
                                "name": "(变更)赡养关系纠纷",
                                "level": 4,
                                "index": "24.2"
                            }
                        ],
                        "level": 3,
                        "index": 24
                    },
                    {
                        "name": "收养关系纠纷",
                        "children": [
                            {
                                "name": "确认收养关系纠纷",
                                "level": 4,
                                "index": "25.1"
                            },
                            {
                                "name": "解除收养关系纠纷",
                                "level": 4,
                                "index": "25.2"
                            }
                        ],
                        "level": 3,
                        "index": 25
                    },
                    {
                        "name": "监护权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 26
                    },
                    {
                        "name": "探望权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 27
                    },
                    {
                        "name": "分家析产纠纷",
                        "children": [],
                        "level": 3,
                        "index": 28
                    }
                ],
                "level": 2,
                "index": 2
            },
            {
                "name": "继承纠纷",
                "children": [
                    {
                        "name": "法定继承纠纷",
                        "children": [
                            {
                                "name": "普通法定继承纠纷",
                                "level": 4,
                                "index": "29.1"
                            },
                            {
                                "name": "转继承纠纷",
                                "level": 4,
                                "index": "29.2"
                            },
                            {
                                "name": "代位继承纠纷",
                                "level": 4,
                                "index": "29.3"
                            }
                        ],
                        "level": 3,
                        "index": 29
                    },
                    {
                        "name": "遗嘱继承纠纷",
                        "children": [],
                        "level": 3,
                        "index": 30
                    },
                    {
                        "name": "被继承人债务清偿纠纷",
                        "children": [],
                        "level": 3,
                        "index": 31
                    },
                    {
                        "name": "遗赠纠纷",
                        "children": [],
                        "level": 3,
                        "index": 32
                    },
                    {
                        "name": "遗赠扶养协议纠纷",
                        "children": [],
                        "level": 3,
                        "index": 33
                    },
                    {
                        "name": "遗产管理纠纷",
                        "children": [],
                        "level": 3,
                        "index": 34
                    }
                ],
                "level": 2,
                "index": 3
            }
        ],
        "level": 1,
        "index": 2
    },
    {
        "name": " 物权纠纷",
        "children": [
            {
                "name": "不动产登记纠纷",
                "children": [
                    {
                        "name": "异议登记不当损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 35
                    },
                    {
                        "name": "虚假登记损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 36
                    }
                ],
                "level": 2,
                "index": 4
            },
            {
                "name": "物权保护纠纷",
                "children": [
                    {
                        "name": "物权确认纠纷",
                        "children": [
                            {
                                "name": "所有权确认纠纷",
                                "level": 4,
                                "index": "37.1"
                            },
                            {
                                "name": "用益物权确认纠纷",
                                "level": 4,
                                "index": "37.2"
                            },
                            {
                                "name": "担保物权确认纠纷",
                                "level": 4,
                                "index": "37.3"
                            }
                        ],
                        "level": 3,
                        "index": 37
                    },
                    {
                        "name": "返还原物纠纷",
                        "children": [],
                        "level": 3,
                        "index": 38
                    },
                    {
                        "name": "排除妨害纠纷",
                        "children": [],
                        "level": 3,
                        "index": 39
                    },
                    {
                        "name": "消除危险纠纷",
                        "children": [],
                        "level": 3,
                        "index": 40
                    },
                    {
                        "name": "修理、重作、更换纠纷",
                        "children": [],
                        "level": 3,
                        "index": 41
                    },
                    {
                        "name": "恢复原状纠纷",
                        "children": [],
                        "level": 3,
                        "index": 42
                    },
                    {
                        "name": "财产损害赔偿纠纷",
                        "children": [],
                        "level": 3,
                        "index": 43
                    }
                ],
                "level": 2,
                "index": 5
            },
            {
                "name": "所有权纠纷",
                "children": [
                    {
                        "name": "侵害集体经济组织成员权益纠纷",
                        "children": [],
                        "level": 3,
                        "index": 44
                    },
                    {
                        "name": "建筑物区分所有权纠纷",
                        "children": [
                            {
                                "name": "业主专有权纠纷",
                                "level": 4,
                                "index": "45.1"
                            },
                            {
                                "name": "业主共有权纠纷",
                                "level": 4,
                                "index": "45.2"
                            },
                            {
                                "name": "车位纠纷",
                                "level": 4,
                                "index": "45.3"
                            },
                            {
                                "name": "车库纠纷",
                                "level": 4,
                                "index": "45.4"
                            }
                        ],
                        "level": 3,
                        "index": 45
                    },
                    {
                        "name": "业主撤销权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 46
                    },
                    {
                        "name": "业主知情权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 47
                    },
                    {
                        "name": "遗失物返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 48
                    },
                    {
                        "name": "漂流物返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 49
                    },
                    {
                        "name": "埋藏物返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 50
                    },
                    {
                        "name": "隐藏物返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 51
                    },
                    {
                        "name": "添附物归属纠纷",
                        "children": [],
                        "level": 3,
                        "index": 52
                    },
                    {
                        "name": "相邻关系纠纷",
                        "children": [
                            {
                                "name": "相邻用水",
                                "level": 4,
                                "index": "53.1"
                            },
                            {
                                "name": "相邻通行纠纷",
                                "level": 4,
                                "index": "53.2"
                            },
                            {
                                "name": "相邻土地",
                                "level": 4,
                                "index": "53.3"
                            },
                            {
                                "name": "相邻通风纠纷",
                                "level": 4,
                                "index": "53.4"
                            },
                            {
                                "name": "相邻采光",
                                "level": 4,
                                "index": "53.5"
                            },
                            {
                                "name": "相邻污染侵害纠纷",
                                "level": 4,
                                "index": "53.6"
                            },
                            {
                                "name": "相邻损害防免关系纠纷",
                                "level": 4,
                                "index": "53.7"
                            }
                        ],
                        "level": 3,
                        "index": 53
                    },
                    {
                        "name": "共有纠纷",
                        "children": [
                            {
                                "name": "共有权确认纠纷",
                                "level": 4,
                                "index": "54.1"
                            },
                            {
                                "name": "共有物分割纠纷",
                                "level": 4,
                                "index": "54.2"
                            },
                            {
                                "name": "共有人优先购买权纠纷",
                                "level": 4,
                                "index": "54.3"
                            },
                            {
                                "name": "债权人代位析产纠纷七",
                                "level": 4,
                                "index": "54.4"
                            }
                        ],
                        "level": 3,
                        "index": 54
                    },
                    {
                        "name": "海域使用权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 55
                    },
                    {
                        "name": "探矿权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 56
                    },
                    {
                        "name": "采矿权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 57
                    },
                    {
                        "name": "取水权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 58
                    },
                    {
                        "name": "养殖权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 59
                    },
                    {
                        "name": "捕捞权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 60
                    },
                    {
                        "name": "土地承包经营权纠纷",
                        "children": [
                            {
                                "name": "土地承包经营权确认纠纷",
                                "level": 4,
                                "index": "61.1"
                            },
                            {
                                "name": "承包地征收补偿费用分配纠纷",
                                "level": 4,
                                "index": "61.2"
                            },
                            {
                                "name": "土地承包经营权继承纠纷",
                                "level": 4,
                                "index": "61.3"
                            }
                        ],
                        "level": 3,
                        "index": 61
                    },
                    {
                        "name": "土地经营权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 62
                    },
                    {
                        "name": "建设用地使用权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 63
                    },
                    {
                        "name": "宅基地使用权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 64
                    },
                    {
                        "name": "居住权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 65
                    },
                    {
                        "name": "地役权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 66
                    }
                ],
                "level": 2,
                "index": 6
            },
            {
                "name": "担保物权纠纷",
                "children": [
                    {
                        "name": "抵押权纠纷",
                        "children": [
                            {
                                "name": "建筑物和其他土地附着物抵押权纠纷",
                                "level": 4,
                                "index": "67.1"
                            },
                            {
                                "name": "在建建筑物抵押权纠纷",
                                "level": 4,
                                "index": "67.2"
                            },
                            {
                                "name": "建设用地使用权抵押权纠纷",
                                "level": 4,
                                "index": "67.3"
                            },
                            {
                                "name": "土地承包经营权抵押权纠纷",
                                "level": 4,
                                "index": "67.4"
                            },
                            {
                                "name": "探矿权抵押权纠纷",
                                "level": 4,
                                "index": "67.5"
                            },
                            {
                                "name": "采矿权抵押权纠纷",
                                "level": 4,
                                "index": "67.6"
                            },
                            {
                                "name": "海域使用权抵押权纠纷",
                                "level": 4,
                                "index": "67.7"
                            },
                            {
                                "name": "动产抵押权纠纷",
                                "level": 4,
                                "index": "67.8"
                            },
                            {
                                "name": "在建船舶",
                                "level": 4,
                                "index": "67.9"
                            },
                            {
                                "name": "动产浮动抵押权纠纷",
                                "level": 4,
                                "index": "67.10"
                            },
                            {
                                "name": "最高额抵押权纠纷",
                                "level": 4,
                                "index": "67.11"
                            }
                        ],
                        "level": 3,
                        "index": 67
                    },
                    {
                        "name": "质权纠纷",
                        "children": [
                            {
                                "name": "动产质权纠纷",
                                "level": 4,
                                "index": "68.1"
                            },
                            {
                                "name": "转质权纠纷",
                                "level": 4,
                                "index": "68.2"
                            },
                            {
                                "name": "最高额质权纠纷",
                                "level": 4,
                                "index": "68.3"
                            },
                            {
                                "name": "票据质权纠纷",
                                "level": 4,
                                "index": "68.4"
                            },
                            {
                                "name": "债券质权纠纷",
                                "level": 4,
                                "index": "68.5"
                            },
                            {
                                "name": "存单质权纠纷",
                                "level": 4,
                                "index": "68.6"
                            },
                            {
                                "name": "仓单质权纠纷",
                                "level": 4,
                                "index": "68.7"
                            },
                            {
                                "name": "提单质权纠纷",
                                "level": 4,
                                "index": "68.8"
                            },
                            {
                                "name": "股权质权纠纷",
                                "level": 4,
                                "index": "68.9"
                            },
                            {
                                "name": "基金份额质权纠纷",
                                "level": 4,
                                "index": "68.10"
                            },
                            {
                                "name": "知识产权质权纠纷",
                                "level": 4,
                                "index": "68.11"
                            },
                            {
                                "name": "应收账款质权纠纷",
                                "level": 4,
                                "index": "68.12"
                            }
                        ],
                        "level": 3,
                        "index": 68
                    },
                    {
                        "name": "留置权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 69
                    }
                ],
                "level": 2,
                "index": 8
            },
            {
                "name": "占有保护纠纷",
                "children": [
                    {
                        "name": "占有物返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 70
                    },
                    {
                        "name": "占有排除妨害纠纷",
                        "children": [],
                        "level": 3,
                        "index": 71
                    },
                    {
                        "name": "占有消除危险纠纷",
                        "children": [],
                        "level": 3,
                        "index": 72
                    },
                    {
                        "name": "占有物损害赔偿纠纷",
                        "children": [],
                        "level": 3,
                        "index": 73
                    }
                ],
                "level": 2,
                "index": 9
            }
        ],
        "level": 1,
        "index": 3
    },
    {
        "name": " 合同、准合同纠纷",
        "children": [
            {
                "name": "合同纠纷",
                "children": [
                    {
                        "name": "缔约过失责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 74
                    },
                    {
                        "name": "预约合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 75
                    },
                    {
                        "name": "确认合同效力纠纷",
                        "children": [
                            {
                                "name": "确认合同有效纠纷",
                                "level": 4,
                                "index": "76.1"
                            },
                            {
                                "name": "确认合同无效纠纷",
                                "level": 4,
                                "index": "76.2"
                            }
                        ],
                        "level": 3,
                        "index": 76
                    },
                    {
                        "name": "债权人代位权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 77
                    },
                    {
                        "name": "债权人撤销权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 78
                    },
                    {
                        "name": "债权转让合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 79
                    },
                    {
                        "name": "债务转移合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 80
                    },
                    {
                        "name": "债权债务概括转移合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 81
                    },
                    {
                        "name": "债务加入纠纷",
                        "children": [],
                        "level": 3,
                        "index": 82
                    },
                    {
                        "name": "悬赏广告纠纷",
                        "children": [],
                        "level": 3,
                        "index": 83
                    },
                    {
                        "name": "买卖合同纠纷",
                        "children": [
                            {
                                "name": "分期付款买卖合同纠纷",
                                "level": 4,
                                "index": "84.1"
                            },
                            {
                                "name": "凭样品买卖合同纠纷",
                                "level": 4,
                                "index": "84.2"
                            },
                            {
                                "name": "试用买卖合同纠纷",
                                "level": 4,
                                "index": "84.3"
                            },
                            {
                                "name": "所有权保留买卖合同纠纷",
                                "level": 4,
                                "index": "84.4"
                            },
                            {
                                "name": "招标投标买卖合同纠纷",
                                "level": 4,
                                "index": "84.5"
                            },
                            {
                                "name": "互易纠纷",
                                "level": 4,
                                "index": "84.6"
                            },
                            {
                                "name": "国际货物买卖合同纠纷",
                                "level": 4,
                                "index": "84.7"
                            },
                            {
                                "name": "信息网络买卖合同纠纷",
                                "level": 4,
                                "index": "84.8"
                            }
                        ],
                        "level": 3,
                        "index": 84
                    },
                    {
                        "name": "拍卖合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 85
                    },
                    {
                        "name": "建设用地使用权合同纠纷",
                        "children": [
                            {
                                "name": "建设用地使用权出让合同纠纷",
                                "level": 4,
                                "index": "86.1"
                            },
                            {
                                "name": "建设用地使用权转让合同纠纷",
                                "level": 4,
                                "index": "86.2"
                            }
                        ],
                        "level": 3,
                        "index": 86
                    },
                    {
                        "name": "临时用地合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 87
                    },
                    {
                        "name": "探矿权转让合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 88
                    },
                    {
                        "name": "采矿权转让合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 89
                    },
                    {
                        "name": "房地产开发经营合同纠纷",
                        "children": [
                            {
                                "name": "委托代建合同纠纷",
                                "level": 4,
                                "index": "90.1"
                            },
                            {
                                "name": "合资",
                                "level": 4,
                                "index": "90.2"
                            },
                            {
                                "name": "项目转让合同纠纷",
                                "level": 4,
                                "index": "90.3"
                            }
                        ],
                        "level": 3,
                        "index": 90
                    },
                    {
                        "name": "房屋买卖合同纠纷",
                        "children": [
                            {
                                "name": "商品房预约合同纠纷",
                                "level": 4,
                                "index": "91.1"
                            },
                            {
                                "name": "商品房预售合同纠纷",
                                "level": 4,
                                "index": "91.2"
                            },
                            {
                                "name": "商品房销售合同纠纷",
                                "level": 4,
                                "index": "91.3"
                            },
                            {
                                "name": "商品房委托代理销售合同纠纷",
                                "level": 4,
                                "index": "91.4"
                            },
                            {
                                "name": "经济适用房转让合同纠纷",
                                "level": 4,
                                "index": "91.5"
                            },
                            {
                                "name": "农村房屋买卖合同纠纷",
                                "level": 4,
                                "index": "91.6"
                            }
                        ],
                        "level": 3,
                        "index": 91
                    },
                    {
                        "name": "民事主体间房屋拆迁补偿合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 92
                    },
                    {
                        "name": "供用电合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 93
                    },
                    {
                        "name": "供用水合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 94
                    },
                    {
                        "name": "供用气合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 95
                    },
                    {
                        "name": "供用热力合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 96
                    },
                    {
                        "name": "排污权交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 97
                    },
                    {
                        "name": "用能权交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 98
                    },
                    {
                        "name": "用水权交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 99
                    },
                    {
                        "name": "碳排放权交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 100
                    },
                    {
                        "name": "碳汇交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 101
                    },
                    {
                        "name": "赠与合同纠纷",
                        "children": [
                            {
                                "name": "公益事业捐赠合同纠纷",
                                "level": 4,
                                "index": "102.1"
                            },
                            {
                                "name": "附义务赠与合同纠纷",
                                "level": 4,
                                "index": "102.2"
                            }
                        ],
                        "level": 3,
                        "index": 102
                    },
                    {
                        "name": "借款合同纠纷",
                        "children": [
                            {
                                "name": "金融借款合同纠纷",
                                "level": 4,
                                "index": "103.1"
                            },
                            {
                                "name": "同业拆借纠纷",
                                "level": 4,
                                "index": "103.2"
                            },
                            {
                                "name": "民间借贷纠纷",
                                "level": 4,
                                "index": "103.3"
                            },
                            {
                                "name": "小额借款合同纠纷",
                                "level": 4,
                                "index": "103.4"
                            },
                            {
                                "name": "金融不良债权转让合同纠纷",
                                "level": 4,
                                "index": "103.5"
                            },
                            {
                                "name": "金融不良债权追偿纠纷",
                                "level": 4,
                                "index": "103.6"
                            }
                        ],
                        "level": 3,
                        "index": 103
                    },
                    {
                        "name": "保证合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 104
                    },
                    {
                        "name": "抵押合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 105
                    },
                    {
                        "name": "质押合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 106
                    },
                    {
                        "name": "定金合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 107
                    },
                    {
                        "name": "进出口押汇纠纷",
                        "children": [],
                        "level": 3,
                        "index": 108
                    },
                    {
                        "name": "储蓄存款合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 109
                    },
                    {
                        "name": "银行卡纠纷",
                        "children": [
                            {
                                "name": "借记卡纠纷",
                                "level": 4,
                                "index": "110.1"
                            },
                            {
                                "name": "信用卡纠纷",
                                "level": 4,
                                "index": "110.2"
                            }
                        ],
                        "level": 3,
                        "index": 110
                    },
                    {
                        "name": "租赁合同纠纷",
                        "children": [
                            {
                                "name": "土地租赁合同纠纷",
                                "level": 4,
                                "index": "111.1"
                            },
                            {
                                "name": "房屋租赁合同纠纷",
                                "level": 4,
                                "index": "111.2"
                            },
                            {
                                "name": "车辆租赁合同纠纷",
                                "level": 4,
                                "index": "111.3"
                            },
                            {
                                "name": "建筑设备租赁合同纠纷",
                                "level": 4,
                                "index": "111.4"
                            }
                        ],
                        "level": 3,
                        "index": 111
                    },
                    {
                        "name": "融资租赁合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 112
                    },
                    {
                        "name": "保理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 113
                    },
                    {
                        "name": "承揽合同纠纷",
                        "children": [
                            {
                                "name": "加工合同纠纷",
                                "level": 4,
                                "index": "114.1"
                            },
                            {
                                "name": "定作合同纠纷",
                                "level": 4,
                                "index": "114.2"
                            },
                            {
                                "name": "修理合同纠纷",
                                "level": 4,
                                "index": "114.3"
                            },
                            {
                                "name": "复制合同纠纷",
                                "level": 4,
                                "index": "114.4"
                            },
                            {
                                "name": "测试合同纠纷",
                                "level": 4,
                                "index": "114.5"
                            },
                            {
                                "name": "检验合同纠纷",
                                "level": 4,
                                "index": "114.6"
                            },
                            {
                                "name": "铁路机车",
                                "level": 4,
                                "index": "114.7"
                            }
                        ],
                        "level": 3,
                        "index": 114
                    },
                    {
                        "name": "建设工程合同纠纷",
                        "children": [
                            {
                                "name": "建设工程勘察合同纠纷",
                                "level": 4,
                                "index": "115.1"
                            },
                            {
                                "name": "建设工程设计合同纠纷",
                                "level": 4,
                                "index": "115.2"
                            },
                            {
                                "name": "建设工程施工合同纠纷",
                                "level": 4,
                                "index": "115.3"
                            },
                            {
                                "name": "建设工程价款优先受偿权纠纷",
                                "level": 4,
                                "index": "115.4"
                            },
                            {
                                "name": "建设工程分包合同纠纷",
                                "level": 4,
                                "index": "115.5"
                            },
                            {
                                "name": "建设工程监理合同纠纷",
                                "level": 4,
                                "index": "115.6"
                            },
                            {
                                "name": "装饰装修合同纠纷",
                                "level": 4,
                                "index": "115.7"
                            },
                            {
                                "name": "铁路修建合同纠纷",
                                "level": 4,
                                "index": "115.8"
                            },
                            {
                                "name": "农村建房施工合同纠纷",
                                "level": 4,
                                "index": "115.9"
                            }
                        ],
                        "level": 3,
                        "index": 115
                    },
                    {
                        "name": "运输合同纠纷",
                        "children": [
                            {
                                "name": "公路旅客运输合同纠纷",
                                "level": 4,
                                "index": "116.1"
                            },
                            {
                                "name": "公路货物运输合同纠纷",
                                "level": 4,
                                "index": "116.2"
                            },
                            {
                                "name": "水路旅客运输合同纠纷",
                                "level": 4,
                                "index": "116.3"
                            },
                            {
                                "name": "水路货物运输合同纠纷",
                                "level": 4,
                                "index": "116.4"
                            },
                            {
                                "name": "航空旅客运输合同纠纷",
                                "level": 4,
                                "index": "116.5"
                            },
                            {
                                "name": "航空货物运输合同纠纷",
                                "level": 4,
                                "index": "116.6"
                            },
                            {
                                "name": "出租汽车运输合同纠纷",
                                "level": 4,
                                "index": "116.7"
                            },
                            {
                                "name": "管道运输合同纠纷",
                                "level": 4,
                                "index": "116.8"
                            },
                            {
                                "name": "城市公交运输合同纠纷",
                                "level": 4,
                                "index": "116.9"
                            },
                            {
                                "name": "联合运输合同纠纷",
                                "level": 4,
                                "index": "116.10"
                            },
                            {
                                "name": "多式联运合同纠纷",
                                "level": 4,
                                "index": "116.11"
                            },
                            {
                                "name": "铁路货物运输合同纠纷",
                                "level": 4,
                                "index": "116.12"
                            },
                            {
                                "name": "铁路旅客运输合同纠纷",
                                "level": 4,
                                "index": "116.13"
                            },
                            {
                                "name": "铁路行李运输合同纠纷",
                                "level": 4,
                                "index": "116.14"
                            },
                            {
                                "name": "铁路包裹运输合同纠纷",
                                "level": 4,
                                "index": "116.15"
                            },
                            {
                                "name": "国际铁路联运合同纠纷",
                                "level": 4,
                                "index": "116.16"
                            }
                        ],
                        "level": 3,
                        "index": 116
                    },
                    {
                        "name": "保管合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 117
                    },
                    {
                        "name": "仓储合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 118
                    },
                    {
                        "name": "委托合同纠纷",
                        "children": [
                            {
                                "name": "进出口代理合同纠纷",
                                "level": 4,
                                "index": "119.1"
                            },
                            {
                                "name": "货运代理合同纠纷",
                                "level": 4,
                                "index": "119.2"
                            },
                            {
                                "name": "民用航空运输销售代理合同纠纷",
                                "level": 4,
                                "index": "119.3"
                            },
                            {
                                "name": "诉讼",
                                "level": 4,
                                "index": "119.4"
                            },
                            {
                                "name": "销售代理合同纠纷",
                                "level": 4,
                                "index": "119.5"
                            }
                        ],
                        "level": 3,
                        "index": 119
                    },
                    {
                        "name": "委托理财合同纠纷",
                        "children": [
                            {
                                "name": "金融委托理财合同纠纷",
                                "level": 4,
                                "index": "120.1"
                            },
                            {
                                "name": "民间委托理财合同纠纷",
                                "level": 4,
                                "index": "120.2"
                            }
                        ],
                        "level": 3,
                        "index": 120
                    },
                    {
                        "name": "物业服务合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 121
                    },
                    {
                        "name": "行纪合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 122
                    },
                    {
                        "name": "中介合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 123
                    },
                    {
                        "name": "补偿贸易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 124
                    },
                    {
                        "name": "借用合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 125
                    },
                    {
                        "name": "典当纠纷",
                        "children": [],
                        "level": 3,
                        "index": 126
                    },
                    {
                        "name": "合伙合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 127
                    },
                    {
                        "name": "种植、养殖回收合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 128
                    },
                    {
                        "name": "彩票、奖券纠纷",
                        "children": [],
                        "level": 3,
                        "index": 129
                    },
                    {
                        "name": "中外合作勘探开发自然资源合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 130
                    },
                    {
                        "name": "农业承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 131
                    },
                    {
                        "name": "林业承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 132
                    },
                    {
                        "name": "渔业承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 133
                    },
                    {
                        "name": "牧业承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 134
                    },
                    {
                        "name": "土地承包经营权合同纠纷",
                        "children": [
                            {
                                "name": "土地承包经营权转让合同纠纷",
                                "level": 4,
                                "index": "135.1"
                            },
                            {
                                "name": "土地承包经营权互换合同纠纷",
                                "level": 4,
                                "index": "135.2"
                            },
                            {
                                "name": "土地经营权入股合同纠纷",
                                "level": 4,
                                "index": "135.3"
                            },
                            {
                                "name": "土地经营权抵押合同纠纷",
                                "level": 4,
                                "index": "135.4"
                            },
                            {
                                "name": "土地经营权出租合同纠纷",
                                "level": 4,
                                "index": "135.5"
                            }
                        ],
                        "level": 3,
                        "index": 135
                    },
                    {
                        "name": "居住权合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 136
                    },
                    {
                        "name": "服务合同纠纷",
                        "children": [
                            {
                                "name": "电信服务合同纠纷",
                                "level": 4,
                                "index": "137.1"
                            },
                            {
                                "name": "邮政服务合同纠纷",
                                "level": 4,
                                "index": "137.2"
                            },
                            {
                                "name": "快递服务合同纠纷",
                                "level": 4,
                                "index": "137.3"
                            },
                            {
                                "name": "医疗服务合同纠纷",
                                "level": 4,
                                "index": "137.4"
                            },
                            {
                                "name": "法律服务合同纠纷",
                                "level": 4,
                                "index": "137.5"
                            },
                            {
                                "name": "旅游合同纠纷",
                                "level": 4,
                                "index": "137.6"
                            },
                            {
                                "name": "房地产咨询合同纠纷",
                                "level": 4,
                                "index": "137.7"
                            },
                            {
                                "name": "房地产价格评估合同纠纷",
                                "level": 4,
                                "index": "137.8"
                            },
                            {
                                "name": "旅店服务合同纠纷",
                                "level": 4,
                                "index": "137.9"
                            },
                            {
                                "name": "财会服务合同纠纷",
                                "level": 4,
                                "index": "137.10"
                            },
                            {
                                "name": "餐饮服务合同纠纷",
                                "level": 4,
                                "index": "137.11"
                            },
                            {
                                "name": "娱乐服务合同纠纷",
                                "level": 4,
                                "index": "137.12"
                            },
                            {
                                "name": "有线电视服务合同纠纷",
                                "level": 4,
                                "index": "137.13"
                            },
                            {
                                "name": "网络服务合同纠纷",
                                "level": 4,
                                "index": "137.14"
                            },
                            {
                                "name": "教育培训合同纠纷",
                                "level": 4,
                                "index": "137.15"
                            },
                            {
                                "name": "家政服务合同纠纷",
                                "level": 4,
                                "index": "137.16"
                            },
                            {
                                "name": "庆典服务合同纠纷",
                                "level": 4,
                                "index": "137.17"
                            },
                            {
                                "name": "殡葬服务合同纠纷",
                                "level": 4,
                                "index": "137.18"
                            },
                            {
                                "name": "农业技术服务合同纠纷",
                                "level": 4,
                                "index": "137.19"
                            },
                            {
                                "name": "农机作业服务合同纠纷",
                                "level": 4,
                                "index": "137.20"
                            },
                            {
                                "name": "保安服务合同纠纷",
                                "level": 4,
                                "index": "137.21"
                            },
                            {
                                "name": "银行结算合同纠纷",
                                "level": 4,
                                "index": "137.22"
                            }
                        ],
                        "level": 3,
                        "index": 137
                    },
                    {
                        "name": "演出合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 138
                    },
                    {
                        "name": "劳务合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 139
                    },
                    {
                        "name": "离退休人员返聘合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 140
                    },
                    {
                        "name": "广告合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 141
                    },
                    {
                        "name": "展览合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 142
                    },
                    {
                        "name": "追偿权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 143
                    }
                ],
                "level": 2,
                "index": 10
            },
            {
                "name": "不当得利纠纷",
                "children": [
                    {
                        "name": "不当得利纠纷",
                        "children": [],
                        "level": 3,
                        "index": 144
                    }
                ],
                "level": 2,
                "index": 11
            },
            {
                "name": "无因管理纠纷",
                "children": [
                    {
                        "name": "无因管理纠纷",
                        "children": [],
                        "level": 3,
                        "index": 145
                    }
                ],
                "level": 2,
                "index": 12
            }
        ],
        "level": 1,
        "index": 4
    },
    {
        "name": " 知识产权与竞争纠纷",
        "children": [
            {
                "name": "知识产权合同纠纷",
                "children": [
                    {
                        "name": "著作权合同纠纷",
                        "children": [
                            {
                                "name": "委托创作合同纠纷",
                                "level": 4,
                                "index": "146.1"
                            },
                            {
                                "name": "合作创作合同纠纷",
                                "level": 4,
                                "index": "146.2"
                            },
                            {
                                "name": "著作权转让合同纠纷",
                                "level": 4,
                                "index": "146.3"
                            },
                            {
                                "name": "著作权许可使用合同纠纷",
                                "level": 4,
                                "index": "146.4"
                            },
                            {
                                "name": "出版合同纠纷",
                                "level": 4,
                                "index": "146.5"
                            },
                            {
                                "name": "表演合同纠纷",
                                "level": 4,
                                "index": "146.6"
                            },
                            {
                                "name": "音像制品制作合同纠纷",
                                "level": 4,
                                "index": "146.7"
                            },
                            {
                                "name": "广播电视播放合同纠纷",
                                "level": 4,
                                "index": "146.8"
                            },
                            {
                                "name": "邻接权转让合同纠纷",
                                "level": 4,
                                "index": "146.9"
                            },
                            {
                                "name": "邻接权许可使用合同纠纷",
                                "level": 4,
                                "index": "146.10"
                            },
                            {
                                "name": "计算机软件开发合同纠纷",
                                "level": 4,
                                "index": "146.11"
                            },
                            {
                                "name": "计算机软件著作权转让合同纠纷",
                                "level": 4,
                                "index": "146.12"
                            },
                            {
                                "name": "计算机软件著作权许可使用合同纠纷",
                                "level": 4,
                                "index": "146.13"
                            }
                        ],
                        "level": 3,
                        "index": 146
                    },
                    {
                        "name": "商标合同纠纷",
                        "children": [
                            {
                                "name": "商标权转让合同纠纷",
                                "level": 4,
                                "index": "147.1"
                            },
                            {
                                "name": "商标使用许可合同纠纷",
                                "level": 4,
                                "index": "147.2"
                            },
                            {
                                "name": "商标代理合同纠纷",
                                "level": 4,
                                "index": "147.3"
                            }
                        ],
                        "level": 3,
                        "index": 147
                    },
                    {
                        "name": "专利合同纠纷",
                        "children": [
                            {
                                "name": "专利申请权转让合同纠纷",
                                "level": 4,
                                "index": "148.1"
                            },
                            {
                                "name": "专利权转让合同纠纷",
                                "level": 4,
                                "index": "148.2"
                            },
                            {
                                "name": "发明专利实施许可合同纠纷",
                                "level": 4,
                                "index": "148.3"
                            },
                            {
                                "name": "实用新型专利实施许可合同纠纷",
                                "level": 4,
                                "index": "148.4"
                            },
                            {
                                "name": "外观设计专利实施许可合同纠纷",
                                "level": 4,
                                "index": "148.5"
                            },
                            {
                                "name": "专利代理合同纠纷",
                                "level": 4,
                                "index": "148.6"
                            }
                        ],
                        "level": 3,
                        "index": 148
                    },
                    {
                        "name": "植物新品种合同纠纷",
                        "children": [
                            {
                                "name": "植物新品种育种合同纠纷",
                                "level": 4,
                                "index": "149.1"
                            },
                            {
                                "name": "植物新品种申请权转让合同纠纷",
                                "level": 4,
                                "index": "149.2"
                            },
                            {
                                "name": "植物新品种权转让合同纠纷",
                                "level": 4,
                                "index": "149.3"
                            },
                            {
                                "name": "植物新品种实施许可合同纠纷",
                                "level": 4,
                                "index": "149.4"
                            }
                        ],
                        "level": 3,
                        "index": 149
                    },
                    {
                        "name": "集成电路布图设计合同纠纷",
                        "children": [
                            {
                                "name": "集成电路布图设计创作合同纠纷",
                                "level": 4,
                                "index": "150.1"
                            },
                            {
                                "name": "集成电路布图设计专有权转让合同纠纷",
                                "level": 4,
                                "index": "150.2"
                            },
                            {
                                "name": "集成电路布图设计许可使用合同纠纷",
                                "level": 4,
                                "index": "150.3"
                            }
                        ],
                        "level": 3,
                        "index": 150
                    },
                    {
                        "name": "商业秘密合同纠纷",
                        "children": [
                            {
                                "name": "技术秘密让与合同纠纷",
                                "level": 4,
                                "index": "151.1"
                            },
                            {
                                "name": "技术秘密许可使用合同纠纷",
                                "level": 4,
                                "index": "151.2"
                            },
                            {
                                "name": "经营秘密让与合同纠纷",
                                "level": 4,
                                "index": "151.3"
                            },
                            {
                                "name": "经营秘密许可使用合同纠纷",
                                "level": 4,
                                "index": "151.4"
                            }
                        ],
                        "level": 3,
                        "index": 151
                    },
                    {
                        "name": "技术合同纠纷",
                        "children": [
                            {
                                "name": "技术委托开发合同纠纷",
                                "level": 4,
                                "index": "152.1"
                            },
                            {
                                "name": "技术合作开发合同纠纷",
                                "level": 4,
                                "index": "152.2"
                            },
                            {
                                "name": "技术转化合同纠纷",
                                "level": 4,
                                "index": "152.3"
                            },
                            {
                                "name": "技术转让合同纠纷",
                                "level": 4,
                                "index": "152.4"
                            },
                            {
                                "name": "技术许可合同纠纷",
                                "level": 4,
                                "index": "152.5"
                            },
                            {
                                "name": "技术咨询合同纠纷",
                                "level": 4,
                                "index": "152.6"
                            },
                            {
                                "name": "技术服务合同纠纷",
                                "level": 4,
                                "index": "152.7"
                            },
                            {
                                "name": "技术培训合同纠纷",
                                "level": 4,
                                "index": "152.8"
                            },
                            {
                                "name": "技术中介合同纠纷",
                                "level": 4,
                                "index": "152.9"
                            },
                            {
                                "name": "技术进口合同纠纷",
                                "level": 4,
                                "index": "152.10"
                            },
                            {
                                "name": "技术出口合同纠纷",
                                "level": 4,
                                "index": "152.11"
                            },
                            {
                                "name": "职务技术成果完成人奖励",
                                "level": 4,
                                "index": "152.12"
                            },
                            {
                                "name": "技术成果完成人署名权",
                                "level": 4,
                                "index": "152.13"
                            }
                        ],
                        "level": 3,
                        "index": 152
                    },
                    {
                        "name": "特许经营合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 153
                    },
                    {
                        "name": "企业名称（商号）合同纠纷",
                        "children": [
                            {
                                "name": "企业名称（商号）转让合同纠纷",
                                "level": 4,
                                "index": "154.1"
                            },
                            {
                                "name": "企业名称（商号）使用合同纠纷",
                                "level": 4,
                                "index": "154.2"
                            }
                        ],
                        "level": 3,
                        "index": 154
                    },
                    {
                        "name": "特殊标志合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 155
                    },
                    {
                        "name": "网络域名合同纠纷",
                        "children": [
                            {
                                "name": "网络域名注册合同纠纷",
                                "level": 4,
                                "index": "156.1"
                            },
                            {
                                "name": "网络域名转让合同纠纷",
                                "level": 4,
                                "index": "156.2"
                            },
                            {
                                "name": "网络域名许可使用合同纠纷",
                                "level": 4,
                                "index": "156.3"
                            }
                        ],
                        "level": 3,
                        "index": 156
                    },
                    {
                        "name": "知识产权质押合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 157
                    }
                ],
                "level": 2,
                "index": 13
            },
            {
                "name": "知识产权权属",
                "children": [
                    {
                        "name": "著作权权属、侵权纠纷",
                        "children": [
                            {
                                "name": "著作权权属纠纷",
                                "level": 4,
                                "index": "158.1"
                            },
                            {
                                "name": "侵害作品发表权纠纷",
                                "level": 4,
                                "index": "158.2"
                            },
                            {
                                "name": "侵害作品署名权纠纷",
                                "level": 4,
                                "index": "158.3"
                            },
                            {
                                "name": "侵害作品修改权纠纷",
                                "level": 4,
                                "index": "158.4"
                            },
                            {
                                "name": "侵害保护作品完整权纠纷",
                                "level": 4,
                                "index": "158.5"
                            },
                            {
                                "name": "侵害作品复制权纠纷",
                                "level": 4,
                                "index": "158.6"
                            },
                            {
                                "name": "侵害作品发行权纠纷",
                                "level": 4,
                                "index": "158.7"
                            },
                            {
                                "name": "侵害作品出租权纠纷",
                                "level": 4,
                                "index": "158.8"
                            },
                            {
                                "name": "侵害作品展览权纠纷",
                                "level": 4,
                                "index": "158.9"
                            },
                            {
                                "name": "侵害作品表演权纠纷",
                                "level": 4,
                                "index": "158.10"
                            },
                            {
                                "name": "侵害作品放映权纠纷",
                                "level": 4,
                                "index": "158.11"
                            },
                            {
                                "name": "侵害作品广播权纠纷",
                                "level": 4,
                                "index": "158.12"
                            },
                            {
                                "name": "侵害作品信息网络传播权纠纷",
                                "level": 4,
                                "index": "158.13"
                            },
                            {
                                "name": "侵害作品摄制权纠纷",
                                "level": 4,
                                "index": "158.14"
                            },
                            {
                                "name": "侵害作品改编权纠纷",
                                "level": 4,
                                "index": "158.15"
                            },
                            {
                                "name": "侵害作品翻译权纠纷",
                                "level": 4,
                                "index": "158.16"
                            },
                            {
                                "name": "侵害作品汇编权纠纷",
                                "level": 4,
                                "index": "158.17"
                            },
                            {
                                "name": "侵害其他著作财产权纠纷",
                                "level": 4,
                                "index": "158.18"
                            },
                            {
                                "name": "出版者权权属纠纷",
                                "level": 4,
                                "index": "158.19"
                            },
                            {
                                "name": "表演者权权属纠纷",
                                "level": 4,
                                "index": "158.20"
                            },
                            {
                                "name": "录音录像制作者权权属纠纷",
                                "level": 4,
                                "index": "158.21"
                            },
                            {
                                "name": "广播组织权权属纠纷",
                                "level": 4,
                                "index": "158.22"
                            },
                            {
                                "name": "侵害出版者权纠纷",
                                "level": 4,
                                "index": "158.23"
                            },
                            {
                                "name": "侵害表演者权纠纷",
                                "level": 4,
                                "index": "158.24"
                            },
                            {
                                "name": "侵害录音录像制作者权纠纷",
                                "level": 4,
                                "index": "158.25"
                            },
                            {
                                "name": "侵害广播组织权纠纷",
                                "level": 4,
                                "index": "158.26"
                            },
                            {
                                "name": "计算机软件著作权权属纠纷",
                                "level": 4,
                                "index": "158.27"
                            },
                            {
                                "name": "侵害计算机软件著作权纠纷",
                                "level": 4,
                                "index": "158.28"
                            }
                        ],
                        "level": 3,
                        "index": 158
                    },
                    {
                        "name": "商标权权属、侵权纠纷",
                        "children": [
                            {
                                "name": "商标权权属纠纷",
                                "level": 4,
                                "index": "159.1"
                            },
                            {
                                "name": "侵害商标权纠纷",
                                "level": 4,
                                "index": "159.2"
                            }
                        ],
                        "level": 3,
                        "index": 159
                    },
                    {
                        "name": "专利权权属、侵权纠纷",
                        "children": [
                            {
                                "name": "专利申请权权属纠纷",
                                "level": 4,
                                "index": "160.1"
                            },
                            {
                                "name": "专利权权属纠纷",
                                "level": 4,
                                "index": "160.2"
                            },
                            {
                                "name": "侵害发明专利权纠纷",
                                "level": 4,
                                "index": "160.3"
                            },
                            {
                                "name": "侵害实用新型专利权纠纷",
                                "level": 4,
                                "index": "160.4"
                            },
                            {
                                "name": "侵害外观设计专利权纠纷",
                                "level": 4,
                                "index": "160.5"
                            },
                            {
                                "name": "假冒他人专利纠纷",
                                "level": 4,
                                "index": "160.6"
                            },
                            {
                                "name": "发明专利临时保护期使用费纠纷",
                                "level": 4,
                                "index": "160.7"
                            },
                            {
                                "name": "职务发明创造发明人",
                                "level": 4,
                                "index": "160.8"
                            },
                            {
                                "name": "发明创造发明人",
                                "level": 4,
                                "index": "160.9"
                            },
                            {
                                "name": "标准必要专利使用费纠纷",
                                "level": 4,
                                "index": "160.10"
                            }
                        ],
                        "level": 3,
                        "index": 160
                    },
                    {
                        "name": "植物新品种权权属、侵权纠纷",
                        "children": [
                            {
                                "name": "植物新品种申请权权属纠纷",
                                "level": 4,
                                "index": "161.1"
                            },
                            {
                                "name": "植物新品种权权属纠纷",
                                "level": 4,
                                "index": "161.2"
                            },
                            {
                                "name": "侵害植物新品种权纠纷",
                                "level": 4,
                                "index": "161.3"
                            },
                            {
                                "name": "植物新品种临时保护期使用费纠纷",
                                "level": 4,
                                "index": "161.4"
                            }
                        ],
                        "level": 3,
                        "index": 161
                    },
                    {
                        "name": "集成电路布图设计专有权权属、侵权纠纷",
                        "children": [
                            {
                                "name": "集成电路布图设计专有权权属纠纷",
                                "level": 4,
                                "index": "162.1"
                            },
                            {
                                "name": "侵害集成电路布图设计专有权纠纷",
                                "level": 4,
                                "index": "162.2"
                            }
                        ],
                        "level": 3,
                        "index": 162
                    },
                    {
                        "name": "侵害企业名称（商号）权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 163
                    },
                    {
                        "name": "侵害特殊标志专有权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 164
                    },
                    {
                        "name": "网络域名权属、侵权纠纷",
                        "children": [
                            {
                                "name": "网络域名权属纠纷",
                                "level": 4,
                                "index": "165.1"
                            },
                            {
                                "name": "侵害网络域名纠纷",
                                "level": 4,
                                "index": "165.2"
                            }
                        ],
                        "level": 3,
                        "index": 165
                    },
                    {
                        "name": "发现权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 166
                    },
                    {
                        "name": "发明权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 167
                    },
                    {
                        "name": "其他科技成果权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 168
                    },
                    {
                        "name": "确认不侵害知识产权纠纷",
                        "children": [
                            {
                                "name": "确认不侵害专利权纠纷",
                                "level": 4,
                                "index": "169.1"
                            },
                            {
                                "name": "确认不侵害商标权纠纷",
                                "level": 4,
                                "index": "169.2"
                            },
                            {
                                "name": "确认不侵害著作权纠纷",
                                "level": 4,
                                "index": "169.3"
                            },
                            {
                                "name": "确认不侵害植物新品种权纠纷",
                                "level": 4,
                                "index": "169.4"
                            },
                            {
                                "name": "确认不侵害集成电路布图设计专用权纠纷",
                                "level": 4,
                                "index": "169.5"
                            },
                            {
                                "name": "确认不侵害计算机软件著作权纠纷",
                                "level": 4,
                                "index": "169.6"
                            }
                        ],
                        "level": 3,
                        "index": 169
                    },
                    {
                        "name": "因申请知识产权临时措施损害责任纠纷",
                        "children": [
                            {
                                "name": "因申请诉前停止侵害专利权损害责任纠纷",
                                "level": 4,
                                "index": "170.1"
                            },
                            {
                                "name": "因申请诉前停止侵害注册商标专用权损害责任纠纷",
                                "level": 4,
                                "index": "170.2"
                            },
                            {
                                "name": "因申请诉前停止侵害著作权损害责任纠纷",
                                "level": 4,
                                "index": "170.3"
                            },
                            {
                                "name": "因申请诉前停止侵害植物新品种权损害责任纠纷",
                                "level": 4,
                                "index": "170.4"
                            },
                            {
                                "name": "因申请海关知识产权保护措施损害责任纠纷",
                                "level": 4,
                                "index": "170.5"
                            },
                            {
                                "name": "因申请诉前停止侵害计算机软件著作权损害责任纠纷",
                                "level": 4,
                                "index": "170.6"
                            },
                            {
                                "name": "因申请诉前停止侵害集成电路布图设计专用权损害责任纠纷",
                                "level": 4,
                                "index": "170.7"
                            }
                        ],
                        "level": 3,
                        "index": 170
                    },
                    {
                        "name": "因恶意提起知识产权诉讼损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 171
                    },
                    {
                        "name": "专利权宣告无效后返还费用纠纷",
                        "children": [],
                        "level": 3,
                        "index": 172
                    }
                ],
                "level": 2,
                "index": 14
            },
            {
                "name": "不正当竞争纠纷",
                "children": [
                    {
                        "name": "仿冒纠纷",
                        "children": [
                            {
                                "name": "擅自使用与他人有一定影响的商品名称",
                                "level": 4,
                                "index": "173.1"
                            },
                            {
                                "name": "擅自使用他人有一定影响的企业名称",
                                "level": 4,
                                "index": "173.2"
                            },
                            {
                                "name": "擅自使用他人有一定影响的域名主体部分",
                                "level": 4,
                                "index": "173.3"
                            }
                        ],
                        "level": 3,
                        "index": 173
                    },
                    {
                        "name": "商业贿赂不正当竞争纠纷",
                        "children": [],
                        "level": 3,
                        "index": 174
                    },
                    {
                        "name": "虚假宣传纠纷",
                        "children": [],
                        "level": 3,
                        "index": 175
                    },
                    {
                        "name": "侵害商业秘密纠纷",
                        "children": [
                            {
                                "name": "侵害技术秘密纠纷",
                                "level": 4,
                                "index": "176.1"
                            },
                            {
                                "name": "侵害经营秘密纠纷",
                                "level": 4,
                                "index": "176.2"
                            }
                        ],
                        "level": 3,
                        "index": 176
                    },
                    {
                        "name": "低价倾销不正当竞争纠纷",
                        "children": [],
                        "level": 3,
                        "index": 177
                    },
                    {
                        "name": "捆绑销售不正当竞争纠纷",
                        "children": [],
                        "level": 3,
                        "index": 178
                    },
                    {
                        "name": "有奖销售纠纷",
                        "children": [],
                        "level": 3,
                        "index": 179
                    },
                    {
                        "name": "商业诋毁纠纷",
                        "children": [],
                        "level": 3,
                        "index": 180
                    },
                    {
                        "name": "串通投标不正当竞争纠纷",
                        "children": [],
                        "level": 3,
                        "index": 181
                    },
                    {
                        "name": "网络不正当竞争纠纷",
                        "children": [],
                        "level": 3,
                        "index": 182
                    }
                ],
                "level": 2,
                "index": 15
            },
            {
                "name": "垄断纠纷",
                "children": [
                    {
                        "name": "垄断协议纠纷",
                        "children": [
                            {
                                "name": "横向垄断协议纠纷",
                                "level": 4,
                                "index": "183.1"
                            },
                            {
                                "name": "纵向垄断协议纠纷",
                                "level": 4,
                                "index": "183.2"
                            }
                        ],
                        "level": 3,
                        "index": 183
                    },
                    {
                        "name": "滥用市场支配地位纠纷",
                        "children": [
                            {
                                "name": "垄断定价纠纷",
                                "level": 4,
                                "index": "184.1"
                            },
                            {
                                "name": "掠夺定价纠纷",
                                "level": 4,
                                "index": "184.2"
                            },
                            {
                                "name": "拒绝交易纠纷",
                                "level": 4,
                                "index": "184.3"
                            },
                            {
                                "name": "限定交易纠纷",
                                "level": 4,
                                "index": "184.4"
                            },
                            {
                                "name": "捆绑交易纠纷",
                                "level": 4,
                                "index": "184.5"
                            },
                            {
                                "name": "差别待遇纠纷",
                                "level": 4,
                                "index": "184.6"
                            }
                        ],
                        "level": 3,
                        "index": 184
                    },
                    {
                        "name": "经营者集中纠纷",
                        "children": [],
                        "level": 3,
                        "index": 185
                    }
                ],
                "level": 2,
                "index": 16
            }
        ],
        "level": 1,
        "index": 5
    },
    {
        "name": " 劳动争议、人事争议",
        "children": [
            {
                "name": "劳动争议",
                "children": [
                    {
                        "name": "劳动合同纠纷",
                        "children": [
                            {
                                "name": "确认劳动关系纠纷",
                                "level": 4,
                                "index": "186.1"
                            },
                            {
                                "name": "集体合同纠纷",
                                "level": 4,
                                "index": "186.2"
                            },
                            {
                                "name": "劳务派遣合同纠纷",
                                "level": 4,
                                "index": "186.3"
                            },
                            {
                                "name": "非全日制用工纠纷",
                                "level": 4,
                                "index": "186.4"
                            },
                            {
                                "name": "追索劳动报酬纠纷",
                                "level": 4,
                                "index": "186.5"
                            },
                            {
                                "name": "经济补偿金纠纷",
                                "level": 4,
                                "index": "186.6"
                            },
                            {
                                "name": "竞业限制纠纷",
                                "level": 4,
                                "index": "186.7"
                            }
                        ],
                        "level": 3,
                        "index": 186
                    },
                    {
                        "name": "社会保险纠纷",
                        "children": [
                            {
                                "name": "养老保险待遇纠纷",
                                "level": 4,
                                "index": "187.1"
                            },
                            {
                                "name": "工伤保险待遇纠纷",
                                "level": 4,
                                "index": "187.2"
                            },
                            {
                                "name": "医疗保险待遇纠纷",
                                "level": 4,
                                "index": "187.3"
                            },
                            {
                                "name": "生育保险待遇纠纷",
                                "level": 4,
                                "index": "187.4"
                            },
                            {
                                "name": "失业保险待遇纠纷",
                                "level": 4,
                                "index": "187.5"
                            }
                        ],
                        "level": 3,
                        "index": 187
                    },
                    {
                        "name": "福利待遇纠纷",
                        "children": [],
                        "level": 3,
                        "index": 188
                    }
                ],
                "level": 2,
                "index": 17
            },
            {
                "name": "人事争议",
                "children": [
                    {
                        "name": "聘用合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 189
                    },
                    {
                        "name": "聘任合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 190
                    },
                    {
                        "name": "辞职纠纷",
                        "children": [],
                        "level": 3,
                        "index": 191
                    },
                    {
                        "name": "辞退纠纷",
                        "children": [],
                        "level": 3,
                        "index": 192
                    }
                ],
                "level": 2,
                "index": 18
            }
        ],
        "level": 1,
        "index": 6
    },
    {
        "name": "海事海商纠纷",
        "children": [
            {
                "name": "海事海商纠纷",
                "children": [
                    {
                        "name": "船舶碰撞损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 193
                    },
                    {
                        "name": "船舶触碰损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 194
                    },
                    {
                        "name": "船舶损坏空中设施、水下设施损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 195
                    },
                    {
                        "name": "船舶污染损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 196
                    },
                    {
                        "name": "海上、通海水域污染损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 197
                    },
                    {
                        "name": "海上、通海水域养殖损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 198
                    },
                    {
                        "name": "海上、通海水域财产损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 199
                    },
                    {
                        "name": "海上、通海水域人身损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 200
                    },
                    {
                        "name": "非法留置船舶、船载货物、船用燃油、船用物料损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 201
                    },
                    {
                        "name": "海上、通海水域货物运输合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 202
                    },
                    {
                        "name": "海上、通海水域旅客运输合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 203
                    },
                    {
                        "name": "海上、通海水域行李运输合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 204
                    },
                    {
                        "name": "船舶经营管理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 205
                    },
                    {
                        "name": "船舶买卖合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 206
                    },
                    {
                        "name": "船舶建造合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 207
                    },
                    {
                        "name": "船舶修理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 208
                    },
                    {
                        "name": "船舶改建合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 209
                    },
                    {
                        "name": "船舶拆解合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 210
                    },
                    {
                        "name": "船舶抵押合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 211
                    },
                    {
                        "name": "航次租船合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 212
                    },
                    {
                        "name": "船舶租用合同纠纷",
                        "children": [
                            {
                                "name": "定期租船合同纠纷",
                                "level": 4,
                                "index": "213.1"
                            },
                            {
                                "name": "光船租赁合同纠纷",
                                "level": 4,
                                "index": "213.2"
                            }
                        ],
                        "level": 3,
                        "index": 213
                    },
                    {
                        "name": "船舶融资租赁合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 214
                    },
                    {
                        "name": "海上、通海水域运输船舶承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 215
                    },
                    {
                        "name": "渔船承包合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 216
                    },
                    {
                        "name": "船舶属具租赁合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 217
                    },
                    {
                        "name": "船舶属具保管合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 218
                    },
                    {
                        "name": "海运集装箱租赁合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 219
                    },
                    {
                        "name": "海运集装箱保管合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 220
                    },
                    {
                        "name": "港口货物保管合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 221
                    },
                    {
                        "name": "船舶代理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 222
                    },
                    {
                        "name": "海上、通海水域货运代理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 223
                    },
                    {
                        "name": "理货合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 224
                    },
                    {
                        "name": "船舶物料和备品供应合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 225
                    },
                    {
                        "name": "船员劳务合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 226
                    },
                    {
                        "name": "海难救助合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 227
                    },
                    {
                        "name": "海上、通海水域打捞合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 228
                    },
                    {
                        "name": "海上、通海水域拖航合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 229
                    },
                    {
                        "name": "海上、通海水域保险合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 230
                    },
                    {
                        "name": "海上、通海水域保赔合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 231
                    },
                    {
                        "name": "海上、通海水域运输联营合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 232
                    },
                    {
                        "name": "船舶营运借款合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 233
                    },
                    {
                        "name": "海事担保合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 234
                    },
                    {
                        "name": "航道、港口疏浚合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 235
                    },
                    {
                        "name": "船坞、码头建造合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 236
                    },
                    {
                        "name": "船舶检验合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 237
                    },
                    {
                        "name": "海事请求担保纠纷",
                        "children": [],
                        "level": 3,
                        "index": 238
                    },
                    {
                        "name": "海上、通海水域运输重大责任事故责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 239
                    },
                    {
                        "name": "港口作业重大责任事故责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 240
                    },
                    {
                        "name": "港口作业纠纷",
                        "children": [],
                        "level": 3,
                        "index": 241
                    },
                    {
                        "name": "共同海损纠纷",
                        "children": [],
                        "level": 3,
                        "index": 242
                    },
                    {
                        "name": "海洋开发利用纠纷",
                        "children": [],
                        "level": 3,
                        "index": 243
                    },
                    {
                        "name": "船舶共有纠纷",
                        "children": [],
                        "level": 3,
                        "index": 244
                    },
                    {
                        "name": "船舶权属纠纷",
                        "children": [],
                        "level": 3,
                        "index": 245
                    },
                    {
                        "name": "海运欺诈纠纷",
                        "children": [],
                        "level": 3,
                        "index": 246
                    },
                    {
                        "name": "海事债权确权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 247
                    }
                ],
                "level": 2,
                "index": 19
            }
        ],
        "level": 1,
        "index": 7
    },
    {
        "name": " 与公司、证券等有关的民事纠纷",
        "children": [
            {
                "name": "与企业有关的纠纷",
                "children": [
                    {
                        "name": "企业出资人权益确认纠纷",
                        "children": [],
                        "level": 3,
                        "index": 248
                    },
                    {
                        "name": "侵害企业出资人权益纠纷",
                        "children": [],
                        "level": 3,
                        "index": 249
                    },
                    {
                        "name": "企业公司制改造合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 250
                    },
                    {
                        "name": "企业股份合作制改造合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 251
                    },
                    {
                        "name": "企业债权转股权合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 252
                    },
                    {
                        "name": "企业分立合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 253
                    },
                    {
                        "name": "企业租赁经营合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 254
                    },
                    {
                        "name": "企业出售合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 255
                    },
                    {
                        "name": "挂靠经营合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 256
                    },
                    {
                        "name": "企业兼并合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 257
                    },
                    {
                        "name": "联营合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 258
                    },
                    {
                        "name": "企业承包经营合同纠纷",
                        "children": [
                            {
                                "name": "中外合资经营企业承包经营合同纠纷",
                                "level": 4,
                                "index": "259.1"
                            },
                            {
                                "name": "中外合作经营企业承包经营合同纠纷",
                                "level": 4,
                                "index": "259.2"
                            },
                            {
                                "name": "外商独资企业承包经营合同纠纷",
                                "level": 4,
                                "index": "259.3"
                            },
                            {
                                "name": "乡镇企业承包经营合同纠纷",
                                "level": 4,
                                "index": "259.4"
                            }
                        ],
                        "level": 3,
                        "index": 259
                    },
                    {
                        "name": "中外合资经营企业合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 260
                    },
                    {
                        "name": "中外合作经营企业合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 261
                    }
                ],
                "level": 2,
                "index": 20
            },
            {
                "name": "与公司有关的纠纷",
                "children": [
                    {
                        "name": "股东资格确认纠纷",
                        "children": [],
                        "level": 3,
                        "index": 262
                    },
                    {
                        "name": "股东名册记载纠纷",
                        "children": [],
                        "level": 3,
                        "index": 263
                    },
                    {
                        "name": "请求变更公司登记纠纷",
                        "children": [],
                        "level": 3,
                        "index": 264
                    },
                    {
                        "name": "股东出资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 265
                    },
                    {
                        "name": "新增资本认购纠纷",
                        "children": [],
                        "level": 3,
                        "index": 266
                    },
                    {
                        "name": "股东知情权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 267
                    },
                    {
                        "name": "请求公司收购股份纠纷",
                        "children": [],
                        "level": 3,
                        "index": 268
                    },
                    {
                        "name": "股权转让纠纷",
                        "children": [],
                        "level": 3,
                        "index": 269
                    },
                    {
                        "name": "公司决议纠纷",
                        "children": [
                            {
                                "name": "公司决议效力确认纠纷",
                                "level": 4,
                                "index": "270.1"
                            },
                            {
                                "name": "公司决议撤销纠纷",
                                "level": 4,
                                "index": "270.2"
                            }
                        ],
                        "level": 3,
                        "index": 270
                    },
                    {
                        "name": "公司设立纠纷",
                        "children": [],
                        "level": 3,
                        "index": 271
                    },
                    {
                        "name": "公司证照返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 272
                    },
                    {
                        "name": "发起人责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 273
                    },
                    {
                        "name": "公司盈余分配纠纷",
                        "children": [],
                        "level": 3,
                        "index": 274
                    },
                    {
                        "name": "损害股东利益责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 275
                    },
                    {
                        "name": "损害公司利益责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 276
                    },
                    {
                        "name": "损害公司债权人利益责任纠纷",
                        "children": [
                            {
                                "name": "股东损害公司债权人利益责任纠纷",
                                "level": 4,
                                "index": "277.1"
                            },
                            {
                                "name": "实际控制人损害公司债权人利益责任纠纷",
                                "level": 4,
                                "index": "277.2"
                            }
                        ],
                        "level": 3,
                        "index": 277
                    },
                    {
                        "name": "公司关联交易损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 278
                    },
                    {
                        "name": "公司合并纠纷",
                        "children": [],
                        "level": 3,
                        "index": 279
                    },
                    {
                        "name": "公司分立纠纷",
                        "children": [],
                        "level": 3,
                        "index": 280
                    },
                    {
                        "name": "公司减资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 281
                    },
                    {
                        "name": "公司增资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 282
                    },
                    {
                        "name": "公司解散纠纷",
                        "children": [],
                        "level": 3,
                        "index": 283
                    },
                    {
                        "name": "清算责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 284
                    },
                    {
                        "name": "上市公司收购纠纷",
                        "children": [],
                        "level": 3,
                        "index": 285
                    }
                ],
                "level": 2,
                "index": 21
            },
            {
                "name": "合伙企业纠纷",
                "children": [
                    {
                        "name": "入伙纠纷",
                        "children": [],
                        "level": 3,
                        "index": 286
                    },
                    {
                        "name": "退伙纠纷",
                        "children": [],
                        "level": 3,
                        "index": 287
                    },
                    {
                        "name": "合伙企业财产份额转让纠纷",
                        "children": [],
                        "level": 3,
                        "index": 288
                    }
                ],
                "level": 2,
                "index": 22
            },
            {
                "name": "与破产有关的纠纷",
                "children": [
                    {
                        "name": "请求撤销个别清偿行为纠纷",
                        "children": [],
                        "level": 3,
                        "index": 289
                    },
                    {
                        "name": "请求确认债务人行为无效纠纷",
                        "children": [],
                        "level": 3,
                        "index": 290
                    },
                    {
                        "name": "对外追收债权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 291
                    },
                    {
                        "name": "追收未缴出资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 292
                    },
                    {
                        "name": "追收抽逃出资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 293
                    },
                    {
                        "name": "追收非正常收入纠纷",
                        "children": [],
                        "level": 3,
                        "index": 294
                    },
                    {
                        "name": "破产债权确认纠纷",
                        "children": [
                            {
                                "name": "职工破产债权确认纠纷",
                                "level": 4,
                                "index": "295.1"
                            },
                            {
                                "name": "普通破产债权确认纠纷",
                                "level": 4,
                                "index": "295.2"
                            }
                        ],
                        "level": 3,
                        "index": 295
                    },
                    {
                        "name": "取回权纠纷",
                        "children": [
                            {
                                "name": "一般取回权纠纷",
                                "level": 4,
                                "index": "296.1"
                            },
                            {
                                "name": "出卖人取回权纠纷",
                                "level": 4,
                                "index": "296.2"
                            }
                        ],
                        "level": 3,
                        "index": 296
                    },
                    {
                        "name": "破产抵销权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 297
                    },
                    {
                        "name": "别除权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 298
                    },
                    {
                        "name": "破产撤销权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 299
                    },
                    {
                        "name": "损害债务人利益赔偿纠纷",
                        "children": [],
                        "level": 3,
                        "index": 300
                    },
                    {
                        "name": "管理人责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 301
                    }
                ],
                "level": 2,
                "index": 23
            },
            {
                "name": "证券纠纷",
                "children": [
                    {
                        "name": "证券权利确认纠纷",
                        "children": [
                            {
                                "name": "股票权利确认纠纷",
                                "level": 4,
                                "index": "302.1"
                            },
                            {
                                "name": "公司债券权利确认纠纷",
                                "level": 4,
                                "index": "302.2"
                            },
                            {
                                "name": "国债权利确认纠纷",
                                "level": 4,
                                "index": "302.3"
                            },
                            {
                                "name": "证券投资基金权利确认纠纷",
                                "level": 4,
                                "index": "302.4"
                            }
                        ],
                        "level": 3,
                        "index": 302
                    },
                    {
                        "name": "证券交易合同纠纷",
                        "children": [
                            {
                                "name": "股票交易纠纷",
                                "level": 4,
                                "index": "303.1"
                            },
                            {
                                "name": "公司债券交易纠纷",
                                "level": 4,
                                "index": "303.2"
                            },
                            {
                                "name": "国债交易纠纷",
                                "level": 4,
                                "index": "303.3"
                            },
                            {
                                "name": "证券投资基金交易纠纷",
                                "level": 4,
                                "index": "303.4"
                            }
                        ],
                        "level": 3,
                        "index": 303
                    },
                    {
                        "name": "金融衍生品种交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 304
                    },
                    {
                        "name": "证券承销合同纠纷",
                        "children": [
                            {
                                "name": "证券代销合同纠纷",
                                "level": 4,
                                "index": "305.1"
                            },
                            {
                                "name": "证券包销合同纠纷",
                                "level": 4,
                                "index": "305.2"
                            }
                        ],
                        "level": 3,
                        "index": 305
                    },
                    {
                        "name": "证券投资咨询纠纷",
                        "children": [],
                        "level": 3,
                        "index": 306
                    },
                    {
                        "name": "证券资信评级服务合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 307
                    },
                    {
                        "name": "证券回购合同纠纷",
                        "children": [
                            {
                                "name": "股票回购合同纠纷",
                                "level": 4,
                                "index": "308.1"
                            },
                            {
                                "name": "国债回购合同纠纷",
                                "level": 4,
                                "index": "308.2"
                            },
                            {
                                "name": "公司债券回购合同纠纷",
                                "level": 4,
                                "index": "308.3"
                            },
                            {
                                "name": "证券投资基金回购合同纠纷",
                                "level": 4,
                                "index": "308.4"
                            },
                            {
                                "name": "质押式证券回购纠纷",
                                "level": 4,
                                "index": "308.5"
                            }
                        ],
                        "level": 3,
                        "index": 308
                    },
                    {
                        "name": "证券上市合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 309
                    },
                    {
                        "name": "证券交易代理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 310
                    },
                    {
                        "name": "证券上市保荐合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 311
                    },
                    {
                        "name": "证券发行纠纷",
                        "children": [
                            {
                                "name": "证券认购纠纷",
                                "level": 4,
                                "index": "312.1"
                            },
                            {
                                "name": "证券发行失败纠纷",
                                "level": 4,
                                "index": "312.2"
                            }
                        ],
                        "level": 3,
                        "index": 312
                    },
                    {
                        "name": "证券返还纠纷",
                        "children": [],
                        "level": 3,
                        "index": 313
                    },
                    {
                        "name": "证券欺诈责任纠纷",
                        "children": [
                            {
                                "name": "证券内幕交易责任纠纷",
                                "level": 4,
                                "index": "314.1"
                            },
                            {
                                "name": "操纵证券交易市场责任纠纷",
                                "level": 4,
                                "index": "314.2"
                            },
                            {
                                "name": "证券虚假陈述责任纠纷",
                                "level": 4,
                                "index": "314.3"
                            },
                            {
                                "name": "欺诈客户责任纠纷",
                                "level": 4,
                                "index": "314.4"
                            }
                        ],
                        "level": 3,
                        "index": 314
                    },
                    {
                        "name": "证券托管纠纷",
                        "children": [],
                        "level": 3,
                        "index": 315
                    },
                    {
                        "name": "证券登记、存管、结算纠纷",
                        "children": [],
                        "level": 3,
                        "index": 316
                    },
                    {
                        "name": "融资融券交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 317
                    },
                    {
                        "name": "客户交易结算资金纠纷",
                        "children": [],
                        "level": 3,
                        "index": 318
                    }
                ],
                "level": 2,
                "index": 24
            },
            {
                "name": "期货交易纠纷",
                "children": [
                    {
                        "name": "期货经纪合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 319
                    },
                    {
                        "name": "期货透支交易纠纷",
                        "children": [],
                        "level": 3,
                        "index": 320
                    },
                    {
                        "name": "期货强行平仓纠纷",
                        "children": [],
                        "level": 3,
                        "index": 321
                    },
                    {
                        "name": "期货实物交割纠纷",
                        "children": [],
                        "level": 3,
                        "index": 322
                    },
                    {
                        "name": "期货保证合约纠纷",
                        "children": [],
                        "level": 3,
                        "index": 323
                    },
                    {
                        "name": "期货交易代理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 324
                    },
                    {
                        "name": "侵占期货交易保证金纠纷",
                        "children": [],
                        "level": 3,
                        "index": 325
                    },
                    {
                        "name": "期货欺诈责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 326
                    },
                    {
                        "name": "操纵期货交易市场责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 327
                    },
                    {
                        "name": "期货内幕交易责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 328
                    },
                    {
                        "name": "期货虚假信息责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 329
                    }
                ],
                "level": 2,
                "index": 25
            },
            {
                "name": "信托纠纷",
                "children": [
                    {
                        "name": "民事信托纠纷",
                        "children": [],
                        "level": 3,
                        "index": 330
                    },
                    {
                        "name": "营业信托纠纷",
                        "children": [],
                        "level": 3,
                        "index": 331
                    },
                    {
                        "name": "公益信托纠纷",
                        "children": [],
                        "level": 3,
                        "index": 332
                    }
                ],
                "level": 2,
                "index": 26
            },
            {
                "name": "保险纠纷",
                "children": [
                    {
                        "name": "财产保险合同纠纷",
                        "children": [
                            {
                                "name": "财产损失保险合同纠纷",
                                "level": 4,
                                "index": "333.1"
                            },
                            {
                                "name": "责任保险合同纠纷",
                                "level": 4,
                                "index": "333.2"
                            },
                            {
                                "name": "信用保险合同纠纷",
                                "level": 4,
                                "index": "333.3"
                            },
                            {
                                "name": "保证保险合同纠纷",
                                "level": 4,
                                "index": "333.4"
                            },
                            {
                                "name": "保险人代位求偿权纠纷",
                                "level": 4,
                                "index": "333.5"
                            }
                        ],
                        "level": 3,
                        "index": 333
                    },
                    {
                        "name": "人身保险合同纠纷",
                        "children": [
                            {
                                "name": "人寿保险合同纠纷",
                                "level": 4,
                                "index": "334.1"
                            },
                            {
                                "name": "意外伤害保险合同纠纷",
                                "level": 4,
                                "index": "334.2"
                            },
                            {
                                "name": "健康保险合同纠纷",
                                "level": 4,
                                "index": "334.3"
                            }
                        ],
                        "level": 3,
                        "index": 334
                    },
                    {
                        "name": "再保险合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 335
                    },
                    {
                        "name": "保险经纪合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 336
                    },
                    {
                        "name": "保险代理合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 337
                    },
                    {
                        "name": "进出口信用保险合同纠纷",
                        "children": [],
                        "level": 3,
                        "index": 338
                    },
                    {
                        "name": "保险费纠纷",
                        "children": [],
                        "level": 3,
                        "index": 339
                    }
                ],
                "level": 2,
                "index": 27
            },
            {
                "name": "票据纠纷",
                "children": [
                    {
                        "name": "票据付款请求权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 340
                    },
                    {
                        "name": "票据追索权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 341
                    },
                    {
                        "name": "票据交付请求权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 342
                    },
                    {
                        "name": "票据返还请求权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 343
                    },
                    {
                        "name": "票据损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 344
                    },
                    {
                        "name": "票据利益返还请求权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 345
                    },
                    {
                        "name": "汇票回单签发请求权纠纷",
                        "children": [],
                        "level": 3,
                        "index": 346
                    },
                    {
                        "name": "票据保证纠纷",
                        "children": [],
                        "level": 3,
                        "index": 347
                    },
                    {
                        "name": "确认票据无效纠纷",
                        "children": [],
                        "level": 3,
                        "index": 348
                    },
                    {
                        "name": "票据代理纠纷",
                        "children": [],
                        "level": 3,
                        "index": 349
                    },
                    {
                        "name": "票据回购纠纷",
                        "children": [],
                        "level": 3,
                        "index": 350
                    }
                ],
                "level": 2,
                "index": 28
            },
            {
                "name": "信用证纠纷",
                "children": [
                    {
                        "name": "委托开立信用证纠纷",
                        "children": [],
                        "level": 3,
                        "index": 351
                    },
                    {
                        "name": "信用证开证纠纷",
                        "children": [],
                        "level": 3,
                        "index": 352
                    },
                    {
                        "name": "信用证议付纠纷",
                        "children": [],
                        "level": 3,
                        "index": 353
                    },
                    {
                        "name": "信用证欺诈纠纷",
                        "children": [],
                        "level": 3,
                        "index": 354
                    },
                    {
                        "name": "信用证融资纠纷",
                        "children": [],
                        "level": 3,
                        "index": 355
                    },
                    {
                        "name": "信用证转让纠纷",
                        "children": [],
                        "level": 3,
                        "index": 356
                    }
                ],
                "level": 2,
                "index": 29
            },
            {
                "name": "独立保函纠纷",
                "children": [
                    {
                        "name": "独立保函开立纠纷",
                        "children": [],
                        "level": 3,
                        "index": 357
                    },
                    {
                        "name": "独立保函付款纠纷",
                        "children": [],
                        "level": 3,
                        "index": 358
                    },
                    {
                        "name": "独立保函追偿纠纷",
                        "children": [],
                        "level": 3,
                        "index": 359
                    },
                    {
                        "name": "独立保函欺诈纠纷",
                        "children": [],
                        "level": 3,
                        "index": 360
                    },
                    {
                        "name": "独立保函转让纠纷",
                        "children": [],
                        "level": 3,
                        "index": 361
                    },
                    {
                        "name": "独立保函通知纠纷",
                        "children": [],
                        "level": 3,
                        "index": 362
                    },
                    {
                        "name": "独立保函撤销纠纷",
                        "children": [],
                        "level": 3,
                        "index": 363
                    }
                ],
                "level": 2,
                "index": 30
            }
        ],
        "level": 1,
        "index": 8
    },
    {
        "name": " 侵权责任纠纷",
        "children": [
            {
                "name": "侵权责任纠纷",
                "children": [
                    {
                        "name": "监护人责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 364
                    },
                    {
                        "name": "用人单位责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 365
                    },
                    {
                        "name": "劳务派遣工作人员侵权责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 366
                    },
                    {
                        "name": "提供劳务者致害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 367
                    },
                    {
                        "name": "提供劳务者受害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 368
                    },
                    {
                        "name": "网络侵权责任纠纷",
                        "children": [
                            {
                                "name": "网络侵害虚拟财产纠纷",
                                "level": 4,
                                "index": "369.1"
                            }
                        ],
                        "level": 3,
                        "index": 369
                    },
                    {
                        "name": "违反安全保障义务责任纠纷",
                        "children": [
                            {
                                "name": "经营场所",
                                "level": 4,
                                "index": "370.1"
                            },
                            {
                                "name": "群众性活动组织者责任纠纷",
                                "level": 4,
                                "index": "370.2"
                            }
                        ],
                        "level": 3,
                        "index": 370
                    },
                    {
                        "name": "教育机构责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 371
                    },
                    {
                        "name": "性骚扰损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 372
                    },
                    {
                        "name": "产品责任纠纷",
                        "children": [
                            {
                                "name": "产品生产者责任纠纷",
                                "level": 4,
                                "index": "373.1"
                            },
                            {
                                "name": "产品销售者责任纠纷",
                                "level": 4,
                                "index": "373.2"
                            },
                            {
                                "name": "产品运输者责任纠纷",
                                "level": 4,
                                "index": "373.3"
                            },
                            {
                                "name": "产品仓储者责任纠纷",
                                "level": 4,
                                "index": "373.4"
                            }
                        ],
                        "level": 3,
                        "index": 373
                    },
                    {
                        "name": "机动车交通事故责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 374
                    },
                    {
                        "name": "非机动车交通事故责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 375
                    },
                    {
                        "name": "医疗损害责任纠纷",
                        "children": [
                            {
                                "name": "侵害患者知情同意权责任纠纷",
                                "level": 4,
                                "index": "376.1"
                            },
                            {
                                "name": "医疗产品责任纠纷",
                                "level": 4,
                                "index": "376.2"
                            }
                        ],
                        "level": 3,
                        "index": 376
                    },
                    {
                        "name": "环境污染责任纠纷",
                        "children": [
                            {
                                "name": "大气污染责任纠纷",
                                "level": 4,
                                "index": "377.1"
                            },
                            {
                                "name": "水污染责任纠纷",
                                "level": 4,
                                "index": "377.2"
                            },
                            {
                                "name": "土壤污染责任纠纷",
                                "level": 4,
                                "index": "377.3"
                            },
                            {
                                "name": "电子废物污染责任纠纷",
                                "level": 4,
                                "index": "377.4"
                            },
                            {
                                "name": "固体废物污染责任纠纷",
                                "level": 4,
                                "index": "377.5"
                            },
                            {
                                "name": "噪声污染责任纠纷",
                                "level": 4,
                                "index": "377.6"
                            },
                            {
                                "name": "光污染责任纠纷",
                                "level": 4,
                                "index": "377.7"
                            },
                            {
                                "name": "放射性污染责任纠纷",
                                "level": 4,
                                "index": "377.8"
                            }
                        ],
                        "level": 3,
                        "index": 377
                    },
                    {
                        "name": "生态破坏责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 378
                    },
                    {
                        "name": "高度危险责任纠纷",
                        "children": [
                            {
                                "name": "民用核设施",
                                "level": 4,
                                "index": "379.1"
                            },
                            {
                                "name": "民用航空器损害责任纠纷",
                                "level": 4,
                                "index": "379.2"
                            },
                            {
                                "name": "占有",
                                "level": 4,
                                "index": "379.3"
                            },
                            {
                                "name": "高度危险活动损害责任纠纷",
                                "level": 4,
                                "index": "379.4"
                            },
                            {
                                "name": "遗失",
                                "level": 4,
                                "index": "379.5"
                            },
                            {
                                "name": "非法占有高度危险物损害责任纠纷",
                                "level": 4,
                                "index": "379.6"
                            }
                        ],
                        "level": 3,
                        "index": 379
                    },
                    {
                        "name": "饲养动物损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 380
                    },
                    {
                        "name": "建筑物和物件损害责任纠纷",
                        "children": [
                            {
                                "name": "物件脱落",
                                "level": 4,
                                "index": "381.1"
                            },
                            {
                                "name": "建筑物",
                                "level": 4,
                                "index": "381.2"
                            },
                            {
                                "name": "高空抛物",
                                "level": 4,
                                "index": "381.3"
                            },
                            {
                                "name": "堆放物倒塌",
                                "level": 4,
                                "index": "381.4"
                            },
                            {
                                "name": "公共道路妨碍通行损害责任纠纷",
                                "level": 4,
                                "index": "381.5"
                            },
                            {
                                "name": "林木折断",
                                "level": 4,
                                "index": "381.6"
                            },
                            {
                                "name": "地面施工",
                                "level": 4,
                                "index": "381.7"
                            }
                        ],
                        "level": 3,
                        "index": 381
                    },
                    {
                        "name": "触电人身损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 382
                    },
                    {
                        "name": "义务帮工人受害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 383
                    },
                    {
                        "name": "见义勇为人受害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 384
                    },
                    {
                        "name": "公证损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 385
                    },
                    {
                        "name": "防卫过当损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 386
                    },
                    {
                        "name": "紧急避险损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 387
                    },
                    {
                        "name": "驻香港、澳门特别行政区军人执行职务侵权责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 388
                    },
                    {
                        "name": "铁路运输损害责任纠纷",
                        "children": [
                            {
                                "name": "铁路运输人身损害责任纠纷",
                                "level": 4,
                                "index": "389.1"
                            },
                            {
                                "name": "铁路运输财产损害责任纠纷",
                                "level": 4,
                                "index": "389.2"
                            }
                        ],
                        "level": 3,
                        "index": 389
                    },
                    {
                        "name": "水上运输损害责任纠纷",
                        "children": [
                            {
                                "name": "水上运输人身损害责任纠纷",
                                "level": 4,
                                "index": "390.1"
                            },
                            {
                                "name": "水上运输财产损害责任纠纷",
                                "level": 4,
                                "index": "390.2"
                            }
                        ],
                        "level": 3,
                        "index": 390
                    },
                    {
                        "name": "航空运输损害责任纠纷",
                        "children": [
                            {
                                "name": "航空运输人身损害责任纠纷",
                                "level": 4,
                                "index": "391.1"
                            },
                            {
                                "name": "航空运输财产损害责任纠纷",
                                "level": 4,
                                "index": "391.2"
                            }
                        ],
                        "level": 3,
                        "index": 391
                    },
                    {
                        "name": "因申请财产保全损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 392
                    },
                    {
                        "name": "因申请行为保全损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 393
                    },
                    {
                        "name": "因申请证据保全损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 394
                    },
                    {
                        "name": "因申请先予执行损害责任纠纷",
                        "children": [],
                        "level": 3,
                        "index": 395
                    }
                ],
                "level": 2,
                "index": 31
            }
        ],
        "level": 1,
        "index": 9
    },
    {
        "name": " 非讼程序案件案由",
        "children": [
            {
                "name": "选民资格案件",
                "children": [
                    {
                        "name": "申请确定选民资格",
                        "children": [],
                        "level": 3,
                        "index": 396
                    }
                ],
                "level": 2,
                "index": 32
            },
            {
                "name": "宣告失踪",
                "children": [
                    {
                        "name": "申请宣告自然人失踪",
                        "children": [],
                        "level": 3,
                        "index": 397
                    },
                    {
                        "name": "申请撤销宣告失踪判决",
                        "children": [],
                        "level": 3,
                        "index": 398
                    },
                    {
                        "name": "申请为失踪人财产指定、变更代管人",
                        "children": [],
                        "level": 3,
                        "index": 399
                    },
                    {
                        "name": "申请宣告自然人死亡",
                        "children": [],
                        "level": 3,
                        "index": 400
                    },
                    {
                        "name": "申请撤销宣告自然人死亡判决",
                        "children": [],
                        "level": 3,
                        "index": 401
                    }
                ],
                "level": 2,
                "index": 33
            },
            {
                "name": "认定自然人无民事行为能力",
                "children": [
                    {
                        "name": "申请宣告自然人无民事行为能力",
                        "children": [],
                        "level": 3,
                        "index": 402
                    },
                    {
                        "name": "申请宣告自然人限制民事行为能力",
                        "children": [],
                        "level": 3,
                        "index": 403
                    },
                    {
                        "name": "申请宣告自然人恢复限制民事行为能力",
                        "children": [],
                        "level": 3,
                        "index": 404
                    },
                    {
                        "name": "申请宣告自然人恢复完全民事行为能力",
                        "children": [],
                        "level": 3,
                        "index": 405
                    }
                ],
                "level": 2,
                "index": 34
            },
            {
                "name": "指定遗产管理人案件",
                "children": [
                    {
                        "name": "申请指定遗产管理人",
                        "children": [],
                        "level": 3,
                        "index": 406
                    }
                ],
                "level": 2,
                "index": 35
            },
            {
                "name": "认定财产无主案件",
                "children": [
                    {
                        "name": "申请认定财产无主",
                        "children": [],
                        "level": 3,
                        "index": 407
                    },
                    {
                        "name": "申请撤销认定财产无主判决",
                        "children": [],
                        "level": 3,
                        "index": 408
                    }
                ],
                "level": 2,
                "index": 36
            },
            {
                "name": "确认调解协议案件",
                "children": [
                    {
                        "name": "申请司法确认调解协议",
                        "children": [],
                        "level": 3,
                        "index": 409
                    },
                    {
                        "name": "申请撤销确认调解协议裁定",
                        "children": [],
                        "level": 3,
                        "index": 410
                    }
                ],
                "level": 2,
                "index": 37
            },
            {
                "name": "实现担保物权案件",
                "children": [
                    {
                        "name": "申请实现担保物权",
                        "children": [],
                        "level": 3,
                        "index": 411
                    },
                    {
                        "name": "申请撤销准许实现担保物权裁定",
                        "children": [],
                        "level": 3,
                        "index": 412
                    }
                ],
                "level": 2,
                "index": 38
            },
            {
                "name": "监护权特别程序案件",
                "children": [
                    {
                        "name": "申请确定监护人",
                        "children": [],
                        "level": 3,
                        "index": 413
                    },
                    {
                        "name": "申请指定监护人",
                        "children": [],
                        "level": 3,
                        "index": 414
                    },
                    {
                        "name": "申请变更监护人",
                        "children": [],
                        "level": 3,
                        "index": 415
                    },
                    {
                        "name": "申请撤销监护人资格",
                        "children": [],
                        "level": 3,
                        "index": 416
                    },
                    {
                        "name": "申请恢复监护人资格",
                        "children": [],
                        "level": 3,
                        "index": 417
                    }
                ],
                "level": 2,
                "index": 39
            },
            {
                "name": "督促程序案件",
                "children": [
                    {
                        "name": "申请支付令",
                        "children": [],
                        "level": 3,
                        "index": 418
                    }
                ],
                "level": 2,
                "index": 40
            },
            {
                "name": "公示催告程序案件",
                "children": [
                    {
                        "name": "申请公示催告",
                        "children": [],
                        "level": 3,
                        "index": 419
                    }
                ],
                "level": 2,
                "index": 41
            },
            {
                "name": "公司清算案件",
                "children": [
                    {
                        "name": "申请公司清算",
                        "children": [],
                        "level": 3,
                        "index": 420
                    }
                ],
                "level": 2,
                "index": 42
            },
            {
                "name": "破产程序案件",
                "children": [
                    {
                        "name": "申请破产清算",
                        "children": [],
                        "level": 3,
                        "index": 421
                    },
                    {
                        "name": "申请破产重整",
                        "children": [],
                        "level": 3,
                        "index": 422
                    },
                    {
                        "name": "申请破产和解",
                        "children": [],
                        "level": 3,
                        "index": 423
                    },
                    {
                        "name": "申请对破产财产追加分配",
                        "children": [],
                        "level": 3,
                        "index": 424
                    }
                ],
                "level": 2,
                "index": 43
            },
            {
                "name": "申请诉前停止侵害知识产权案件",
                "children": [
                    {
                        "name": "申请诉前停止侵害专利权",
                        "children": [],
                        "level": 3,
                        "index": 425
                    },
                    {
                        "name": "申请诉前停止侵害注册商标专用权",
                        "children": [],
                        "level": 3,
                        "index": 426
                    },
                    {
                        "name": "申请诉前停止侵害著作权",
                        "children": [],
                        "level": 3,
                        "index": 427
                    },
                    {
                        "name": "申请诉前停止侵害植物新品种权",
                        "children": [],
                        "level": 3,
                        "index": 428
                    },
                    {
                        "name": "申请诉前停止侵害计算机软件著作权",
                        "children": [],
                        "level": 3,
                        "index": 429
                    },
                    {
                        "name": "申请诉前停止侵害集成电路布图设计专用权",
                        "children": [],
                        "level": 3,
                        "index": 430
                    }
                ],
                "level": 2,
                "index": 44
            },
            {
                "name": "申请保全案件",
                "children": [
                    {
                        "name": "申请诉前财产保全",
                        "children": [],
                        "level": 3,
                        "index": 431
                    },
                    {
                        "name": "申请诉前行为保全",
                        "children": [],
                        "level": 3,
                        "index": 432
                    },
                    {
                        "name": "申请诉前证据保全",
                        "children": [],
                        "level": 3,
                        "index": 433
                    },
                    {
                        "name": "申请仲裁前财产保全",
                        "children": [],
                        "level": 3,
                        "index": 434
                    },
                    {
                        "name": "申请仲裁前行为保全",
                        "children": [],
                        "level": 3,
                        "index": 435
                    },
                    {
                        "name": "申请仲裁前证据保全",
                        "children": [],
                        "level": 3,
                        "index": 436
                    },
                    {
                        "name": "仲裁程序中的财产保全",
                        "children": [],
                        "level": 3,
                        "index": 437
                    },
                    {
                        "name": "仲裁程序中的证据保全",
                        "children": [],
                        "level": 3,
                        "index": 438
                    },
                    {
                        "name": "申请执行前财产保全",
                        "children": [],
                        "level": 3,
                        "index": 439
                    },
                    {
                        "name": "申请中止支付信用证项下款项",
                        "children": [],
                        "level": 3,
                        "index": 440
                    },
                    {
                        "name": "申请中止支付保函项下款项",
                        "children": [],
                        "level": 3,
                        "index": 441
                    }
                ],
                "level": 2,
                "index": 45
            },
            {
                "name": "申请人身安全保护令案件",
                "children": [
                    {
                        "name": "申请人身安全保护令",
                        "children": [],
                        "level": 3,
                        "index": 442
                    }
                ],
                "level": 2,
                "index": 46
            },
            {
                "name": "申请人格权侵害禁令案件",
                "children": [
                    {
                        "name": "申请人格权侵害禁令",
                        "children": [],
                        "level": 3,
                        "index": 443
                    }
                ],
                "level": 2,
                "index": 47
            },
            {
                "name": "仲裁程序案件",
                "children": [
                    {
                        "name": "申请确认仲裁协议效力",
                        "children": [],
                        "level": 3,
                        "index": 444
                    },
                    {
                        "name": "申请撤销仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 445
                    }
                ],
                "level": 2,
                "index": 48
            },
            {
                "name": "海事诉讼特别程序案件",
                "children": [
                    {
                        "name": "申请海事请求保全",
                        "children": [
                            {
                                "name": "申请扣押船舶",
                                "level": 4,
                                "index": "446.1"
                            },
                            {
                                "name": "申请拍卖扣押船舶",
                                "level": 4,
                                "index": "446.2"
                            },
                            {
                                "name": "申请扣押船载货物",
                                "level": 4,
                                "index": "446.3"
                            },
                            {
                                "name": "申请拍卖扣押船载货物",
                                "level": 4,
                                "index": "446.4"
                            },
                            {
                                "name": "申请扣押船用燃油及船用物料",
                                "level": 4,
                                "index": "446.5"
                            },
                            {
                                "name": "申请拍卖扣押船用燃油及船用物料",
                                "level": 4,
                                "index": "446.6"
                            }
                        ],
                        "level": 3,
                        "index": 446
                    },
                    {
                        "name": "申请海事支付令",
                        "children": [],
                        "level": 3,
                        "index": 447
                    },
                    {
                        "name": "申请海事强制令",
                        "children": [],
                        "level": 3,
                        "index": 448
                    },
                    {
                        "name": "申请海事证据保全",
                        "children": [],
                        "level": 3,
                        "index": 449
                    },
                    {
                        "name": "申请设立海事赔偿责任限制基金",
                        "children": [],
                        "level": 3,
                        "index": 450
                    },
                    {
                        "name": "申请船舶优先权催告",
                        "children": [],
                        "level": 3,
                        "index": 451
                    },
                    {
                        "name": "申请海事债权登记与受偿",
                        "children": [],
                        "level": 3,
                        "index": 452
                    }
                ],
                "level": 2,
                "index": 49
            },
            {
                "name": "申请承认与执行法院判决",
                "children": [
                    {
                        "name": "申请执行海事仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 453
                    },
                    {
                        "name": "申请执行知识产权仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 454
                    },
                    {
                        "name": "申请执行涉外仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 455
                    },
                    {
                        "name": "申请认可和执行香港特别行政区法院民事判决",
                        "children": [],
                        "level": 3,
                        "index": 456
                    },
                    {
                        "name": "申请认可和执行香港特别行政区仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 457
                    },
                    {
                        "name": "申请认可和执行澳门特别行政区法院民事判决",
                        "children": [],
                        "level": 3,
                        "index": 458
                    },
                    {
                        "name": "申请认可和执行澳门特别行政区仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 459
                    },
                    {
                        "name": "申请认可和执行台湾地区法院民事判决",
                        "children": [],
                        "level": 3,
                        "index": 460
                    },
                    {
                        "name": "申请认可和执行台湾地区仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 461
                    },
                    {
                        "name": "申请承认和执行外国法院民事判决、裁定",
                        "children": [],
                        "level": 3,
                        "index": 462
                    },
                    {
                        "name": "申请承认和执行外国仲裁裁决",
                        "children": [],
                        "level": 3,
                        "index": 463
                    }
                ],
                "level": 2,
                "index": 50
            }
        ],
        "level": 1,
        "index": 10
    },
    {
        "name": " 特殊诉讼程序案件案由",
        "children": [
            {
                "name": "与宣告失踪",
                "children": [
                    {
                        "name": "失踪人债务支付纠纷",
                        "children": [],
                        "level": 3,
                        "index": 464
                    },
                    {
                        "name": "被撤销死亡宣告人请求返还财产纠纷",
                        "children": [],
                        "level": 3,
                        "index": 465
                    }
                ],
                "level": 2,
                "index": 51
            },
            {
                "name": "公益诉讼",
                "children": [
                    {
                        "name": "生态环境保护民事公益诉讼",
                        "children": [
                            {
                                "name": "环境污染民事公益诉讼",
                                "level": 4,
                                "index": "466.1"
                            },
                            {
                                "name": "生态破坏民事公益诉讼",
                                "level": 4,
                                "index": "466.2"
                            },
                            {
                                "name": "生态环境损害赔偿诉讼",
                                "level": 4,
                                "index": "466.3"
                            }
                        ],
                        "level": 3,
                        "index": 466
                    },
                    {
                        "name": "英雄烈士保护民事公益诉讼",
                        "children": [],
                        "level": 3,
                        "index": 467
                    },
                    {
                        "name": "未成年人保护民事公益诉讼",
                        "children": [],
                        "level": 3,
                        "index": 468
                    },
                    {
                        "name": "消费者权益保护民事公益诉讼",
                        "children": [],
                        "level": 3,
                        "index": 469
                    }
                ],
                "level": 2,
                "index": 52
            },
            {
                "name": "第三人撤销之诉",
                "children": [
                    {
                        "name": "第三人撤销之诉",
                        "children": [],
                        "level": 3,
                        "index": 470
                    }
                ],
                "level": 2,
                "index": 53
            },
            {
                "name": "执行程序中的异议之诉",
                "children": [
                    {
                        "name": "执行异议之诉",
                        "children": [
                            {
                                "name": "案外人执行异议之诉",
                                "level": 4,
                                "index": "471.1"
                            },
                            {
                                "name": "申请执行人执行异议之诉",
                                "level": 4,
                                "index": "471.2"
                            }
                        ],
                        "level": 3,
                        "index": 471
                    },
                    {
                        "name": "追加、变更被执行人异议之诉",
                        "children": [],
                        "level": 3,
                        "index": 472
                    },
                    {
                        "name": "执行分配方案异议之诉",
                        "children": [],
                        "level": 3,
                        "index": 473
                    }
                ],
                "level": 2,
                "index": 54
            }
        ],
        "level": 1,
        "index": 11
    }
    ],
  },
  buildTree : function() {
    let tree = [];
    this.Kinds.items.forEach((element) => {
      let node = {};
      this.buildNode(node, element);
      tree.push(node);
    });
    return tree;
  },
  buildNode : function(node, kind) {
    node.id = kind.index;
    node.name = kind.name;
    node.children = [];
    if (kind.children != null && kind.children.length > 0) {
      kind.children.forEach((element) => {
        let child = {};
        this.buildNode(child, element);
        node.children.push(node);
      });
    }
  },
  buildPaint : function() {
    let tree = [];
    this.Kinds.items.forEach((element) => {
      this.buildPaintNode(tree, element);
    });
    return tree;
  },
  buildPaintNode : function(tree, kind) {
    let node = {};
    node.value = kind.index;
    node.text = kind.index + ". " + kind.name;
    if (kind.children != null && kind.children.length > 0) {
      kind.children.forEach((element) => {
        this.buildPaintNode(tree, element);
      });
    } else {
      tree.push(node);
    }
  }
}