<template>
  <v-card class="form-container" ref="account">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="editMode"
        color="primary"
        @click="save('editMode', $refs.mainView)"
      >
        {{ $t("save") }}
      </v-btn>
      <v-btn v-if="!editMode" color="primary" @click="edit('editMode')">
        {{ $t("edit") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <form-view
        :itemSchemas="itemSchemas"
        :readonly="!editMode"
        @item-action="itemAction"
        colPerRow="1"
        ref="mainView"
      ></form-view>
    </v-card-text>
  </v-card>
</template>

<script>
import FormView from "../components/FormView.vue";

export default {
  components: { FormView },
  props: {
    id:{},
  },
  data: () => ({
    title: "SystemSetting",
    entity: {},
    editMode: true,
    itemSchemas: [
      {
        name: "sharePercent",
        value: "sharePercent",
        type: "number",
      },
      {
        name: "invoiceTitle",
        value: "invoiceTitle",
        type: "string",
      },
      {
        name: "invoiceTaxNumber",
        value: "invoiceTaxNumber",
        type: "string",
      },
      {
        name: "invoiceCompanyAddress",
        value: "invoiceCompanyAddress",
        type: "string",
      },
      {
        name: "invoiceTelephone",
        value: "invoiceTelephone",
        type: "string",
      },
      {
        name: "invoiceBankName",
        value: "invoiceBankName",
        type: "string",
      },
      {
        name: "invoiceBankAccount",
        value: "invoiceBankAccount",
        type: "string",
      },
      {
        name: "toName",
        value: "toName",
        type: "string",
      },
      {
        name: "toAddress",
        value: "toAddress",
        type: "string",
      },
      {
        name: "toPhone",
        value: "toPhone",
        type: "string",
      },
      {
        name: "toEmail",
        value: "toEmail",
        type: "string",
      },
    ],
  }),
   mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {},
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      this.get("/api/settings",
        {
          callback: function (data) {
            _this.$refs.mainView.loadEntity(data.data);
          },
        }
      );
    },
    itemAction(schema, value, callback) {
      console.log(schema, value, callback);
      // showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
    save() {
      // let _this = this;
      let entity = this.$refs.mainView.commitEntity();
      let commitEntity = {};
      for (let key in entity) {
        if (entity[key] != undefined || entity[key] != null || entity[key] != '') {
          commitEntity[key] = entity[key];
        }
      }
      let option = {
        callback: function () {
          // _this.$refs.mainView.loadEntity(data.data);
        },
      };
      this.post("/api/setting/", entity, option);
    },
  }
}
</script>

<i18n>
{
  "en": {
    "c_invoiceTitle": "发票信息",
    "c_invoiceTaxNumber": "税号",
    "c_invoiceCompanyAddress": "单位地址",
    "c_invoiceTelephone":"电话",
    "c_invoiceBankName":"开户行",
    "c_invoiceBankAccount":"银行账户",
    "c_toName":"发票收件人",
    "c_toAddress":"发票邮寄地址",
    "c_toPhone":"发票邮寄电话",
    "c_toEmail":"发票电子邮箱"
  },
  "cn": {
    "c_invoiceTitle": "发票信息",
    "c_invoiceTaxNumber": "税号",
    "c_invoiceCompanyAddress": "单位地址",
    "c_invoiceTelephone":"电话",
    "c_invoiceBankName":"开户行",
    "c_invoiceBankAccount":"银行账户",
    "c_toName":"发票收件人",
    "c_toAddress":"发票邮寄地址",
    "c_toPhone":"发票邮寄电话",
    "c_toEmail":"发票电子邮箱"
  }
}
</i18n>
