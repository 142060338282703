<template>
  <entity-view
    :item-schemas="itemSchemas"
    :select="select"
    :items="items"
    :readonly="readonly"
    :title="$t('history')"
    :pagination="pagination"
    :externalActions="externalActions"
    :options="options"
    actionName="operation"
    @items-refresh="itemsRefresh"
    @page-change="pageChange"
  >
  </entity-view>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import OrderStatusDropdowns from '../common/order_status';

export default {
  components: { EntityView },
  props: {
    id: {}
  },
  data: () => ({
    title: "OrderHistory",
    select: false,
    readonly: true,
    options: {
      dateTimePattern: "YYYY-MM-DD HH:mm:ss",
      showAction: false,
    },
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "number",
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
      },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        dropdown: OrderStatusDropdowns.values,
        localized: true,
      },
      {
        name: "modifiedAt",
        value: "modifiedAt",
        type: "date",
      },
    ],
    items: [],
    externalActions: [],
    criterias: []
  }),
  mounted() {
    this.updateSchemaData();
  },
  computed: {},
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh(criterias) {
      let _this = this;
      if (criterias != undefined) {
        this.criterias = criterias;
      }
      let postData = {
        pagination: this.pagination,
        criterias: criterias,
      };
      this.post("/api/order/" + this.id + "/histories", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh(this.criterias);
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name) {
      if (name === "insert") {
        this.itemCreate();
      }
    },
  },
};
</script>

<style scoped>
.main-container {
  margin-left: 384px;
}
.nav-drawer {
  margin-top: 64px;
}
.filter-label {
  margin-left: -12px !important;
}
</style>
