<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn
                color="primary mr-4"
                fab
                small
                @click="$router.go(-1)"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              <v-chip :color="this.entity['enable'] ? 'red': 'green'" class="mr-4 white--text">{{ (this.entity['enable'] ? "在线" : "离线") }}</v-chip>
              <v-chip :color="this.isForbidden ? 'green' : 'red' " class="white--text">{{ this.isForbidden ? "禁止登录" : "允许登录" }}</v-chip>
              <v-spacer></v-spacer>
              <v-btn v-if="entity['forbidden'] != true" class="primary ml-4" @click="forbidden(true)">
                {{ $t("forbidden") }}
              </v-btn>
              <v-btn v-if="entity['forbidden'] == true" class="warning ml-4" @click="forbidden(false)">
                {{ $t("enabled") }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="ma-4" ref="invoice">
          <order-part :lawyerId="id"></order-part>
        </v-row>
        <v-row class="ma-4" ref="order">
          <invoice-part :lawyerId="id"></invoice-part>
        </v-row>

      </div>
    </div>
    <v-dialog v-model="forbiddenDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{isForbidden ? $t("enable_title") : $t("forbidden_title") }}
        </v-card-title>
        <v-card-text>
          {{ isForbidden ? $t("enable_description") : $t("forbidden_description")}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="forbiddenDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              forbiddenDialog = false;
              doForbidden(!isForbidden);
            "
          >
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BreadcrumbEx from '../components/BreadcrumbEx.vue';
import FormView from "../components/FormView.vue";
import InvoicePart from './InvoicePart.vue';
import OrderPart from './OrderPart.vue';
import showDialog from "./show-dialog.js";

export default {
  components: { FormView, OrderPart, BreadcrumbEx, InvoicePart },
  props: {
    id: {},
  },
  data: () => ({
    title: "DraftLawyer",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    colPerRow: 1,
    isCreate: false,
    isForbidden: false,
    forbiddenDialog: false,
    revertDialog: false,
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "invoice",
        text: "invoice",
        tag: "invoice",
      },
      {
        name: "order",
        text: "order",
        tag: "order",
      },
    ],
    itemSchemas: [
      {
        name: "name",
        value: "name",
        type: "string",
        readonly: true,
      },
      {
        name: "imageUrl",
        value: "imageUrl",
        type: "image",
        required: true,
        getURL: function (value) {
          return process.env.VUE_APP_API_URL + value;
        },
      },
      {
        name: "office",
        value: "office",
        type: "string",
        readonly: true,
      },
      {
        name: "licenseNo",
        value: "licenseNo",
        type: "string",
        readonly: true,
      },
      {
        name: "licenseImage",
        value: "licenseImage",
        type: "image",
        readonly: true,
        getURL: function (value) {
          return process.env.VUE_APP_API_URL + value;
        },
      },
      {
        name: "location",
        value: "location",
        type: "string",
        required: true,
      },
      {
        name: "fields",
        value: "fields",
        type: "string",
      },
      {
        name: "level",
        value: "level",
        type: "string",
      },
      {
        name: "graduatedFrom",
        value: "graduatedFrom",
        type: "string",
      },
      {
        name: "major",
        value: "major",
        type: "string",
      },
      {
        name: "degree",
        value: "degree",
        type: "string",
      },
      {
        name: "otherEducation",
        value: "otherEducation",
        type: "text",
      },
      {
        name: "workDaysTotal",
        value: "workDaysTotal",
        type: "string",
      },
      {
        name: "workDaysGjf",
        value: "workDaysGjf",
        type: "string",
      },
      {
        name: "workDaysXz",
        value: "workDaysXz",
        type: "string",
      },
      {
        name: "workDaysKy",
        value: "workDaysKy",
        type: "string",
      },
      {
        name: "workDaysQy",
        value: "workDaysQy",
        type: "string",
      },
      {
        name: "workDaysLawyer",
        value: "workDaysLawyer",
        type: "string",
      },
      {
        name: "workDaysOther",
        value: "workDaysOther",
        type: "string",
      },
      {
        name: "honor",
        value: "honor",
        type: "text",
      },
      {
        name: "cases",
        value: "cases",
        type: "text",
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.refresh();
    this.isCreate = this.id == undefined;
    console.log("Lawyer Id: " + this.id);
  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      if (this.id == undefined) {
        return;
      }
      this.get("/api/lawyer/" + this.id, {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
          _this.isForbidden = data.data["forbidden"];
        },
      });
    },
    forbidden() {
      this.forbiddenDialog = true;
    },
    doForbidden(forbidden) {
      let that = this;
      this.forbiddenDialog = false;
      let option = {
        callback: function () {
          that.refresh();
        },
      };
      this.get(`/api/lawyer/` + this.id + "/forbidden?forbidden=" + forbidden, option);
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {
    "c_firstName": "First Name",
    "c_lastName": "Last Name",
    "c_name": "Contact Name",
    "c_check": "Check",
    "c_phone": "Phone",
    "c_website": "WebSite",
    "c_industry": "Industry",
    "c_owner": "Owner",
    "c_updated": "Updated",
    "c_tax": "Tax Code",
    "c_revenue": "Annual Revenue",
    "c_address": "Address",
    "c_billAddress": "Bill Address",
    "c_shippingAddress": "Ship Address",
    "c_modifiedAt": "Modified At",
    "delete_title": "Delete",
    "delete_description": "Do you want to delete \"{0}\"?"
  },
  "cn": {
    "c_firstName": "名",
    "c_lastName": "姓",
    "c_name": "联系人",
    "c_check": "选中",
    "c_phone": "电话",
    "c_website": "网站",
    "c_industry": "行业",
    "c_owner": "所有人",
    "c_updated": "更新时间",
    "c_tax": "税号",
    "c_revenue": "年收入",
    "c_address": "地址",
    "c_billAddress": "账单地址",
    "c_shippingAddress": "邮寄地址",
    "c_modifiedAt": "修改时间",
    "delete_title": "删除",
    "delete_description": "是否要删除\"{0}\"?"
  }
}
</i18n>
