import Vue from "vue";

const install = function (Vue) {
  Vue.prototype.localizeSchema = function (schemaItem) {
    let _this = this;
    schemaItem.forEach((schema) => {
      if (schema.name != undefined) {
        schema.text = _this.$t("c_" + schema.name);
      }
  
      if (schema.type == "dropdown") {
        if (schema.localized) {
          schema.itemText = "text." + _this.$i18n.locale;
        } else {
          schema.itemText = "text";
        }
      }
    });
  };
};

Vue.use(install);

export default install;
