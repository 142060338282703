<template>
  <v-text-field
    v-model="password"
    v-bind:password="password"
    v-on:change="$emit('change', password)"
    :readonly="readonly"
    :rules="rules"
    :clearable="clearable"
    :solo="solo"
    :flat="flat"
    :outlined="outlined"
    hide-details="auto"
    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
    :type="show ? 'text' : 'password'"
    @click:append="show = !show"
    dense
  >
    <template v-slot:prepend>
      <v-text-field
        class="field-label pt-1"
        solo
        flat
        readonly
        :reverse="reverse"
        dense
        :value="text + ':'"
      >
      </v-text-field>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: 'password',
    event: 'change'
  },
  props: {
    readonly: {},
    value: {},
    rules: {},
    clearable: {},
    solo: {},
    flat: {},
    outlined: {},
    reverse: {},
    text: {},
  },
  computed: {},
  data() {
    return {
      password: "",
      show: false,
    };
  },
  watch: {},
  methods: {},
};
</script>
