<template>
  <v-container fill-height class="d-flex justify-center">
    <v-card flat>
      <v-card-title primary-title class="layout justify-center">
        <h1>{{ $t("register") }}</h1>
      </v-card-title>
      <v-alert
        v-if="errorMessage != undefined"
        v-model="alert"
        class="ma-3"
        dense
        dismissible
        type="error"
      >
        {{ errorMessage }}
      </v-alert>
      <v-card-text>
        <v-form>
          <v-container fill-height>
            <!-- <v-row>
              <v-text-field
                v-model="data.name"
                :label="$t('name')"
              ></v-text-field>
            </v-row> -->
            <v-row>
              <v-text-field
                v-model="data.email"
                :label="$t('email')"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="data.password"
                type="password"
                :label="$t('password')"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="data.repassword"
                type="password"
                :label="$t('repassword')"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-row>
            <v-row
              justify="center"
              no-gutters
              justify-content="center"
              align-content="center"
            >
              <v-col class="d-flex justify-center">
                <v-btn color="primary" elevation="2" raised @click="register">
                  {{ $t("register") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col class="d-flex justify-center">
                <router-link to="/login">{{ $t("login") }}</router-link>
              </v-col>
            </v-row>
          </v-container>
          <div class="loading-overlay" v-if="loading">
            <v-progress-circular
              :width="3"
              :size="50"
              color="primary"
              indeterminate
            >
            </v-progress-circular>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      show: false,
      loading: false,
      alert: true,
      errorMessage: null,
    };
  },
  methods: {
    register() {
      this.alert = true;
      let obj = {
        username: this.data.email,
        password: this.data.password,
        repassword: this.data.repassword,
      };

      let _this = this;
      this.post("/auth/register", obj, {
        callback: function () {
          _this.$router.push("/login");
        },
        error: function (error) {
          _this.errorMessage = error;
        },
      });
    },
  },
};
</script>

<style lang="scss">
.loading-overlay {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "title": "Login",
    "name": "Name",
    "login": "Log In",
    "register": "Register",
    "email": "Email",
    "password": "Password",
    "repassword": "Re-Password"
  },
  "cn": {
    "title": "登录",
    "name": "名字",
    "login": "登录",
    "register": "注册",
    "email": "电子邮箱",
    "password": "密码",
    "repassword": "在输入一次密码"
  }
}
</i18n>
