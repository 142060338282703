module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
        "c_accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
        "c_appid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Id"])},
        "c_outTradeNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade No"])},
        "c_tradeState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade State"])},
        "c_tradeStateDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade State Description"])},
        "c_imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Screenshot"])},
        "dialogStatusTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])}
      },
      "cn": {
        "c_accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名"])},
        "c_accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户号"])},
        "c_appid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["应用ID"])},
        "c_outTradeNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商户订单号"])},
        "c_tradeState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易状态"])},
        "c_tradeStateDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易状态描述"])},
        "c_imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账截图"])},
        "dialogStatusTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易状态"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_accountName":"Account Name","c_accountNumber":"Account Number","c_appid":"App Id","c_outTradeNo":"Trade No","c_tradeState":"Trade State","c_tradeStateDesc":"Trade State Description","c_imageUrl":"Transfer Screenshot","dialogStatusTitle":"Payment Status"},"cn":{"c_accountName":"账户名","c_accountNumber":"账户号","c_appid":"应用ID","c_outTradeNo":"商户订单号","c_tradeState":"交易状态","c_tradeStateDesc":"交易状态描述","c_imageUrl":"转账截图","dialogStatusTitle":"交易状态"}}')
  delete Component.options._Ctor
  
}
