export default {
  types: [
    {
      value: "review",
      text: {
        cn: "合同法律风险审查",
        en: "Review Contract",
      },
    },
    {
      value: "analysis",
      text: {
        cn: "案件法律风险分析",
        en: "Case Analysis",
      },
    },
  ],
  levels: [
    {
      value: 1,
      text: {
        cn: "一级",
        en: "Level 1",
      },
    },
    {
      value: 2,
      text: {
        cn: "二级",
        en: "Level 2",
      },
    },
    {
      value: 3,
      text: {
        cn: "三级",
        en: "Level 3",
      },
    },
  ],
  reviewSubType: [
    {
      value: "review2500",
      text: {
        cn: "单份合同≤2500字",
        en: "Less Than 2500",
      },
    },
    {
      value: "review2500_6000",
      text: {
        cn: "2500字<单份合同总字数≤6000字",
        en: "More Than 2500 and Less Than 6000",
      },
    },
    {
      value: "perchars",
      text: {
        cn: "字数收费",
        en: "By Chars",
      },
    },
  ],
  analysisSubType: [
    {
      value: "business100",
      text: {
        cn: "商事案件 100万以下",
        en: "Business Lesss Than 1M RMB",
      },
    },
    {
      value: "business100_500",
      text: {
        cn: "商事案件 超过100万—500万以下",
        en: "Business More Than 1M and Lesss Than 5M RMB",
      },
    },
    {
      value: "business500",
      text: {
        cn: "商事案件 超过500万",
        en: "Business More Than 5M RMB",
      },
    },
    {
      value: "criminal",
      text: {
        cn: "刑事案件",
        en: "Criminal",
      },
    },
    {
      value: "civil_or_admin",
      text: {
        cn: "民事案件、行政案件",
        en: "Civil Case Or Administrative Cases",
      },
    },
  ]
};
