<template>
  <v-app :key="refreshCount">
    <v-app-bar dark app clipped-left v-if="isLogin() == true">
      <div class="text-h6 text-nowrap">法精英</div>
      <v-spacer></v-spacer>
      <v-tabs centered>
        <!-- <v-tabs centered v-if="currentMenu.menus != undefine"> -->
        <v-tab
          v-for="item in primaryMenus"
          :key="item.name"
          link
          @click="clickPrimaryMenu(item)"
          class="text-h6"
        >
          <!-- <v-tab v-for="item in currentMenu.menus" :key="item.name" link> -->
          <v-badge
            v-if="item.count != undefined && item.count > 0"
            color="red"
            :content="item.count"
          >
            {{ item.title }}
          </v-badge>
          <div v-if="item.count == undefined || item.count <= 0">
            {{ item.title }}
          </div>
        </v-tab>
      </v-tabs>

      <v-spacer></v-spacer>
      <!-- <v-btn icon x-large>
        <v-icon dark>mdi-lightbulb-outline</v-icon>
      </v-btn>
      <v-btn icon x-large>
        <v-icon dark>mdi-chat-processing-outline</v-icon>
      </v-btn>
      <v-btn icon x-large>
        <v-icon dark>mdi-help-circle-outline</v-icon>
      </v-btn> -->
      <v-btn icon x-large @click="goSetting()">
        <v-icon dark>mdi-cog</v-icon>
      </v-btn>
      <v-divider vertical class="mr-2 ml-2"></v-divider>
      <v-menu top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-large v-bind="attrs" v-on="on">
            <v-icon dark>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="goProfile()">{{
              $t("setting")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item> -->
        </v-list>
      </v-menu>
      <v-divider vertical class="mr-2 ml-2"> </v-divider>
    </v-app-bar>
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="align-start" fill-height>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <div style="width: 100%">
        <p class="text-center ma-0">
          Copyright © 2022-2022 陕西法精英法律信息咨询有限公司 版权所有
          <a href="https://beian.miit.gov.cn">陕ICP备2022005426号-1</a>
        </p>
      </div>
    </v-footer>
    <v-dialog v-model="showLogin" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> {{ $t("expiredTitle") }} </v-card-title>
        <v-card-text>
          {{ $t("expiredContent") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showLogin = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="logout()">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import pages from "./views/pages";

export default {
  components: {},
  name: "Home",

  data: () => ({
    showLogin: false,
    drawer: true,
    mini: true,
    currentMenu: "",
    primaryMenus: pages.data,
    snackbar: false,
    message: "",
    timeout: 2000,
    refreshCount: 0,
  }),
  created() {},
  mounted() {
    let activeCode = this.getQueryVariable("active_code");
    if (activeCode != null && activeCode != undefined && this.isLogin()) {
      this.logout();
    }

    console.log("IsLogin:" + this.isLogin());
    console.log("Route:" + this.$route.fullPath);
    console.log("this.$root.$i18n.locale:" + this.$root.$i18n.locale);
    console.log(
      "this.$root.$vuetify.lang.current:" + this.$root.$vuetify.lang.current
    );
    if (!this.isLogin() && this.$route.fullPath != "/register") {
      this.$router.push("/login");
      return;
    }

    this.$root.$on("show-error", this.showError);
    this.$root.$on("show-info", this.showInfo);
    this.$root.$on("show-expired-dialog", this.showExpiredDialog);
    this.$root.$on("change-lang", this.changeLanguage);
    this.$root.$on("update-tab", this.updateTab);
    this.currentMenu = this.primaryMenus[0];
    this.clickPrimaryMenu(this.currentMenu);
    this.updateTableText();
  },
  computed: {
    ...mapGetters({
      getUserInfo: "getUserInfo",
    }),
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  methods: {
    updateTableText() {
      if (this.primaryMenus == undefined) {
        return;
      }
      this.primaryMenus.forEach((element) => {
        element.title = this.$t(element.name);
      });
      this.$set(this.primaryMenus, "primaryMenus");
    },
    updateTab(info) {
      this.primaryMenus.forEach((element) => {
        if (info.name == element.name) {
          element.count = info.count;
        }
      });
    },
    clickPrimaryMenu(primaryMenu) {
      this.currentMenu = primaryMenu;
      if (primaryMenu.meuns != undefined) {
        this.$router.push(primaryMenu.menus[0].url);
      } else {
        this.$router.push({
          name: primaryMenu.url,
          params: primaryMenu.params,
        });
      }
    },
    clickSilveMenu(silveMenu) {
      this.$router.push(silveMenu.url);
    },
    isLogin() {
      return localStorage.getItem("app-token") != undefined;
    },
    logout() {
      localStorage.removeItem("app-token");
      this.setUserInfo(null);
      this.showLogin = false;
      this.$router.go("login");
    },
    goSetting() {
      this.$router.push("/setting/user");
    },
    goProfile() {
      this.$router.push("/profile/general");
    },
    showError(info) {
      this.message = info.message;
      this.snackbar = true;
    },
    showInfo(info) {
      this.message = info.message;
      this.snackbar = true;
    },
    showExpiredDialog() {
      this.showLogin = true;
    },
    changeLanguage(lang) {
      console.log("ChangeLanguage:" + lang);
      this.$root.$i18n.locale = lang;
      this.$root.$vuetify.lang.current = lang == "cn" ? "zhHans" : "en";
      this.updateTableText();
      this.refreshCount++;
      console.log("this.$root.$i18n.locale:" + this.$root.$i18n.locale);
      console.log(
        "this.$root.$vuetify.lang.current:" + this.$root.$vuetify.lang.current
      );
    },
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return undefined;
    },
    ...mapMutations({
      setUserInfo: "setUserInfo",
    }),
  },
  watch: {
    "$i18n.locale": function () {
      this.updateTableText();
    },
  },
};
</script>

<style>
.text-nowrap {
  white-space: nowrap;
}
.v-main {
  background-color: #edf0f4;
}
.nav-drawer {
  margin-top: 64px;
  padding-bottom: 64px;
}
.container {
  max-width: 2500px !important;
  padding: 0px !important;
}
.main-container {
  padding: 24px;
}
.form-container {
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "expiredTitle": "Timeout",
    "expiredContent": "Your session has timed out. Click \"OK\" to login again."
  },
  "cn": {
    "expiredTitle": "连接超时",
    "expiredContent": "连接超时. 点击\"确定\"重新登录."
  }
}
</i18n>
