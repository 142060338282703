<template>
  <entity-view
  :item-schemas="itemSchemas"
  :select="select"
  :readonly="true"
  :items="items"
  :title="$t('cart')"
  :pagination="pagination"
  :externalActions="externalActions"
  :options="options"
  @items-refresh="itemsRefresh"
  @item-action="itemAction"
  @page-change="pageChange"
  @double-click-row="clickRow"
  >
  </entity-view>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import ProductDropdowns from "../common/product_dropdown";

export default {
  components: { EntityView },
  props: {
    userId: {},
  },
  data: () => ({
    select: false,
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      // {
      //   name: "userName",
      //   value: "userName",
      //   type: "string",
      // },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: ProductDropdowns.types,
        localized: true,
      },
      {
        name: "modifiedAt",
        value: "modifiedAt",
        type: "date",
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateComponent();
  },
  computed: {
    options: function() {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: false,
      }
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      let postData = {};
      postData.pagination = this.pagination;
      // if (this.criterias != undefined && this.criterias.length != 0 && this.criterias.length != undefined) {
      //   postData.criterias = this.criterias;
      // }
      postData.criterias = [
        {
          column: "user_id",
          operator: "=",
          value: this.userId,
          type: "number"
        }
      ]
      this.post("/api/carts", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow() {
    },
    itemCreate() {
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction() {
    },
  },
};
</script>

<i18n>
{
  "en": {
    "c_name": "Name"
  },
  "cn": {
    "c_name": "主题"
  }
}
</i18n>
