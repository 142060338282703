module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "taxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票信息"])},
        "c_taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])},
        "c_companyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位地址"])},
        "c_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "c_bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行账户"])},
        "c_payName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款人姓名"])},
        "c_payBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_payBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行账号"])},
        "c_toName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人姓名"])},
        "c_toAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人地址"])},
        "c_toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人电话"])},
        "c_toEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人邮箱"])},
        "c_invoiceKind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票类型"])}
      },
      "cn": {
        "taxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票信息"])},
        "c_taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税号"])},
        "c_companyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位地址"])},
        "c_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "c_bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行账户"])},
        "c_payName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款人姓名"])},
        "c_payBankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行"])},
        "c_payBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开户行账号"])},
        "c_toName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人姓名"])},
        "c_toAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人地址"])},
        "c_toPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人电话"])},
        "c_toEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人邮箱"])},
        "c_invoiceKind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票类型"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"taxInfo":"发票信息","c_taxNumber":"税号","c_companyAddress":"单位地址","c_telephone":"电话","c_bankName":"开户行","c_bankAccount":"银行账户","c_payName":"收款人姓名","c_payBankName":"开户行","c_payBankAccount":"开户行账号","c_toName":"收件人姓名","c_toAddress":"收件人地址","c_toPhone":"收件人电话","c_toEmail":"收件人邮箱","c_invoiceKind":"发票类型"},"cn":{"taxInfo":"发票信息","c_taxNumber":"税号","c_companyAddress":"单位地址","c_telephone":"电话","c_bankName":"开户行","c_bankAccount":"银行账户","c_payName":"收款人姓名","c_payBankName":"开户行","c_payBankAccount":"开户行账号","c_toName":"收件人姓名","c_toAddress":"收件人地址","c_toPhone":"收件人电话","c_toEmail":"收件人邮箱","c_invoiceKind":"发票类型"}}')
  delete Component.options._Ctor
  
}
