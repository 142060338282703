<template>
  <v-container>
    <v-navigation-drawer app fixed clipped width="400">
      <!-- <v-list-item two-line>
        <v-list-item-avatar>
          <v-icon large>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title> </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <v-tabs vertical show-arrows>
        <v-tab
          v-for="item in settingMenus"
          :key="item.name"
          link
          @click="clickSettingMenu(item)"
          class="text-h6"
          active-class="tab-active"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <router-view></router-view>
    </div>
  </v-container> 
</template>

<script>

export default {
  props: {
  },
  data: () => ({
    title: "Account",
    settingMenus: [
      {
        name: "general",
        title: "tab_user_general",
        url: "/Profile/General",
      },
      {
        name: "resetpassword",
        title: "tab_reset_password",
        url: "/Profile/ResetPassword",
      },
    ],
  }),
   mounted() {
    this.updateSchemaData();
  },
  computed: {
    
  },
  methods: {
    updateSchemaData() {
    },
    clickSettingMenu(primaryMenu) {
      this.$router.push(primaryMenu.url);
    },
  },
};
</script>

<style scoped>
.tab-active {
  background-color: #272727;
  color: white;
}
</style>

