module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required."])},
        "rule_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail must be valid"])},
        "rule_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number must be valid"])},
        "no_data_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])}
      },
      "cn": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必选项"])},
        "rule_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail不合法"])},
        "rule_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须是数字"])},
        "no_data_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有数据"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"required":"Required.","rule_email":"E-mail must be valid","rule_number":"Number must be valid","no_data_text":"No data available"},"cn":{"required":"必选项","rule_email":"E-mail不合法","rule_number":"必须是数字","no_data_text":"没有数据"}}')
  delete Component.options._Ctor
  
}
