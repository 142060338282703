import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    breadcumb: {},
    back: {},
  },
  getters: {
    getUserInfo: state => {
      let user = localStorage.getItem("current-user");
      if (user != undefined) {
        state.userInfo = JSON.parse(user);
      }
      return state.userInfo;
    },
  },
  mutations: {
    setUserInfo (state, userInfo) {
      if (userInfo == null) {
        state.userInfo = null;
        localStorage.removeItem("current-user");
      } else {
        state.userInfo = userInfo;
        localStorage.setItem("current-user", JSON.stringify(userInfo));
      }
    },
    changeBreadcumb (state, value) {
      state.breadcumb = value
    },
    changeBack (state, value) {
      state.back = value
    },
  },
  actions: {
  },
  modules: {
  }
})
