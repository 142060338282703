<template>
  <v-container fluid>
    <v-navigation-drawer app fixed clipped width="400">
      <template v-slot:prepend :elevation="3">
        <v-list-item>
          <v-list-item-title>{{ $t("search") }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item>
          <v-text-field prepend-inner-icon="mdi-magnify" rounded solo>
          </v-text-field>
        </v-list-item> -->
        <v-divider></v-divider>
      </template>
      <template v-slot:append :elevation="3">
        <v-divider></v-divider>
        <div class="d-flex pa-4">
          <v-btn color="primary" class="mr-4" @click="filter()">{{
            $t("apply_filter")
          }}</v-btn>
          <v-btn color="primary" @click="clearFilter()">{{
            $t("cancel")
          }}</v-btn>
        </div>
      </template>

      <div class="pa-2">
        <filter-part ref="filterView"> </filter-part>
      </div>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <v-row class="ma-0 mb-2">
        <v-card class="form-container">
          <v-card-title class="flex justify-space-between">
            <v-btn color="primary" :outlined="selectedIdx != 0" @click="selectIdx(0)">待接单 </v-btn>
            <v-btn color="primary" :outlined="selectedIdx != 1" @click="selectIdx(1)">待付款 </v-btn>
            <v-btn color="primary" :outlined="selectedIdx != 2" @click="selectIdx(2)">进行中 </v-btn>
            <v-btn color="primary" :outlined="selectedIdx != 3" @click="selectIdx(3)">完成</v-btn>
            <v-btn color="primary" :outlined="selectedIdx != 4" @click="selectIdx(4)">退款</v-btn>
            <v-btn color="primary" :outlined="selectedIdx != 5" @click="selectIdx(5)">全部</v-btn>
          </v-card-title>
        </v-card>
      </v-row>
      <entity-view
        :item-schemas="itemSchemas"
        :readonly="readonly"
        :items="items"
        :title="$t('order')"
        :pagination="pagination"
        :externalActions="externalActions"
        :options="options"
        @items-refresh="itemsRefresh"
        @item-action="itemAction"
        @page-change="pageChange"
        @double-click-row="clickRow"
      >
      </entity-view>
    </div>
    <input-dialog></input-dialog>
  </v-container>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import InputDialog from "../components/InputDialog.vue";
import FilterPart from "./FilterPart.vue";
import ProductDropdowns from "../common/product_dropdown";
import OrderStatusDropdowns from "../common/order_status";

export default {
  components: { EntityView, InputDialog, FilterPart },
  data: () => ({
    title: "Order",
    select: true,
    expendOpen: false,
    readonly: true,
    selectedIdx: 0,
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      {
        name: "orderNo",
        value: "orderNo",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
      },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: ProductDropdowns.types,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
        hasfilter: true,
        condition: {},
      },
      {
        name: "deadline",
        value: "deadline",
        type: "date",
        hasfilter: true,
        condition: {},
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        dropdown: OrderStatusDropdowns.values,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "modifiedAt",
        value: "modifiedAt",
        type: "date",
        hasfilter: true,
        condition: {},
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: false,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.$refs.filterView.loadSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let criterias = this.$refs.filterView.getCriterias();
      let postData = {
        pagination: this.pagination,
        criterias: criterias,
      };
      if (postData.criterias != undefined) {
        let status = [];
        if (this.selectedIdx == 0) {
          status.push("new");
        } else if (this.selectedIdx == 1) {
          status.push("accept");
          status.push("approve_pay");
          status.push("prepay");
        } else if (this.selectedIdx == 2) {
          status.push("process");
        } else if (this.selectedIdx == 3) {
          status.push("finish");
          status.push("feedback");
          status.push("close");
        } else if (this.selectedIdx == 4) {
          status.push("pay_back");
        }

        status.forEach((s) => {
          postData.criterias.push({
            operator: "=",
            type: "string",
            value: s,
            column: "status",
            condition: "or",
          });
        });
      }
      //TODO maybe we should change the role here.
      this.post("/api/orders?role=admin", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.$router.push({
        name: "OrderDetail",
        params: { id: item.id + "" },
      });
    },
    itemCreate() {
      this.$router.push({ name: "OrderDetail" });
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name) {
      if (name === "insert") {
        this.itemCreate();
      }
    },
    selectIdx(idx) {
      this.selectedIdx = idx;
      this.refresh();
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
  "en": {
   
  },
  "cn": {
 
  }
}
</i18n>
