module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "c_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
      },
      "cn": {
        "c_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"c_name":"Name"},"cn":{"c_name":"用户名"}}')
  delete Component.options._Ctor
  
}
