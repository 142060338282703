export default {
  Kinds: {
    items: [
      {
        name: "危害国家安全罪",
        level: 1,
        index: 1,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "背叛国家罪",
            level: 2,
            index: 102,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "分裂国家罪",
            level: 2,
            index: 103,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "分裂国家罪",
                level: 3,
                index: "103.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "煽动分裂国家罪",
                level: 3,
                index: "103.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "武装叛乱、暴乱罪",
            level: 2,
            index: 104,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "颠覆国家政权罪",
            level: 2,
            index: 105,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "颠覆国家政权罪",
                level: 3,
                index: "105.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "煽动颠覆国家政权罪",
                level: 3,
                index: "105.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "与境外勾结的从重处罚",
            level: 2,
            index: 106,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "资助危害国家安全犯罪活动罪",
            level: 2,
            index: 107,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "投敌叛变罪",
            level: 2,
            index: 108,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "叛逃罪",
            level: 2,
            index: 109,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "间谍罪",
            level: 2,
            index: 110,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "为境外窃取、刺探、收买、非法提供国家秘密、情报罪",
            level: 2,
            index: 111,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "资敌罪",
            level: 2,
            index: 112,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "危害国家安全罪适用死刑、没收财产的规定",
            level: 2,
            index: 113,
            kind: "CrimialLaw",
            tag: "item",
          },
        ],
      },
      {
        name: "危害公共安全罪",
        level: 1,
        index: 2,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "放火罪",
            level: 2,
            index: 114,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "放火罪",
                level: 3,
                index: "114.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "决水罪",
                level: 3,
                index: "114.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "爆炸罪",
                level: 3,
                index: "114.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "投放危险物质罪",
                level: 3,
                index: "114.4",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "以危险方法危害公共安全罪",
                level: 3,
                index: "114.5",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "放火罪",
            level: 2,
            index: 115,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "放火罪",
                level: 3,
                index: "115.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "决水罪",
                level: 3,
                index: "115.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "爆炸罪",
                level: 3,
                index: "115.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "投放危险物质罪",
                level: 3,
                index: "115.4",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "以危险方法危害公共安全罪",
                level: 3,
                index: "115.5",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "失火罪",
                level: 3,
                index: "115.6",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失决水罪",
                level: 3,
                index: "115.7",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失爆炸罪",
                level: 3,
                index: "115.8",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失投放危险物质罪",
                level: 3,
                index: "115.9",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失以危险方法危害公共安全罪",
                level: 3,
                index: "115.10",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "破坏交通工具罪",
            level: 2,
            index: 116,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏交通设施罪",
            level: 2,
            index: 117,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏电力设备罪",
            level: 2,
            index: 118,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "破坏电力设备罪",
                level: 3,
                index: "118.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "破坏易燃易爆设备罪",
                level: 3,
                index: "118.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "破坏交通工具罪",
            level: 2,
            index: 119,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "破坏交通工具罪",
                level: 3,
                index: "119.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "破坏交通设施罪",
                level: 3,
                index: "119.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "破坏电力设备罪",
                level: 3,
                index: "119.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "破坏易燃易爆设备罪",
                level: 3,
                index: "119.4",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏交通工具罪",
                level: 3,
                index: "119.5",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏交通设施罪",
                level: 3,
                index: "119.6",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏电力设备罪",
                level: 3,
                index: "119.7",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏易燃易爆设备罪",
                level: 3,
                index: "119.8",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "组织、领导、参加恐怖组织罪",
            level: 2,
            index: 120,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "组织、领导、参加恐怖组织罪",
                level: 3,
                index: "120.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "帮助恐怖活动罪",
                level: 3,
                index: "120.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "准备实施恐怖活动罪",
                level: 3,
                index: "120.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "宣扬恐怖主义、极端主义、煽动实施恐怖活动罪",
                level: 3,
                index: "120.4",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "利用极端主义破坏法律实施罪",
                level: 3,
                index: "120.5",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "强制穿戴宣扬恐怖主义、极端主义服饰、标志罪",
                level: 3,
                index: "120.6",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法持有宣扬恐怖主义、极端主义物品罪",
                level: 3,
                index: "120.7",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "劫持航空器罪",
            level: 2,
            index: 121,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "劫持船只、汽车罪",
            level: 2,
            index: 122,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "暴力危及飞行安全罪",
            level: 2,
            index: 123,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏广播电视设施、公用电信设施罪",
            level: 2,
            index: 124,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "破坏广播电视设施、公用电信设施罪",
                level: 3,
                index: "124.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏广播电视设施、公用电信设施罪",
                level: 3,
                index: "124.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "非法制造、买卖、运输、邮寄、储存枪支、弹药、爆炸物罪",
            level: 2,
            index: 125,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法制造、买卖、运输、邮寄、储存枪支、弹药、爆炸物罪",
                level: 3,
                index: "125.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法制造、买卖、运输、储存危险物质罪",
                level: 3,
                index: "125.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "违规制造、销售枪支罪",
            level: 2,
            index: 126,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "盗窃、抢夺枪支、弹药、爆炸物、危险物质罪",
            level: 2,
            index: 127,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "盗窃、抢夺枪支、弹药、爆炸物、危险物质罪",
                level: 3,
                index: "127.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "抢劫枪支、弹药、爆炸物、危险物质罪",
                level: 3,
                index: "127.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "盗窃、抢夺枪支、弹药、爆炸物、危险物质罪",
                level: 3,
                index: "127.3",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "非法持有、私藏枪支、弹药罪",
            level: 2,
            index: 128,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法持有、私藏枪支、弹药罪",
                level: 3,
                index: "128.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法出租、出借枪支罪",
                level: 3,
                index: "128.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "丢失枪支不报罪",
            level: 2,
            index: 129,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "非法携带枪支、弹药、管制刀具、危险物品危及公共安全罪",
            level: 2,
            index: 130,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "重大飞行事故罪",
            level: 2,
            index: 131,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "铁路运营安全事故罪",
            level: 2,
            index: 132,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "交通肇事罪",
            level: 2,
            index: 133,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "交通肇事罪",
                level: 3,
                index: "133.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "危险驾驶罪",
                level: 3,
                index: "133.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "妨害安全驾驶罪",
                level: 3,
                index: "133.3",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "重大责任事故罪",
            level: 2,
            index: 134,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "重大责任事故罪",
                level: 3,
                index: "134.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "强令、组织他人违章冒险作业罪",
                level: 3,
                index: "134.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "危险作业罪",
                level: 3,
                index: "134.3",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "重大劳动安全事故罪",
            level: 2,
            index: 135,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "重大劳动安全事故罪",
                level: 3,
                index: "135.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "大型群众性活动重大安全事故罪",
                level: 3,
                index: "135.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "危险物品肇事罪",
            level: 2,
            index: 136,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "工程重大安全事故罪",
            level: 2,
            index: 137,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "教育设施重大安全事故罪",
            level: 2,
            index: 138,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "消防责任事故罪",
            level: 2,
            index: 139,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "消防责任事故罪",
                level: 3,
                index: "139.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "不报、谎报安全事故罪",
                level: 3,
                index: "139.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
        ],
      },
      {
        name: "破坏社会主义市场经济秩序罪",
        level: 1,
        index: 3,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "",
            level: 2,
            index: 1,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 140,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售伪劣产品罪",
                    level: 4,
                    index: "140.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 141,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售、提供假药罪",
                    level: 4,
                    index: "141.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 142,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售、提供劣药罪",
                    level: 4,
                    index: "142.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "妨害药品管理罪",
                    level: 4,
                    index: "142.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 143,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售不符合安全标准的食品罪",
                    level: 4,
                    index: "143.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 144,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售有毒、有害食品罪",
                    level: 4,
                    index: "144.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 145,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售不符合标准的医用器材罪",
                    level: 4,
                    index: "145.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 146,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售不符合安全标准的产品罪",
                    level: 4,
                    index: "146.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 147,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售伪劣农药、兽药、化肥、种子罪",
                    level: 4,
                    index: "147.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 148,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "生产、销售不符合卫生标准的化妆品罪",
                    level: 4,
                    index: "148.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 149,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "对生产销售伪劣商品行为的法条适用",
                    level: 4,
                    index: "149.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 150,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "150.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 2,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 151,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私武器、弹药罪",
                    level: 4,
                    index: "151.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私核材料罪",
                    level: 4,
                    index: "151.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私假币罪",
                    level: 4,
                    index: "151.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私文物罪",
                    level: 4,
                    index: "151.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私贵重金属罪",
                    level: 4,
                    index: "151.5",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私珍贵动物、珍贵动物制品罪",
                    level: 4,
                    index: "151.6",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私国家禁止进出口的货物、物品罪",
                    level: 4,
                    index: "151.7",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 152,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私淫秽物品罪",
                    level: 4,
                    index: "152.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "走私废物罪",
                    level: 4,
                    index: "152.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 153,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私普通货物、物品罪",
                    level: 4,
                    index: "153.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 154,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私保税货物和特定减免税货物犯罪",
                    level: 4,
                    index: "154.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 155,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "以走私罪论处的情形",
                    level: 4,
                    index: "155.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 156,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私罪的共犯",
                    level: 4,
                    index: "156.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 157,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "武装掩护走私和以暴力、威胁方法抗拒缉私的处罚",
                    level: 4,
                    index: "157.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 3,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 158,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "虚报注册资本罪",
                    level: 4,
                    index: "158.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 159,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "虚假出资、抽逃出资罪",
                    level: 4,
                    index: "159.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 160,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "欺诈发行证券罪",
                    level: 4,
                    index: "160.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 161,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "违规披露、不披露重要信息罪",
                    level: 4,
                    index: "161.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 162,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害清算罪",
                    level: 4,
                    index: "162.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "隐匿、故意销毁会计凭证、会计账簿、财务会计报告罪",
                    level: 4,
                    index: "162.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "虚假破产罪",
                    level: 4,
                    index: "162.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 163,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非国家工作人员受贿罪",
                    level: 4,
                    index: "163.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 164,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "对非国家工作人员行贿罪",
                    level: 4,
                    index: "164.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "对外国公职人员、国际公共组织官员行贿罪",
                    level: 4,
                    index: "164.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 165,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法经营同类营业罪",
                    level: 4,
                    index: "165.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 166,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "为亲友非法牟利罪",
                    level: 4,
                    index: "166.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 167,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "签订、履行合同失职被骗罪",
                    level: 4,
                    index: "167.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 168,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "国有公司、企业、事业单位人员失职罪",
                    level: 4,
                    index: "168.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "国有公司、企业、事业单位人员滥用职权罪",
                    level: 4,
                    index: "168.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 169,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "徇私舞弊低价折股、出售国有资产罪",
                    level: 4,
                    index: "169.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "背信损害上市公司利益罪",
                    level: 4,
                    index: "169.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 4,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 170,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造货币罪",
                    level: 4,
                    index: "170.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 171,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "出售、购买、运输假币罪",
                    level: 4,
                    index: "171.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "金融工作人员购买假币、以假币换取货币罪",
                    level: 4,
                    index: "171.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 172,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "持有、使用假币罪",
                    level: 4,
                    index: "172.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 173,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "变造货币罪",
                    level: 4,
                    index: "173.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 174,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "擅自设立金融机构罪",
                    level: 4,
                    index: "174.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "伪造、变造、转让金融机构经营许可证、批准文件罪",
                    level: 4,
                    index: "174.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 175,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "高利转贷罪",
                    level: 4,
                    index: "175.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "骗取贷款、票据承兑、金融票证罪",
                    level: 4,
                    index: "175.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 176,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法吸收公众存款罪",
                    level: 4,
                    index: "176.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 177,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造、变造金融票证罪",
                    level: 4,
                    index: "177.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "妨害信用卡管理罪",
                    level: 4,
                    index: "177.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "窃取、收买、非法提供信用卡信息罪",
                    level: 4,
                    index: "177.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 178,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造、变造国家有价证券罪",
                    level: 4,
                    index: "178.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "伪造、变造股票、公司、企业债券罪",
                    level: 4,
                    index: "178.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 179,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "擅自发行股票、公司、企业债券罪",
                    level: 4,
                    index: "179.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 180,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "内幕交易、泄露内幕信息罪",
                    level: 4,
                    index: "180.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "利用未公开信息交易罪",
                    level: 4,
                    index: "180.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 181,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "编造并传播证券、期货交易虚假信息罪",
                    level: 4,
                    index: "181.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "诱骗投资者买卖证券、期货合约罪",
                    level: 4,
                    index: "181.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 182,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "操纵证券、期货市场罪",
                    level: 4,
                    index: "182.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 183,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "保险公司工作人员虚假理赔的犯罪及其处罚",
                    level: 4,
                    index: "183.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 184,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "金融机构工作人员受贿的犯罪及其处罚",
                    level: 4,
                    index: "184.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 185,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "金融机构工作人员挪用资金、公款的犯罪及其处罚",
                    level: 4,
                    index: "185.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "背信运用受托财产罪",
                    level: 4,
                    index: "185.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "违法运用资金罪",
                    level: 4,
                    index: "185.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 186,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "违法发放贷款罪",
                    level: 4,
                    index: "186.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 187,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "吸收客户资金不入账罪",
                    level: 4,
                    index: "187.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 188,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "违规出具金融票证罪",
                    level: 4,
                    index: "188.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 189,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "对违法票据承兑、付款、保证罪",
                    level: 4,
                    index: "189.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 190,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "逃汇罪",
                    level: 4,
                    index: "190.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 191,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "洗钱罪",
                    level: 4,
                    index: "191.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 5,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 192,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "集资诈骗罪",
                    level: 4,
                    index: "192.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 193,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "贷款诈骗罪",
                    level: 4,
                    index: "193.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 194,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "票据诈骗罪",
                    level: 4,
                    index: "194.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "金融凭证诈骗罪",
                    level: 4,
                    index: "194.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 195,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "信用证诈骗罪",
                    level: 4,
                    index: "195.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 196,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "信用卡诈骗罪",
                    level: 4,
                    index: "196.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 197,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "有价证券诈骗罪",
                    level: 4,
                    index: "197.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 198,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "保险诈骗罪",
                    level: 4,
                    index: "198.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 199,
                kind: "CrimialLaw",
                tag: "item",
                children: [],
              },
              {
                name: "",
                level: 3,
                index: 200,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "200.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 6,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 201,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "逃税罪",
                    level: 4,
                    index: "201.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 202,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "抗税罪",
                    level: 4,
                    index: "202.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 203,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "逃避追缴欠税罪",
                    level: 4,
                    index: "203.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 204,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "骗取出口退税罪",
                    level: 4,
                    index: "204.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 205,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "虚开增值税专用发票、用于骗取出口退税、抵扣税款发票罪",
                    level: 4,
                    index: "205.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "虚开发票罪",
                    level: 4,
                    index: "205.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 206,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造、出售伪造的增值税专用发票罪",
                    level: 4,
                    index: "206.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 207,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法出售增值税专用发票罪",
                    level: 4,
                    index: "207.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 208,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法购买增值税专用发票、购买伪造的增值税专用发票罪",
                    level: 4,
                    index: "208.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 209,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法制造、出售非法制造的用于骗取出口退税、抵扣税款发票罪",
                    level: 4,
                    index: "209.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法制造、出售非法制造的发票罪",
                    level: 4,
                    index: "209.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法出售用于骗取出口退税、抵扣税款发票罪",
                    level: 4,
                    index: "209.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法出售发票罪",
                    level: 4,
                    index: "209.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 210,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "盗窃、诈骗增值税专用发票、用于骗取出口退税、抵扣税款发票的处罚",
                    level: 4,
                    index: "210.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "持有伪造的发票罪",
                    level: 4,
                    index: "210.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 211,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "211.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 212,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "税收征缴优先原则",
                    level: 4,
                    index: "212.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 7,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 213,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "假冒注册商标罪",
                    level: 4,
                    index: "213.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 214,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "销售假冒注册商标的商品罪",
                    level: 4,
                    index: "214.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 215,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法制造、销售非法制造的注册商标标识罪",
                    level: 4,
                    index: "215.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 216,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "假冒专利罪",
                    level: 4,
                    index: "216.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 217,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "侵犯著作权罪",
                    level: 4,
                    index: "217.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 218,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "销售侵权复制品罪",
                    level: 4,
                    index: "218.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 219,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "侵犯商业秘密罪",
                    level: 4,
                    index: "219.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "为境外窃取、刺探、收买、非法提供商业秘密罪",
                    level: 4,
                    index: "219.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 220,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "220.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 8,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 221,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "损害商业信誉、商品声誉罪",
                    level: 4,
                    index: "221.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 222,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "虚假广告罪",
                    level: 4,
                    index: "222.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 223,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "串通投标罪",
                    level: 4,
                    index: "223.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 224,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "合同诈骗罪",
                    level: 4,
                    index: "224.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织、领导传销活动罪",
                    level: 4,
                    index: "224.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 225,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法经营罪",
                    level: 4,
                    index: "225.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 226,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "强迫交易罪",
                    level: 4,
                    index: "226.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 227,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造、倒卖伪造的有价票证罪",
                    level: 4,
                    index: "227.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "倒卖车票、船票罪",
                    level: 4,
                    index: "227.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 228,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法转让、倒卖土地使用权罪",
                    level: 4,
                    index: "228.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 229,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "提供虚假证明文件罪",
                    level: 4,
                    index: "229.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "出具证明文件重大失实罪",
                    level: 4,
                    index: "229.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 230,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "逃避商检罪",
                    level: 4,
                    index: "230.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 231,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "231.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "侵犯公民人身权利、民主权利罪",
        level: 1,
        index: 4,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "故意杀人罪",
            level: 2,
            index: 232,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "过失致人死亡罪",
            level: 2,
            index: 233,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "故意伤害罪",
            level: 2,
            index: 234,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "故意伤害罪",
                level: 3,
                index: "234.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "组织出卖人体器官罪",
                level: 3,
                index: "234.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "过失致人重伤罪",
            level: 2,
            index: 235,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "强奸罪",
            level: 2,
            index: 236,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "强奸罪",
                level: 3,
                index: "236.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "负有照护职责人员性侵罪",
                level: 3,
                index: "236.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "强制猥亵、侮辱罪",
            level: 2,
            index: 237,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "强制猥亵、侮辱罪",
                level: 3,
                index: "237.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "猥亵儿童罪",
                level: 3,
                index: "237.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "非法拘禁罪",
            level: 2,
            index: 238,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "绑架罪",
            level: 2,
            index: 239,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "拐卖妇女、儿童罪",
            level: 2,
            index: 240,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "收买被拐卖的妇女、儿童罪",
            level: 2,
            index: 241,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "聚众阻碍解救被收买的妇女、儿童罪",
            level: 2,
            index: 242,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "诬告陷害罪",
            level: 2,
            index: 243,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "强迫劳动罪",
            level: 2,
            index: 244,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "强迫劳动罪",
                level: 3,
                index: "244.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "雇用童工从事危重劳动罪",
                level: 3,
                index: "244.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "非法搜查罪",
            level: 2,
            index: 245,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法搜查罪",
                level: 3,
                index: "245.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法侵入住宅罪",
                level: 3,
                index: "245.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "侮辱罪",
            level: 2,
            index: 246,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "侮辱罪",
                level: 3,
                index: "246.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "诽谤罪",
                level: 3,
                index: "246.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "刑讯逼供罪",
            level: 2,
            index: 247,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "刑讯逼供罪",
                level: 3,
                index: "247.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "暴力取证罪",
                level: 3,
                index: "247.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "虐待被监管人罪",
            level: 2,
            index: 248,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "煽动民族仇恨、民族歧视罪",
            level: 2,
            index: 249,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "出版歧视、侮辱少数民族作品罪",
            level: 2,
            index: 250,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "非法剥夺公民宗教信仰自由罪",
            level: 2,
            index: 251,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法剥夺公民宗教信仰自由罪",
                level: 3,
                index: "251.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "侵犯少数民族风俗习惯罪",
                level: 3,
                index: "251.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "侵犯通信自由罪",
            level: 2,
            index: 252,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "私自开拆、隐匿、毁弃邮件、电报罪",
            level: 2,
            index: 253,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "私自开拆、隐匿、毁弃邮件、电报罪",
                level: 3,
                index: "253.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "侵犯公民个人信息罪",
                level: 3,
                index: "253.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "报复陷害罪",
            level: 2,
            index: 254,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "打击报复会计、统计人员罪",
            level: 2,
            index: 255,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏选举罪",
            level: 2,
            index: 256,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "暴力干涉婚姻自由罪",
            level: 2,
            index: 257,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "重婚罪",
            level: 2,
            index: 258,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏军婚罪",
            level: 2,
            index: 259,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "虐待罪",
            level: 2,
            index: 260,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "虐待罪",
                level: 3,
                index: "260.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "虐待被监护、看护人罪",
                level: 3,
                index: "260.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "遗弃罪",
            level: 2,
            index: 261,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "拐骗儿童罪",
            level: 2,
            index: 262,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "拐骗儿童罪",
                level: 3,
                index: "262.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "组织残疾人、儿童乞讨罪",
                level: 3,
                index: "262.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "组织未成年人进行违反治安管理活动罪",
                level: 3,
                index: "262.3",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
        ],
      },
      {
        name: "侵犯财产罪",
        level: 1,
        index: 5,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "抢劫罪",
            level: 2,
            index: 263,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "盗窃罪",
            level: 2,
            index: 264,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "盗窃通信线路、复制电信号码的处罚",
            level: 2,
            index: 265,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "诈骗罪",
            level: 2,
            index: 266,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "抢夺罪",
            level: 2,
            index: 267,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "聚众哄抢罪",
            level: 2,
            index: 268,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "转化的抢劫罪",
            level: 2,
            index: 269,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "侵占罪",
            level: 2,
            index: 270,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "职务侵占罪",
            level: 2,
            index: 271,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "挪用资金罪",
            level: 2,
            index: 272,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "挪用特定款物罪",
            level: 2,
            index: 273,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "敲诈勒索罪",
            level: 2,
            index: 274,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "故意毁坏财物罪",
            level: 2,
            index: 275,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "破坏生产经营罪",
            level: 2,
            index: 276,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "破坏生产经营罪",
                level: 3,
                index: "276.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "拒不支付劳动报酬罪",
                level: 3,
                index: "276.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
        ],
      },
      {
        name: "妨害社会管理秩序罪",
        level: 1,
        index: 6,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "",
            level: 2,
            index: 1,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 277,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害公务罪",
                    level: 4,
                    index: "277.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "袭警罪",
                    level: 4,
                    index: "277.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 278,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "煽动暴力抗拒法律实施罪",
                    level: 4,
                    index: "278.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 279,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "招摇撞骗罪",
                    level: 4,
                    index: "279.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 280,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪造、变造、买卖国家机关公文、证件、印章罪",
                    level: 4,
                    index: "280.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "盗窃、抢夺、毁灭国家机关公文、证件、印章罪",
                    level: 4,
                    index: "280.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "伪造公司、企业、事业单位、人民团体印章罪",
                    level: 4,
                    index: "280.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "伪造、变造、买卖身份证件罪",
                    level: 4,
                    index: "280.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "使用虚假身份证件、盗用身份证件罪",
                    level: 4,
                    index: "280.5",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "冒名顶替罪",
                    level: 4,
                    index: "280.6",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 281,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法生产、买卖警用装备罪",
                    level: 4,
                    index: "281.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 282,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法获取国家秘密罪",
                    level: 4,
                    index: "282.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法持有国家绝密、机密文件、资料、物品罪",
                    level: 4,
                    index: "282.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 283,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法生产、销售专用间谍器材、窃听、窃照专用器材罪",
                    level: 4,
                    index: "283.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 284,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法使用窃听、窃照专用器材罪",
                    level: 4,
                    index: "284.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织考试作弊罪",
                    level: 4,
                    index: "284.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法出售、提供试题、答案罪",
                    level: 4,
                    index: "284.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "代替考试罪",
                    level: 4,
                    index: "284.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 285,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法侵入计算机信息系统罪",
                    level: 4,
                    index: "285.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法获取计算机信息系统数据、非法控制计算机信息系统罪",
                    level: 4,
                    index: "285.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "提供侵入、非法控制计算机信息系统程序、工具罪",
                    level: 4,
                    index: "285.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 286,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "破坏计算机信息系统罪",
                    level: 4,
                    index: "286.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "拒不履行信息网络安全管理义务罪",
                    level: 4,
                    index: "286.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 287,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "利用计算机实施金融诈骗、盗窃等犯罪的处理",
                    level: 4,
                    index: "287.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法利用信息网络罪",
                    level: 4,
                    index: "287.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "帮助信息网络犯罪活动罪",
                    level: 4,
                    index: "287.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 288,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "扰乱无线电通讯管理秩序罪",
                    level: 4,
                    index: "288.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 289,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "聚众 “打砸抢”的定罪处罚规定",
                    level: 4,
                    index: "289.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 290,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "聚众扰乱社会秩序罪",
                    level: 4,
                    index: "290.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "聚众冲击国家机关罪",
                    level: 4,
                    index: "290.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "扰乱国家机关工作秩序罪",
                    level: 4,
                    index: "290.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织、资助非法聚集罪",
                    level: 4,
                    index: "290.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 291,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "聚众扰乱公共场所秩序、交通秩序罪",
                    level: 4,
                    index: "291.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "投放虚假危险物质罪",
                    level: 4,
                    index: "291.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "编造、故意传播虚假恐怖信息罪",
                    level: 4,
                    index: "291.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "编造、故意传播虚假信息罪",
                    level: 4,
                    index: "291.4",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "高空抛物罪",
                    level: 4,
                    index: "291.5",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 292,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "聚众斗殴罪",
                    level: 4,
                    index: "292.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 293,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "寻衅滋事罪",
                    level: 4,
                    index: "293.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "催收非法债务罪",
                    level: 4,
                    index: "293.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 294,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织、领导、参加黑社会性质组织罪",
                    level: 4,
                    index: "294.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "入境发展黑社会组织罪",
                    level: 4,
                    index: "294.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "包庇、纵容黑社会性质组织罪",
                    level: 4,
                    index: "294.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 295,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "传授犯罪方法罪",
                    level: 4,
                    index: "295.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 296,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法集会、游行、示威罪",
                    level: 4,
                    index: "296.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 297,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法携带武器、管制刀具、爆炸物参加集会、游行、示威罪",
                    level: 4,
                    index: "297.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 298,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "破坏集会、游行、示威罪",
                    level: 4,
                    index: "298.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 299,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "侮辱国旗、国徽、国歌罪",
                    level: 4,
                    index: "299.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "侵害英雄烈士名誉、荣誉罪",
                    level: 4,
                    index: "299.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 300,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织、利用会道门、邪教组织、利用迷信破坏法律实施罪",
                    level: 4,
                    index: "300.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织、利用会道门、邪教组织、利用迷信致人重伤、死亡罪",
                    level: 4,
                    index: "300.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 301,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "聚众淫乱罪",
                    level: 4,
                    index: "301.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "引诱未成年人聚众淫乱罪",
                    level: 4,
                    index: "301.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 302,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "盗窃、侮辱、故意毁坏尸体、尸骨、骨灰罪",
                    level: 4,
                    index: "302.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 303,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "赌博罪",
                    level: 4,
                    index: "303.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "开设赌场罪",
                    level: 4,
                    index: "303.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织参与国（境）外赌博罪",
                    level: 4,
                    index: "303.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 304,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "故意延误投递邮件罪",
                    level: 4,
                    index: "304.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 2,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 305,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "伪证罪",
                    level: 4,
                    index: "305.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 306,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "辩护人、诉讼代理人毁灭证据、伪造证据、妨害作证罪",
                    level: 4,
                    index: "306.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 307,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害作证罪",
                    level: 4,
                    index: "307.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "帮助毁灭、伪造证据罪",
                    level: 4,
                    index: "307.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "虚假诉讼罪",
                    level: 4,
                    index: "307.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 308,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "打击报复证人罪",
                    level: 4,
                    index: "308.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "泄露不应公开的案件信息罪",
                    level: 4,
                    index: "308.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "披露、报道不应公开的案件信息罪",
                    level: 4,
                    index: "308.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 309,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "扰乱法庭秩序罪",
                    level: 4,
                    index: "309.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 310,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "窝藏、包庇罪",
                    level: 4,
                    index: "310.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 311,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "拒绝提供间谍犯罪、恐怖主义犯罪、极端主义犯罪证据罪",
                    level: 4,
                    index: "311.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 312,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "掩饰、隐瞒犯罪所得、犯罪所得收益罪",
                    level: 4,
                    index: "312.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 313,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "拒不执行判决、裁定罪",
                    level: 4,
                    index: "313.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 314,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法处置查封、扣押、冻结的财产罪",
                    level: 4,
                    index: "314.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 315,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "破坏监管秩序罪",
                    level: 4,
                    index: "315.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 316,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "脱逃罪",
                    level: 4,
                    index: "316.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "劫夺被押解人员罪",
                    level: 4,
                    index: "316.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 317,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织越狱罪",
                    level: 4,
                    index: "317.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "暴动越狱罪",
                    level: 4,
                    index: "317.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "聚众持械劫狱罪",
                    level: 4,
                    index: "317.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 3,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 318,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织他人偷越国（边）境罪",
                    level: 4,
                    index: "318.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 319,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "骗取出境证件罪",
                    level: 4,
                    index: "319.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 320,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "提供伪造、变造的出入境证件罪",
                    level: 4,
                    index: "320.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "出售出入境证件罪",
                    level: 4,
                    index: "320.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 321,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "运送他人偷越国（边）境罪",
                    level: 4,
                    index: "321.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 322,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "偷越国（边）境罪",
                    level: 4,
                    index: "322.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 323,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "破坏界碑、界桩罪",
                    level: 4,
                    index: "323.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "破坏永久性测量标志罪",
                    level: 4,
                    index: "323.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 4,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 324,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "故意损毁文物罪",
                    level: 4,
                    index: "324.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "故意损毁名胜古迹罪",
                    level: 4,
                    index: "324.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "过失损毁文物罪",
                    level: 4,
                    index: "324.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 325,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法向外国人出售、赠送珍贵文物罪",
                    level: 4,
                    index: "325.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 326,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "倒卖文物罪",
                    level: 4,
                    index: "326.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 327,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法出售、私赠文物藏品罪",
                    level: 4,
                    index: "327.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 328,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "盗掘古文化遗址、古墓葬罪",
                    level: 4,
                    index: "328.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "盗掘古人类化石、古脊椎动物化石罪",
                    level: 4,
                    index: "328.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 329,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "抢夺、窃取国有档案罪",
                    level: 4,
                    index: "329.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "擅自出卖、转让国有档案罪",
                    level: 4,
                    index: "329.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 5,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 330,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害传染病防治罪",
                    level: 4,
                    index: "330.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 331,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "传染病菌种、毒种扩散罪",
                    level: 4,
                    index: "331.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 332,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害国境卫生检疫罪",
                    level: 4,
                    index: "332.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 333,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法组织卖血罪",
                    level: 4,
                    index: "333.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "强迫卖血罪",
                    level: 4,
                    index: "333.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 334,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法采集、供应血液、制作、供应血液制品罪",
                    level: 4,
                    index: "334.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "采集、供应血液、制作、供应血液制品事故罪",
                    level: 4,
                    index: "334.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法采集人类遗传资源、走私人类遗传资源材料罪",
                    level: 4,
                    index: "334.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 335,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "医疗事故罪",
                    level: 4,
                    index: "335.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 336,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法行医罪",
                    level: 4,
                    index: "336.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法进行节育手术罪",
                    level: 4,
                    index: "336.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法植入基因编辑、克隆胚胎罪",
                    level: 4,
                    index: "336.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 337,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "妨害动植物防疫、检疫罪",
                    level: 4,
                    index: "337.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 6,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 338,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "污染环境罪",
                    level: 4,
                    index: "338.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 339,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法处置进口的固体废物罪",
                    level: 4,
                    index: "339.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "擅自进口固体废物罪",
                    level: 4,
                    index: "339.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 340,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法捕捞水产品罪",
                    level: 4,
                    index: "340.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 341,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "危害珍贵、濒危野生动物罪",
                    level: 4,
                    index: "341.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法狩猎罪",
                    level: 4,
                    index: "341.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法猎捕、收购、运输、出售陆生野生动物罪",
                    level: 4,
                    index: "341.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 342,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法占用农用地罪",
                    level: 4,
                    index: "342.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "破坏自然保护地罪",
                    level: 4,
                    index: "342.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 343,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法采矿罪",
                    level: 4,
                    index: "343.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "破坏性采矿罪",
                    level: 4,
                    index: "343.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 344,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "危害国家重点保护植物罪",
                    level: 4,
                    index: "344.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法引进、释放、丢弃外来入侵物种罪",
                    level: 4,
                    index: "344.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 345,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "盗伐林木罪",
                    level: 4,
                    index: "345.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "滥伐林木罪",
                    level: 4,
                    index: "345.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "非法收购、运输盗伐、滥伐的林木罪",
                    level: 4,
                    index: "345.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 346,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "346.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 7,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 347,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "走私、贩卖、运输、制造毒品罪",
                    level: 4,
                    index: "347.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 348,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法持有毒品罪",
                    level: 4,
                    index: "348.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 349,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "包庇毒品犯罪分子罪",
                    level: 4,
                    index: "349.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "窝藏、转移、隐瞒毒品、毒赃罪",
                    level: 4,
                    index: "349.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 350,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法生产、买卖、运输制毒物品、走私制毒物品罪",
                    level: 4,
                    index: "350.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 351,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法种植毒品原植物罪",
                    level: 4,
                    index: "351.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 352,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法买卖、运输、携带、持有毒品原植物种子、幼苗罪",
                    level: 4,
                    index: "352.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 353,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "引诱、教唆、欺骗他人吸毒罪",
                    level: 4,
                    index: "353.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "强迫他人吸毒罪",
                    level: 4,
                    index: "353.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 354,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "容留他人吸毒罪",
                    level: 4,
                    index: "354.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 355,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "非法提供麻醉药品、精神药品罪",
                    level: 4,
                    index: "355.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "妨害兴奋剂管理罪",
                    level: 4,
                    index: "355.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 356,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "毒品犯罪的再犯",
                    level: 4,
                    index: "356.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 357,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "毒品的含义及毒品数量的计算",
                    level: 4,
                    index: "357.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 8,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 358,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织卖淫罪",
                    level: 4,
                    index: "358.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "强迫卖淫罪",
                    level: 4,
                    index: "358.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "协助组织卖淫罪",
                    level: 4,
                    index: "358.3",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 359,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "引诱、容留、介绍卖淫罪",
                    level: 4,
                    index: "359.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "引诱幼女卖淫罪",
                    level: 4,
                    index: "359.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 360,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "传播性病罪",
                    level: 4,
                    index: "360.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 361,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "旅馆业、饮食服务业等单位组织、强迫、引诱、容留、介绍卖淫的处罚规定",
                    level: 4,
                    index: "361.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 362,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "查处卖淫、嫖娼活动中通风报信的处罚规定",
                    level: 4,
                    index: "362.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
          {
            name: "",
            level: 2,
            index: 9,
            tags: "subchatper",
            kind: "CrimialLaw",
            children: [
              {
                name: "",
                level: 3,
                index: 363,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "制作、复制、出版、贩卖、传播淫秽物品牟利罪",
                    level: 4,
                    index: "363.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "为他人提供书号出版淫秽书刊罪",
                    level: 4,
                    index: "363.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 364,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "传播淫秽物品罪",
                    level: 4,
                    index: "364.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                  {
                    name: "组织播放淫秽音像制品罪",
                    level: 4,
                    index: "364.2",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 365,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "组织淫秽表演罪",
                    level: 4,
                    index: "365.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 366,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "单位犯罪的规定",
                    level: 4,
                    index: "366.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
              {
                name: "",
                level: 3,
                index: 367,
                kind: "CrimialLaw",
                tag: "item",
                children: [
                  {
                    name: "淫秽物品的含义",
                    level: 4,
                    index: "367.1",
                    kind: "CrimialLaw",
                    tag: "details",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "危害国防利益罪",
        level: 1,
        index: 7,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "阻碍军人执行职务罪",
            level: 2,
            index: 368,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "阻碍军人执行职务罪",
                level: 3,
                index: "368.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "阻碍军事行动罪",
                level: 3,
                index: "368.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "破坏武器装备、军事设施、军事通信罪",
            level: 2,
            index: 369,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "破坏武器装备、军事设施、军事通信罪",
                level: 3,
                index: "369.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失损坏武器装备、军事设施、军事通信罪",
                level: 3,
                index: "369.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "故意提供不合格武器装备、军事设施罪",
            level: 2,
            index: 370,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "故意提供不合格武器装备、军事设施罪",
                level: 3,
                index: "370.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失提供不合格武器装备、军事设施罪",
                level: 3,
                index: "370.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "聚众冲击军事禁区罪",
            level: 2,
            index: 371,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "聚众冲击军事禁区罪",
                level: 3,
                index: "371.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "聚众扰乱军事管理区秩序罪",
                level: 3,
                index: "371.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "冒充军人招摇撞骗罪",
            level: 2,
            index: 372,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "煽动军人逃离部队罪",
            level: 2,
            index: 373,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "煽动军人逃离部队罪",
                level: 3,
                index: "373.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "雇用逃离部队军人罪",
                level: 3,
                index: "373.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "接送不合格兵员罪",
            level: 2,
            index: 374,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "伪造、变造、买卖武装部队公文、证件、印章罪",
            level: 2,
            index: 375,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "伪造、变造、买卖武装部队公文、证件、印章罪",
                level: 3,
                index: "375.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "盗窃、抢夺武装部队公文、证件、印章罪",
                level: 3,
                index: "375.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法生产、买卖武装部队制式服装罪",
                level: 3,
                index: "375.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "伪造、盗窃、买卖、非法提供、非法使用武装部队专用标志罪",
                level: 3,
                index: "375.4",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "战时拒绝、逃避征召、军事训练罪",
            level: 2,
            index: 376,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "战时拒绝、逃避征召、军事训练罪",
                level: 3,
                index: "376.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "战时拒绝、逃避服役罪",
                level: 3,
                index: "376.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "战时故意提供虚假敌情罪",
            level: 2,
            index: 377,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时造谣扰乱军心罪",
            level: 2,
            index: 378,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时窝藏逃离部队军人罪",
            level: 2,
            index: 379,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时拒绝、故意延误军事订货罪",
            level: 2,
            index: 380,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时拒绝军事征收、征用罪",
            level: 2,
            index: 381,
            kind: "CrimialLaw",
            tag: "item",
          },
        ],
      },
      {
        name: "贪污贿赂罪",
        level: 1,
        index: 8,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "贪污罪",
            level: 2,
            index: 382,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "对贪污罪的处罚",
            level: 2,
            index: 383,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "挪用公款罪",
            level: 2,
            index: 384,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "受贿罪",
            level: 2,
            index: 385,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "对受贿罪的处罚",
            level: 2,
            index: 386,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "单位受贿罪",
            level: 2,
            index: 387,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "斡旋受贿的处罚",
            level: 2,
            index: 388,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "斡旋受贿的处罚",
                level: 3,
                index: "388.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "利用影响力受贿罪",
                level: 3,
                index: "388.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "行贿罪",
            level: 2,
            index: 389,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "对行贿罪的处罚",
            level: 2,
            index: 390,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "对行贿罪的处罚",
                level: 3,
                index: "390.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "对有影响力的人行贿罪",
                level: 3,
                index: "390.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "对单位行贿罪",
            level: 2,
            index: 391,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "介绍贿赂罪",
            level: 2,
            index: 392,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "单位行贿罪",
            level: 2,
            index: 393,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "国家工作人员在公务活动、对外交往中违规收受礼物不交公的处罚",
            level: 2,
            index: 394,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "巨额财产来源不明罪",
            level: 2,
            index: 395,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "巨额财产来源不明罪",
                level: 3,
                index: "395.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "隐瞒境外存款罪",
                level: 3,
                index: "395.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "私分国有资产罪",
            level: 2,
            index: 396,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "私分国有资产罪",
                level: 3,
                index: "396.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "私分罚没财物罪",
                level: 3,
                index: "396.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
        ],
      },
      {
        name: "渎职罪",
        level: 1,
        index: 9,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "滥用职权罪",
            level: 2,
            index: 397,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "滥用职权罪",
                level: 3,
                index: "397.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "玩忽职守罪",
                level: 3,
                index: "397.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "故意泄露国家秘密罪",
            level: 2,
            index: 398,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "故意泄露国家秘密罪",
                level: 3,
                index: "398.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失泄露国家秘密罪",
                level: 3,
                index: "398.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "徇私枉法罪",
            level: 2,
            index: 399,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "徇私枉法罪",
                level: 3,
                index: "399.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "民事、行政枉法裁判罪",
                level: 3,
                index: "399.2",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "执行判决、裁定失职罪",
                level: 3,
                index: "399.3",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "执行判决、裁定滥用职权罪",
                level: 3,
                index: "399.4",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "枉法仲裁罪",
                level: 3,
                index: "399.5",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "私放在押人员罪",
            level: 2,
            index: 400,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "私放在押人员罪",
                level: 3,
                index: "400.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "失职致使在押人员脱逃罪",
                level: 3,
                index: "400.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "徇私舞弊减刑、假释、暂予监外执行罪",
            level: 2,
            index: 401,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "徇私舞弊不移交刑事案件罪",
            level: 2,
            index: 402,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "滥用管理公司、证券职权罪",
            level: 2,
            index: 403,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "徇私舞弊不征、少征税款罪",
            level: 2,
            index: 404,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "徇私舞弊发售发票、抵扣税款、出口退税罪",
            level: 2,
            index: 405,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "徇私舞弊发售发票、抵扣税款、出口退税罪",
                level: 3,
                index: "405.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "违法提供出口退税凭证罪",
                level: 3,
                index: "405.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "国家机关工作人员签订、履行合同失职被骗罪",
            level: 2,
            index: 406,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "违法发放林木采伐许可证罪",
            level: 2,
            index: 407,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "环境监管失职罪",
            level: 2,
            index: 408,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "环境监管失职罪",
                level: 3,
                index: "408.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "食品、药品监管渎职罪",
                level: 3,
                index: "408.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "传染病防治失职罪",
            level: 2,
            index: 409,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "非法批准征收、征用、占用土地罪",
            level: 2,
            index: 410,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法批准征收、征用、占用土地罪",
                level: 3,
                index: "410.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "非法低价出让国有土地使用权罪",
                level: 3,
                index: "410.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "放纵走私罪",
            level: 2,
            index: 411,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "商检徇私舞弊罪",
            level: 2,
            index: 412,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "商检徇私舞弊罪",
                level: 3,
                index: "412.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "商检失职罪",
                level: 3,
                index: "412.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "动植物检疫徇私舞弊罪",
            level: 2,
            index: 413,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "动植物检疫徇私舞弊罪",
                level: 3,
                index: "413.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "动植物检疫失职罪",
                level: 3,
                index: "413.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "放纵制售伪劣商品犯罪行为罪",
            level: 2,
            index: 414,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "办理偷越国（边）境人员出入境证件罪",
            level: 2,
            index: 415,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "办理偷越国（边）境人员出入境证件罪",
                level: 3,
                index: "415.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "放行偷越国（边）境人员罪",
                level: 3,
                index: "415.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "不解救被拐卖、绑架妇女、儿童罪",
            level: 2,
            index: 416,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "不解救被拐卖、绑架妇女、儿童罪",
                level: 3,
                index: "416.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "阻碍解救被拐卖、绑架妇女、儿童罪",
                level: 3,
                index: "416.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "帮助犯罪分子逃避处罚罪",
            level: 2,
            index: 417,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "招收公务员、学生徇私舞弊罪",
            level: 2,
            index: 418,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "失职造成珍贵文物损毁、流失罪",
            level: 2,
            index: 419,
            kind: "CrimialLaw",
            tag: "item",
          },
        ],
      },
      {
        name: "军人违反职责罪",
        level: 1,
        index: 10,
        tag: "chatper",
        kind: "CrimialLaw",
        children: [
          {
            name: "军人违反职责罪的概念",
            level: 2,
            index: 420,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时违抗命令罪",
            level: 2,
            index: 421,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "隐瞒、谎报军情罪",
            level: 2,
            index: 422,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "隐瞒、谎报军情罪",
                level: 3,
                index: "422.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "拒传、假传军令罪",
                level: 3,
                index: "422.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "投降罪",
            level: 2,
            index: 423,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时临阵脱逃罪",
            level: 2,
            index: 424,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "擅离、玩忽军事职守罪",
            level: 2,
            index: 425,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "阻碍执行军事职务罪",
            level: 2,
            index: 426,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "指使部属违反职责罪",
            level: 2,
            index: 427,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "违令作战消极罪",
            level: 2,
            index: 428,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "拒不救援友邻部队罪",
            level: 2,
            index: 429,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "军人叛逃罪",
            level: 2,
            index: 430,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "非法获取军事秘密罪",
            level: 2,
            index: 431,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "非法获取军事秘密罪",
                level: 3,
                index: "431.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "为境外窃取、刺探、收买、非法提供军事秘密罪",
                level: 3,
                index: "431.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "故意泄露军事秘密罪",
            level: 2,
            index: 432,
            kind: "CrimialLaw",
            tag: "item",
            children: [
              {
                name: "故意泄露军事秘密罪",
                level: 3,
                index: "432.1",
                kind: "CrimialLaw",
                tag: "details",
              },
              {
                name: "过失泄露军事秘密罪",
                level: 3,
                index: "432.2",
                kind: "CrimialLaw",
                tag: "details",
              },
            ],
          },
          {
            name: "战时造谣惑众罪",
            level: 2,
            index: 433,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时自伤罪",
            level: 2,
            index: 434,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "逃离部队罪",
            level: 2,
            index: 435,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "武器装备肇事罪",
            level: 2,
            index: 436,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "擅自改变武器装备编配用途罪",
            level: 2,
            index: 437,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "盗窃、抢夺武器装备、军用物资罪",
            level: 2,
            index: 438,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "非法出卖、转让武器装备罪",
            level: 2,
            index: 439,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "遗弃武器装备罪",
            level: 2,
            index: 440,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "遗失武器装备罪",
            level: 2,
            index: 441,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "擅自出卖、转让军队房地产罪",
            level: 2,
            index: 442,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "虐待部属罪",
            level: 2,
            index: 443,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "遗弃伤病军人罪",
            level: 2,
            index: 444,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时拒不救治伤病军人罪",
            level: 2,
            index: 445,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "战时残害居民、掠夺居民财物罪",
            level: 2,
            index: 446,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "私放俘虏罪",
            level: 2,
            index: 447,
            kind: "CrimialLaw",
            tag: "item",
          },
          {
            name: "虐待俘虏罪",
            level: 2,
            index: 448,
            kind: "CrimialLaw",
            tag: "item",
          },
        ],
      },
    ],
  },
  buildTree: function () {
    let tree = [];
    this.Kinds.items.forEach((element) => {
      let node = {};
      this.buildNode(node, element);
      tree.push(node);
    });
    return tree;
  },
  buildNode: function (node, kind) {
    node.id = kind.index;
    node.name = kind.name;
    node.children = [];
    if (kind.children != null && kind.children.length > 0) {
      kind.children.forEach((element) => {
        let child = {};
        this.buildNode(child, element);
        node.children.push(node);
      });
    }
  },
  buildPaint: function () {
    let tree = [];
    this.Kinds.items.forEach((element) => {
      this.buildPaintNode(tree, element);
    });
    return tree;
  },
  buildPaintNode: function (tree, kind) {
    let node = {};
    node.value = kind.index;
    node.text = kind.index + ". " + kind.name;
    if (kind.children != null && kind.children.length > 0) {
      kind.children.forEach((element) => {
        this.buildPaintNode(tree, element);
      });
    } else {
      tree.push(node);
    }
  },
};
