var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"width":"100%"},attrs:{"elevation":_vm.elevation}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"show-select":_vm.select,"single-select":_vm.singleSelect,"items":_vm.items,"dense":_vm.dense,"no-data-text":_vm.$t('no_data_text'),"hide-default-footer":""},on:{"click:row":_vm.clickRow,"dblclick:row":_vm.dbclickRow},scopedSlots:_vm._u([(!_vm.hiddenHeader)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.executeOp(undefined, 'insert')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.refresh()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-refresh ")])],1)],1)]},proxy:true}:null,_vm._l((_vm.itemSchemas),function(h,index){return {key:("item." + (h.value)),fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [(h.hasTemplate)?_vm._t(h.template,null,{"value":value,"header":header,"item":item}):_vm._e(),(!h.hasTemplate)?_c('div',{key:index},[_vm._v(" "+_vm._s(_vm.formatValue(header, item, value))+" ")]):_vm._e()]}}}),{key:("item." + _vm.actionName),fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{attrs:{"align":"center","justify-space-around":"","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.options != undefined ? (_vm.options.showUpdate == undefined ? true :  _vm.options.showUpdate) : true)?_c('v-btn',{staticClass:"div-space",class:hover ? '' : 'd-hidden',attrs:{"fab":"","outlined":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.executeOp(item, 'update')}}},[_c('v-icon',[_vm._v("mdi-lead-pencil")])],1):_vm._e(),(_vm.options != undefined ? (_vm.options.showDelete == undefined ? true :  _vm.options.showDelete) : true)?_c('v-btn',{staticClass:"div-space",class:hover ? '' : 'd-hidden',attrs:{"fab":"","outlined":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.executeOp(item, 'delete')}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1):_vm._e(),_vm._t("expanded-action",null,{"item":item,"hover":hover})],2)],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}}),[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":this.pagination.pageCount,"total-visible":7,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }