<template>
  <v-card>
    <v-card-title>
      <v-btn-toggle v-model="reportType" mandatory dense @change="refresh">
        <v-btn text>
          {{ $t("title_daily_report") }}
        </v-btn>
        <v-btn text>
          {{ $t("title_month_report") }}
        </v-btn>
      </v-btn-toggle>

      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        dark
        color="primary"
        small
        v-on:click.stop="refresh()"
      >
        <v-icon dark> mdi-refresh </v-icon>
      </v-btn>
    </v-card-title>
    <div ref="chartpart" id="chart-count" class="col-item-6 chart-item"></div>
  </v-card>
</template>

<script>
import { LayoutService, TYPE_HEIGHT } from "../common/layout_service";
import moment from "moment";
const PageRows = 12;
const layoutService = new LayoutService();

export default {
  props: {
    belongType: {},
    belongId: {},
  },
  data() {
    return {
      reportType: 1,
      chart: "",
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: ["待接单", "待付款", "处理中", "完成", "退款中", "关闭"],
        },
        xAxis: [
          {
            type: "category",
            data: [],
            name: "月",
            axisLabel: {
              formatter: "{value}月",
            },
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "个数",
            axisLabel: {
              formatter: "{value} 个",
            },
          },
        ],
        series: [
          {
            name: "待接单",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "待付款",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "处理中",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "完成",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "退款中",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
          {
            name: "关闭",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 个";
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    this.updateText();
    this.initLayout();
    this.chart = this.$echarts.init(
      document.getElementById("chart-count"),
      "light"
    );
    this.refresh();
  },
  methods: {
    updateText() {
      // let utils = new Utils(this);
    },
    initLayout() {
      let containerHeight =
        window.innerHeight -
        document.getElementsByClassName("v-toolbar")[0].offsetHeight -
        12 * 8;
      layoutService.initLayout(
        "chart-count",
        containerHeight,
        PageRows,
        TYPE_HEIGHT
      );
    },
    refresh() {
      this.fillData();
    },
    fillData() {
      let _this = this;
      let xAxisData = [];
      let xAxisDisplay = [];
      let type = "day";
      if (_this.reportType == 0) {
        for (let index = 29; index >= 0; index--) {
          xAxisDisplay.push(moment().subtract(index, "days").format("D"));
          xAxisData.push(moment().subtract(index, "days").format("YYYY-MM-DD"));
        }
        type = "day";
        _this.option.xAxis[0].name = "天";
        _this.option.xAxis[0].axisLabel.formatter = "{value}日";
      } else if (_this.reportType == 1) {
        for (let index = 11; index >= 0; index--) {
          xAxisDisplay.push(moment().subtract(index, "months").format("M"));
          xAxisData.push(moment().subtract(index, "months").format("M"));
        }
        type = "month";
        _this.option.xAxis[0].name = "月";
        _this.option.xAxis[0].axisLabel.formatter = "{value}月";
      }

      _this.option.xAxis[0].data = xAxisDisplay;
      this.get("/api/statistic/order/" + type, {
        callback: function (data) {
          let count = xAxisData.length;
          _this.option.series[0].data = new Array(count).fill(0);
          _this.option.series[1].data = new Array(count).fill(0);
          _this.option.series[2].data = new Array(count).fill(0);
          _this.option.series[3].data = new Array(count).fill(0);
          _this.option.series[4].data = new Array(count).fill(0);
          _this.option.series[5].data = new Array(count).fill(0);
          _this.pushValue(_this, xAxisData, data, 0, "new");
          _this.pushValue(_this, xAxisData, data, 1, ["accept", "prepay", "approve_pay", "reject_pay"]);
          _this.pushValue(_this, xAxisData, data, 2, "process");
          _this.pushValue(_this, xAxisData, data, 3, ["finish", "feedback"]);
          _this.pushValue(_this, xAxisData, data, 4, "pay_back");
          _this.pushValue(_this, xAxisData, data, 5, "close");
          _this.chart.setOption(_this.option);
        },
      });
    },
    pushValue(_this, xAxisData, data, sIndex, name) {
      for (let index = 0; index < xAxisData.length; index++) {
        const xName = xAxisData[index];
        let value = 0;
        let length = data.data.length;
        for (let dataIdx = 0; dataIdx < length; dataIdx++) {
          let entity = data.data[dataIdx];
          if (entity.name == xName) {
            if (Array.isArray(name)) {
              name.forEach(key => {
                if (entity.nestedName == key) {
                  value += entity.value;  
                  return;
                }
              });
            } else if (entity.nestedName == name) {
              value = entity.value;      
              break;        
            }
          }
        }
        _this.option.series[sIndex].data[index] += value;
      }
    },
  },
};
</script>

<style scoped>
.chart-item {
  height: 500px;
}
</style>
