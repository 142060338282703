<template>
  <entity-view
    :item-schemas="itemSchemas"
    :readonly="readonly"
    :items="items"
    :title="$t('payment')"
    :pagination="pagination"
    :externalActions="externalActions"
    :options="options"
    @items-refresh="itemsRefresh"
    @item-action="itemAction"
    @page-change="pageChange"
    @double-click-row="clickRow"
  >
    <template v-slot:icon="item">
      <v-icon v-if="item.value" small color="success">
        mdi-check-circle
      </v-icon>
      <v-icon v-if="!item.value" small color="error">
        mdi-close-circle
      </v-icon>
    </template>
  </entity-view>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import PayementTypeDropdown from "../common/payment_type";
import PayementStatusDropdown from "../common/payment_status";
import CommonTypes from "../common/common_type";

export default {
  components: { EntityView },
  props: {
    orderId: {},
    status: {}
  },
  data: () => ({
    title: "Lawyer",
    select: true,
    expendOpen: false,
    readonly: true,
    selectedIdx: 0,
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "paymentNo",
        value: "paymentNo",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "type",
        value: "type",
        type: "dropdown",
        dropdown: PayementTypeDropdown.values,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
        hasfilter: true,
        condition: {},
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "lawyerName",
        value: "lawyerName",
        type: "string",
        hasfilter: true,
        condition: {},
      },
      {
        name: "isRefund",
        value: "isRefund",
        type: "dropdown",
        localized: true,
        dropdown: CommonTypes.booleans,
        hasfilter: true,
        condition: {},
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        dropdown: PayementStatusDropdown.values,
        localized: true,
        hasfilter: true,
        condition: {},
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "datetime",
        hasfilter: true,
        condition: {},
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: false,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let postData = {
        pagination: this.pagination,
        criterias: [],
      };
      if (postData.criterias != undefined) {
        if (this.orderId != undefined) {
          postData.criterias.push({
            operator: "=",
            type: "string",
            value: this.orderId,
            column: "order_id",
            condition: "and",
          });
        }

        if (this.status != undefined) {
          postData.criterias.push({
            operator: "=",
            type: "string",
            value: this.status,
            column: "status",
            condition: "and",
          });
        }
      }
      this.post("/api/payments?role=admin", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.$router.push({
        name: "PaymentDetail",
        params: { id: item.id + "" },
      });
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name) {
      if (name === "insert") {
        this.itemCreate();
      }
    },
    selectIdx(idx) {
      this.selectedIdx = idx;
      this.refresh();
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
    "en": {
    "c_enable": "Is Online",
    "c_userId": "UserId"
  },
  "cn": {
    "c_enable": "是否在线",
    "c_userId": "客户ID"
  }
}
</i18n>
