<template>
  <v-card class="form-container" ref="genernal">
    <v-card-title>
      <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="save($refs.mainView)"
      >
        {{ $t("save") }}
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <form-view
        :itemSchemas="itemSchemas"
        :readonly="!editMode"
        @item-action="itemAction"
        ref="mainView"
        :colPerRow="colPerRow"
      ></form-view>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import FormView from "../components/FormView.vue";
import showDialog from "./show-dialog.js";

export default {
  components: { FormView },
  data: () => ({
    title: "ResetPassword",
    entity: {},
    editMode: true,
    dialog: false,
    user: {},
    colPerRow: 2,
    itemSchemas: [
      {
        name: "password",
        value: "password",
        type: "password",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "newPassword",
        value: "newPassword",
        type: "password",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
      {
        name: "reNewPassword",
        value: "reNewPassword",
        type: "password",
      },
      {
        name: "HOLDER",
        value: "HOLDER",
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.getCurrentUser();
    this.$refs.mainView.loadEntity(this.entity);
  },
  computed: {
  },
  methods: {
    getCurrentUser() {
      let _this = this;
      let user = this.$store.getters.getUserInfo;
      if (user == undefined) {
        this.get("/api/user/current", {
          callback: function (data) {
            _this.user = data.data;
            _this.setUserInfo(data.data);
          },
        });
      } else {
        _this.user = user;
      }
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    save(refView) {
      this.saveInternal(refView);
    },
    saveInternal(refView) {
      let _this = this;
      let entity = refView.commitEntity();
      entity["username"] = _this.user.email;
      let option = {
        callback: function (data) {
          _this.entity = data.data;
        },
      };
      this.post("/auth/resetpassword/", entity, option);
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
    selectValueClose(isCancel, selections) {
      console.log("Selection Dialog is closed. IsCancel:" + isCancel);
      console.log("                            Selections:" + selections);
    },
  },
  ...mapMutations({
    setUserInfo: "setUserInfo",
  }),
};
</script>

