<template>
  <v-container>
    <v-navigation-drawer app class="nav-drawer">
      <template v-slot:prepend>
        <!-- <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ entity.fullName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, i) in tagList"
            :key="i"
            @click="$vuetify.goTo($refs[item.tag])"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <div class="overflow-y-auto">
        <v-row class="ma-4">
          <v-card class="form-container">
            <v-card-title>
              <v-btn
                color="primary mr-4"
                fab
                small
                @click="$router.go(-1)"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <breadcrumb-ex></breadcrumb-ex>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-row>
        <v-row class="ma-4">
          <v-card class="form-container" ref="main">
            <v-card-title>
              <div class="text-h6 ml-4">{{ $t("main_title") }}</div>
              <v-spacer></v-spacer>
              {{ $t("status") + ": " + this.status }}
              <v-spacer></v-spacer>
              <v-btn v-if="entity['status'] == 'waiting' || entity['status'] == 'approved'" color="primary" @click="approve()">
                {{ $t("approve") }}
              </v-btn>
              <v-btn v-if="entity['status'] == 'waiting' || entity['status'] == 'approved'" class="warning ml-4" @click="reject()">
                {{ $t("reject") }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <form-view
                :itemSchemas="itemSchemas"
                :readonly="!editMode"
                :colPerRow="colPerRow"
                @item-action="itemAction"
                ref="mainView"
              ></form-view>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="ma-4" ref="history">
          <draft-lawyer-history-part
            ref="historyList"
            :draft-lawyer-id="id"
          ></draft-lawyer-history-part>
        </v-row>
      </div>
      <v-dialog v-model="dialog" persistent max-width="1200">
        <v-card>
          <v-card-title class="text-h5 ml-6">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text>
            <form-view
              :itemSchemas="dialogSchemas"
              :readonly="dialogReadonly"
              :reverse="false"
              :colPerRow="colPerRow"
              ref="commenView"
            >
            </form-view>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="
                dialog = false;
                commit();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import FormView from "../components/FormView.vue";
import DraftLawyerHistoryPart from "./DraftLawyerHistoryPart.vue";
import reviewAction from "../common/review_action";
import Utils from "../common/utils";
import showDialog from "./show-dialog.js";
import BreadcrumbEx from '../components/BreadcrumbEx.vue';

export default {
  components: { FormView, DraftLawyerHistoryPart, BreadcrumbEx },
  props: {
    id: {},
  },
  data: () => ({
    title: "DraftLawyer",
    entity: {},
    editMode: false,
    dialogReadonly: false,
    dialog: false,
    colPerRow: 1,
    isCreate: false,
    dialogTitle: "",
    isApprove: false,
    status: "",
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    tagList: [
      {
        name: "main",
        text: "main",
        tag: "main",
      },
      {
        name: "history",
        text: "history",
        tag: "history",
      },
    ],
    
    itemSchemas: [
      {
        name: "name",
        value: "name",
        type: "string",
        readonly: true,
      },
      {
        name: "imageUrl",
        value: "imageUrl",
        type: "image",
        required: true,
        getURL: function (value) {
          return process.env.VUE_APP_API_URL + value;
        },
      },
      {
        name: "office",
        value: "office",
        type: "string",
        readonly: true,
      },
      {
        name: "licenseNo",
        value: "licenseNo",
        type: "string",
        readonly: true,
      },
      {
        name: "licenseImage",
        value: "licenseImage",
        type: "image",
        readonly: true,
        getURL: function (value) {
          return process.env.VUE_APP_API_URL + value;
        },
      },
      {
        name: "location",
        value: "location",
        type: "string",
        required: true,
      },
      {
        name: "fields",
        value: "fields",
        type: "string",
      },
      {
        name: "level",
        value: "level",
        type: "string",
      },
      {
        name: "graduatedFrom",
        value: "graduatedFrom",
        type: "string",
      },
      {
        name: "major",
        value: "major",
        type: "string",
      },
      {
        name: "degree",
        value: "degree",
        type: "string",
      },
      {
        name: "otherEducation",
        value: "otherEducation",
        type: "text",
      },
      {
        name: "workDaysTotal",
        value: "workDaysTotal",
        type: "string",
      },
      {
        name: "workDaysGjf",
        value: "workDaysGjf",
        type: "string",
      },
      {
        name: "workDaysXz",
        value: "workDaysXz",
        type: "string",
      },
      {
        name: "workDaysKy",
        value: "workDaysKy",
        type: "string",
      },
      {
        name: "workDaysQy",
        value: "workDaysQy",
        type: "string",
      },
      {
        name: "workDaysLawyer",
        value: "workDaysLawyer",
        type: "string",
      },
      {
        name: "workDaysOther",
        value: "workDaysOther",
        type: "string",
      },
      {
        name: "honor",
        value: "honor",
        type: "text",
      },
      {
        name: "cases",
        value: "cases",
        type: "text",
      },
    ],
    dialogSchemas: [
      {
        name: "comment",
        value: "comment",
        type: "text",
        flex: 2,
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
    this.updateTags();
    this.updateComponent();
    this.isCreate = this.id == undefined;
    console.log("DraftLawyer Id: " + this.id);

  },
  computed: {},
  methods: {
    updateTags() {
      this.tagList.forEach((tag) => {
        if (tag.name != undefined) {
          tag.text = this.$t(tag.tag);
        }
      });
    },
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      this.localizeSchema(this.dialogSchemas);
    },
    updateComponent() {
      this.refresh();
    },
    refresh() {
      let _this = this;
      let utils = new Utils(this);
      if (this.id == undefined) {
        return;
      }
      this.get("/api/lawyer/" + this.id + "?isDraft=true", {
        callback: function (data) {
          _this.entity = data.data;
          if (_this.$refs.mainView != undefined) {
            _this.$refs.mainView.loadEntity(data.data);
          }
          _this.status = utils.getLocaleText(reviewAction.data, data.data["status"]);
          console.log("Status:" + data.data["status"]);
        },
      });
    },
    approve() {
      this.dialogTitle = this.$t("approve");
      this.isApprove = true;
      this.dialog = true;
    },
    reject() {
      this.dialogTitle = this.$t("reject");
      this.isApprove = false;
      this.dialog = true;
    },
    commit() {
      let entity = this.$refs.commenView.commitEntity();
      entity["approve"] = this.isApprove;
      let option = {
        callback: function () {},
      };
      this.post(`/api/lawyer/` + this.id + "/approve", entity, option);
    },
    itemAction(schema, value, callback) {
      showDialog(this, this.$refs.actionDialog, schema, value, callback);
    },
  },
};
</script>

<style>
.form-container {
  width: 100%;
}
.nav-drawer {
  margin-top: 64px;
}
</style>

<i18n>
{
  "en": {
    "c_firstName": "First Name",
    "c_lastName": "Last Name",
    "c_name": "Contact Name",
    "c_check": "Check",
    "c_phone": "Phone",
    "c_website": "WebSite",
    "c_industry": "Industry",
    "c_owner": "Owner",
    "c_updated": "Updated",
    "c_tax": "Tax Code",
    "c_revenue": "Annual Revenue",
    "c_address": "Address",
    "c_billAddress": "Bill Address",
    "c_shippingAddress": "Ship Address",
    "c_modifiedAt": "Modified At",
    "delete_title": "Delete",
    "delete_description": "Do you want to delete \"{0}\"?"
  },
  "cn": {
    "c_firstName": "名",
    "c_lastName": "姓",
    "c_name": "联系人",
    "c_check": "选中",
    "c_phone": "电话",
    "c_website": "网站",
    "c_industry": "行业",
    "c_owner": "所有人",
    "c_updated": "更新时间",
    "c_tax": "税号",
    "c_revenue": "年收入",
    "c_address": "地址",
    "c_billAddress": "账单地址",
    "c_shippingAddress": "邮寄地址",
    "c_modifiedAt": "修改时间",
    "delete_title": "删除",
    "delete_description": "是否要删除\"{0}\"?"
  }
}
</i18n>
