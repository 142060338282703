<template>
  <v-container fill-height class="d-flex justify-center">
    <v-card class="login-container" outlined>
      <v-card-title primary-title class="layout justify-center pt-15 px-15">
        <v-icon 
          x-large 
          color="orange" 
          left
        >
        mdi-account
        </v-icon>
        <span
          class="text-h4"
        >
        {{ $t("login_title") }}
        </span>
      </v-card-title>

      <v-card-text class="px-10">
        <v-alert 
          v-if="errorMessage != undefined" 
          v-model="alert" 
          text
          dense 
          dismissible
          type="error"
        >
          {{ $t("login_failue_text") }}
        </v-alert>
      </v-card-text>
     
      <v-card-text class="px-10 pb-10">

        <v-form>

          <v-row v-if="!showActiveUI">
            <v-text-field
              v-model="login.email"
              outlined
              required
              :label="$t('login_email_user')"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-text-field
              v-model="login.password"
              outlined
              :type="show ? 'text' : 'password'"
              :label="$t('login_password')"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
          </v-row>

          <v-row v-if="showActiveUI">
            <v-text-field
              v-model="login.repassword"
              outlined
              :type="show ? 'text' : 'password'"
              :label="$t('login_repassword')"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-center">
                <v-btn
                  color="primary"
                  @click="auth('user')"
                  >{{ showActiveUI ? $t("login_button_text_active_code") : $t("login_button_text") }}</v-btn
                >
            </v-col>
          </v-row>

          <div class="loading-overlay" v-if="loading">
            <v-progress-circular
              :width="3"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

        </v-form>

      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "App",
  data() {
    return {
      show: false,
      loading: false,
      alert: true,
      activeCode: null,
      showActiveUI: false,
      errorMessage: null,
      login: {
        email: "",
        password: "",
        repassword: "",
        deviceCode: "",
      },
    };
  },
  mounted() {
    this.activeCode = this.getQueryVariable("active_code");
    if (this.activeCode != null && this.activeCode != undefined) {
      this.showActiveUI = true;
      console.log("Show Activation UI. code:" + this.activeCode);
    } else {
      this.showActiveUI = false;
      console.log("Show Login UI");
    }
  },
  methods: {
    auth() {
      this.alert = true;
      let _this = this;
      if (this.showActiveUI) {
        let obj = {
          rePassword: this.login.repassword,
          password: this.login.password,
          code: this.activeCode
        };
        this.post("/api/active", obj, {
          callback: function (data) {
            _this.setUserInfo(null);
            localStorage.removeItem("app-token");
            localStorage.setItem("app-token", data.access_token);
            window.location.href = "/";
          },
          error: function (error) {
            _this.errorMessage = error;
          },
          returnLogin: false
        });
      } else {
        let obj = {
          username: this.login.email,
          password: this.login.password,
        };
        this.post("/auth/login", obj, {
          callback: function (data) {
            _this.setUserInfo(null);
            localStorage.removeItem("app-token");
            localStorage.setItem("app-token", data.access_token);
            _this.$router.go(0);
          },
          error: function (error) {
            _this.errorMessage = error;
          },
          returnLogin: false
        });
      }
      
    },
    getQueryVariable(variable){
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
          let pair = vars[i].split("=");
          if(pair[0] == variable){
            return pair[1];
          }
       }
       return undefined;
    },
    ...mapMutations({
      setUserInfo: "setUserInfo"
    }),
  },
};
</script>

<style lang="scss">
.loading-overlay {
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 30%;
}
</style>


