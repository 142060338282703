<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    persistent
    v-model="shown"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container fill-height>
            <v-row v-for="schema in itemSchemas" :key="schema.name">
              <v-text-field
                v-if="showField(schema, 'string', operator)"
                v-model="entity[schema.value]"
                :label="schema.text"
                :readonly="isReadonly(schema)"
                :rules="[rules.required]"
              ></v-text-field>
              <v-select
                v-if="showField(schema, 'dropdown', operator)"
                :items="schema.dropdown"
                :label="schema.text"
                item-text="name"
                item-value="value"
                v-model="entity[schema.value]"
                :readonly="isReadonly(schema)"
                :rules="[rules.required]"
              ></v-select>
              <v-text-field
                v-model="entity[schema.value]"
                v-if="showField(schema, 'number', operator)"
                :label="schema.text"
                :readonly="isReadonly(schema)"
                :rules="[rules.required]"
                single-line
                type="number"
              />
              <v-file-input
                show-size
                v-model="entity[schema.value]"
                v-if="showField(schema, 'file', operator)"
                :label="schema.text"
                :readonly="isReadonly(schema)"
                :rules="[rules.required]"
                @change="
                  (files) => {
                    uploadFile(schema.value, files);
                  }
                "
              />
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-for="action in actions"
          v-bind:key="action.name"
          color="primary"
          @click="invokeAction(action.name, entity)"
        >
          {{ action.name }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :disabled="!valid"
          text
          @click="saveDialog()"
        >
          {{ operator }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="closeDialog()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InputDialog",
  props: {
    title: {
      type: String,
    },
    actions: {},
    itemSchemas: {},
  },
  data() {
    return {
      valid: true,
      shown: "",
      operator: "",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    showField(schema, type, operator) {
      if (schema.type != type) {
        return false;
      }

      if (schema["on" + operator] == undefined) {
        return true;
      } else {
        return schema["on" + operator];
      }
    },
    isKey(schema) {
      if (schema["isKey"] == true) {
        return true;
      } else {
        return false;
      }
    },
    isReadonly(schema) {
      if (schema["readonly"] == undefined) {
        return this.isKey(schema);
      } else {
        return schema["readonly"];
      }
    },
    saveDialog() {
      this.$refs.form.validate();
      if (this.valid) {
        this.entity = this.commitEntity();
        this.$emit("dialog-commit", this.operator, this.entity);
        this.closeDialog();
      }
    },
    showDialog(op, entity) {
      this.operator = op;
      this.entity = Object.assign({}, entity);
      this.entity = this.loadEntity();
      this.shown = "true";
    },
    closeDialog() {
      this.shown = "";
    },
    invokeAction(name) {
      this.$refs.form.validate();
      if (this.valid) {
        this.entity = this.commitEntity();
        this.$emit("dialog-action", name, this.entity);
      }
    },
    uploadFile(field, files) {
      this.$emit("upload-file", field, files);
    },
    commitEntity() {
      let entity = Object.assign({}, this.entity);
      this.itemSchemas.forEach((schema) => {
        if (schema.category != undefined) {
          if (entity[schema.category] == undefined) {
            entity[schema.category] = {};
          }
          entity[schema.category][schema.value] = entity[schema.value];
        }
      });
      return entity;
    },
    loadEntity() {
      let entity = Object.assign({}, this.entity);
      this.itemSchemas.forEach((schema) => {
        if (schema.category != undefined) {
          if (entity[schema.category] != undefined) {
            entity[schema.value] = entity[schema.category][schema.value];
          }
        }
      });
      return entity;
    },
  },
};
</script>
