module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
        "c_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
        "c_startAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start At"])},
        "c_endAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End At"])}
      },
      "cn": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
        "c_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知内容"])},
        "c_startAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始生效时间"])},
        "c_endAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束生效时间"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"notice":"Notice","c_message":"Message","c_startAt":"Start At","c_endAt":"End At"},"cn":{"notice":"通知","c_message":"通知内容","c_startAt":"开始生效时间","c_endAt":"结束生效时间"}}')
  delete Component.options._Ctor
  
}
