<template>
  <v-container class="pa-4">
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{
            $t("title_welcome", [user["nickname"]])
          }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <number-grow
          :value="userCount"
          :title="$t('userCount')"
          :unit="$t('unitPeople')"
        ></number-grow>
      </v-col>
      <v-col cols="4">
        <number-grow
          :value="lawyerCount"
          :title="$t('lawyerCount')"
          :unit="$t('unitPeople')"
        ></number-grow>
      </v-col>
      <v-col cols="4">
        <number-grow
          :value="orderCount"
          :title="$t('orderCount')"
          :unit="$t('unitItem')"
        ></number-grow>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <number-grow
          :value="amount"
          :title="$t('amount')"
          :unit="$t('unitMoneyRMB')"
        ></number-grow>
      </v-col>
      <v-col cols="4">
        <number-grow
          :value="approveLawyer"
          :title="$t('approveLawyer')"
          :unit="$t('unitPeople')"
        ></number-grow>
      </v-col>
      <v-col cols="4">
        <number-grow
          :value="approvePayment"
          :title="$t('approvePayment')"
          :unit="$t('unitItem')"
        ></number-grow>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <chart-part-daily-report></chart-part-daily-report>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import NumberGrow from "../components/NumberGrow.vue";
import ChartPartDailyReport from "./ChartPartDailyReport.vue";
export default {
  components: { NumberGrow, ChartPartDailyReport },
  name: "Home",
  data: () => ({
    userCount: 0,
    lawyerCount: 0,
    orderCount: 0,
    amount: 0,
    approveLawyer: undefined,
    approvePayment: undefined,
    user: {},
    myOpenedTaskCriteria: {},
  }),
  watch: {
    approveLawyer: function (val) {
      this.$root.$emit("update-tab", {
        name: "approveLawyer",
        count: val,
      })
    },
    approvePayment: function (val) {
      this.$root.$emit("update-tab", {
        name: "payment",
        count: val,
      })
    },
  },
  computed: {},
  mounted() {
    let _this = this;
    this.get("/api/statistic/common", {
      callback: function (data) {
        data.data.forEach((item) => {
          if (item.name == "user_count") {
            _this.userCount = item.value;
          } else if (item.name == "lawyer_count") {
            _this.lawyerCount = item.value;
          } else if (item.name == "order_count") {
            _this.orderCount = item.value;
          } else if (item.name == "amount") {
            _this.amount = item.value;
          } else if (item.name == "approveLawyer") {
            _this.approveLawyer = item.value;
          } else if (item.name == "approvePayment") {
            _this.approvePayment = item.value;
          }
        });
      },
    });
    let user = this.$store.getters.getUserInfo;
    if (user == undefined || user == null) {
      this.get("/api/user/current", {
        callback: function (data) {
          _this.user = data.data;
          _this.setUserInfo(data.data);
          _this.$root.$emit("change-lang", _this.user.language);
        },
      });
    } else {
      this.user = user;
    }

    this.updateCriterias();
  },
  methods: {
    // getCurrentUser() {

    // },
    updateCriterias() {
      this.myOpenedTaskCriteria = [
        {
          operator: "=",
          type: "number",
          value: this.user["id"],
          column: "ownerId",
        },
        {
          operator: "<>",
          type: "string",
          value: "completed",
          column: "taskStatus",
        },
      ];
    },
    ...mapMutations({
      setUserInfo: "setUserInfo",
    }),
  },
};
</script>

<style scoped>
.text-nowrap {
  white-space: nowrap;
}
</style>
