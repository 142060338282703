import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import zhHans from "vuetify/es5/locale/zh-Hans";
import en from "vuetify/es5/locale/en";
import '@mdi/font/css/materialdesignicons.css' 

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    current: "zhHans",
    locales: { zhHans, en },
  },
  buttons: {
    capitalize: false,
  },
  theme: {
    // dark: true,
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
