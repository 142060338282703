import Vue from "vue";

const install = function (Vue) {
  Vue.prototype.get = function (url, options) {
    this.request(url, "get", null, options);
  };

  Vue.prototype.post = function (url, body, options) {
    this.request(url, "post", body, options);
  };

  Vue.prototype.put = function (url, body, options) {
    this.request(url, "put", body, options);
  };

  Vue.prototype.delete = function (url, options) {
    this.request(url, "delete", null, options);
  };

  Vue.prototype.upload = function (url, file, body, options) {
    if (options == undefined) {
      options = {};
    }
    options.file = file;
    this.request(url, "upload", body, options);
  }

  Vue.prototype.request = function (url, method, body, options) {
    let _this = this;
    if (options != undefined && options.precall != undefined) options.precall();
    let access_token = localStorage.getItem("app-token");
    let config = options.config;
    let download = options.download;
    if (config == undefined) {
      config = {};
    }
    if (access_token != null) {
      if (config["headers"] == undefined) {
        config["headers"] = {};
      }
      config["headers"]["Authorization"] = "Bearer " + access_token;
    }
    if (download == true) {
      config["responseType"] = 'blob';
    }
    let result = null;
    if (method == "get") {
      result = this.$axios.get(url, config );
    } else if (method == "post") {
      result = this.$axios.post(url, body, config);
    } else if (method == "put") {
      result = this.$axios.put(url, body, config);
    } else if (method == "delete") {
      result = this.$axios.delete(url, config);
    } else if (method == "upload") {
      let formData = new FormData();
      formData.append("file", options.file);
      if (body != undefined) {
        formData.append("properties", new Blob(
          [JSON.stringify(body)], 
          {
            type: "application/json"
          }));
      }
      result = this.$axios.post(url, formData, config);
    }

    if (result == null) {
      return;
    }

    this.loading = true;
    result.then(
      (res) => {
        if (download == true) {
          options.callback(new Blob([res.data]));
        } else {
          if (res.data.success) {
            _this.$root.$emit("show-info", {
              type: "success",
              message: `${url} is success.`,
            });
            if (options != undefined && options.callback != undefined) {
              options.callback(res.data);
            }
          } else {
            _this.$root.$emit("show-error", {
              type: "failed",
              message: res.data.message,
            });
          }
        }
        
        _this.loading = false;
        if (options != undefined && options.postcall != undefined)
          options.postcall();
      },
      (err) => {
        _this.loading = false;
        _this.$root.$emit("show-error", err);
        if (options != undefined) {
          if (options.postcall != undefined) {
            options.postcall();
          }
          if (options.error != undefined) {
            options.error(err);
          }

          if ((options.returnLogin == undefined || options.returnLogin) && err.response.status == "403") {
            // localStorage.removeItem("app-token");
            // _this.$router.go(0);
            _this.$root.$emit("show-expired-dialog");
          }
        }
      }
    );
  };
};

Vue.use(install);

export default install;
