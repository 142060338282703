const TYPE_WIDTH = 1;
const TYPE_HEIGHT = 2;
class LayoutService {
  initLayout(className, totalSize, gridCount, type) {
    for (let i = 1; i <= gridCount; i++) {
      this.layoutItem(className, totalSize, gridCount, i, type);
    }
  }

  layoutItem(className, totalSize, gridCount, size, type) {
    const elements = document.querySelectorAll("." + className + "-" + size);
    for (let i = 0; i < elements.length; i++) {
      if (TYPE_HEIGHT == type) {
        elements[i].style.height = (totalSize / gridCount) * size + "px";
      } else if (TYPE_WIDTH == type) {
        elements[i].style.width = (totalSize / gridCount) * size + "px";
      }
    }
  }
}

export { LayoutService, TYPE_WIDTH, TYPE_HEIGHT };
