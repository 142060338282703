<template>
  <v-form class="ma-3" v-model="valid" lazy-validation>
    <v-row no-gutters>
      <v-alert v-model="alert" width="100%" dense outlined type="error" :close-text="$t('close')" dismissible>{{ errorMessage }}</v-alert>
    </v-row>
    <v-row no-gutters>
      <v-col
        v-for="(schema, index) in shownSchemas"
        :key="schema.name + `${index}`"
        :cols="12 / colPerRow"
      >
        <v-text-field
          v-if="showField(schema, 'string')"
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :clearable="!isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          hide-details="auto"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-text-field>
        <v-text-field
          v-if="showField(schema, 'image')"
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :clearable="!isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          hide-details="auto"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-text-field>
        <v-img
          v-if="showField(schema, 'image')"
          max-height="150"
          max-width="250"
          class="field-img"
          contain
          :src="schema.getURL == undefined ? null : schema.getURL(entity[schema.value])"
        ></v-img>
        <password-input
          v-if="showField(schema, 'password')"
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :clearable="!isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :text="schema.text"
          :reverse="reverse"
          @change="onChange(schema)"
        >
        </password-input>
        <v-text-field
          v-if="showField(schema, 'calculate')"
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :clearable="!isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          hide-details="auto"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-text-field>
        <v-textarea
          v-if="showField(schema, 'text')"
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :clearable="!isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :no-resize="isReadonly(schema)"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-textarea>
        <v-menu
          v-if="showField(schema, 'date')"
          v-model="schema.menu"
          :ref="`menu-${schema.value}`"
          :disabled="isReadonly(schema)"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="entity[schema.value]"
              readonly
              :clearable="!isReadonly(schema)"
              :solo="isReadonly(schema)"
              :flat="isReadonly(schema)"
              :outlined="!isReadonly(schema)"
              :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
              :label="$vuetify.breakpoint.mobile ? schema.text : ''"
              dense
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend>
                <v-text-field
                  class="field-label pt-1"
                  v-show="!$vuetify.breakpoint.mobile"
                  solo
                  flat
                  readonly
                  :reverse="reverse"
                  dense
                  :value="schema.text + ':'"
                >
                </v-text-field>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="entity[schema.value]"
            @change="dateChanged(schema)"
            :locale="getPickerlocale()"
          ></v-date-picker>
        </v-menu>
        <v-select
          v-if="showField(schema, 'dropdown')"
          :items="
            typeof schema.dropdown === 'function'
              ? schema.dropdown(entity)
              : schema.dropdown
          "
          :no-data-text="$t('no_data_text')"
          :item-text="schema.itemText == undefined ? 'text' : schema.itemText"
          :item-value="
            schema.itemValue == undefined ? 'value' : schema.itemValue
          "
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :append-icon="isReadonly(schema) ? '' : 'mdi-menu-down'"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="dropdownChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-select>
        <v-autocomplete
          v-if="showField(schema, 'dropdown', 'action')"
          :no-data-text="$t('no_data_text')"
          :items="
            typeof schema.dropdown === 'function'
              ? schema.dropdown(entity)
              : schema.dropdown
          "
          :item-text="schema.itemText == undefined ? 'text' : schema.itemText"
          :item-value="
            schema.itemValue == undefined ? 'value' : schema.itemValue
          "
          v-model="entity[schema.value]"
          :readonly="isReadonly(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :append-icon="isReadonly(schema) ? '' : 'mdi-menu-down'"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="dropdownChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
          <template v-if="!isReadonly(schema)" v-slot:append-outer>
            <v-btn
              fab
              icon
              outlined
              rounded
              tile
              small
              class="action-btn"
              @click="invokeItemAction(schema, entity)"
            >
              <v-icon dark> mdi-magnify </v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
        <v-text-field
          v-model="entity[schema.value]"
          v-if="showField(schema, 'number')"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-text-field>
        <v-file-input
          show-size
          v-model="entity[schema.value]"
          v-if="showField(schema, 'file')"
          :readonly="isReadonly(schema)"
          :rules="isReadonly(schema) ? [] : getRules(schema)"
          :solo="isReadonly(schema)"
          :flat="isReadonly(schema)"
          :outlined="!isReadonly(schema)"
          :class="$vuetify.breakpoint.mobile ? 'pt-2': ''"
          :label="$vuetify.breakpoint.mobile ? schema.text : ''"
          dense
          @change="onChange(schema)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="field-label pt-1"
              v-show="!$vuetify.breakpoint.mobile"
              solo
              flat
              readonly
              :reverse="reverse"
              dense
              :value="schema.text + ':'"
            >
            </v-text-field>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import PasswordInput from "./inputs/PasswordInput.vue";

export default {
  components: { PasswordInput },
  props: {
    itemSchemas: {},
    readonly: {},
    colPerRow: {
      type: Number,
      default: 2,
    },
    reverse: {
      default: true,
    },
  },
  computed: {},
  data() {
    return {
      alert: false,
      valid: true,
      errorMessage: "",
      shownSchemas: [],
      entity: [],
      groups: {},
      rules: {
        required: (value) => !!value || this.$t("required"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("rule_email"),
        number: (v) =>
          /\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*/.test(v) ||
          this.$t("rule_number"),
      },
    };
  },
  watch: {
    entity: {
      deep: true,
      handler(newEntity) {
        this.itemSchemas.forEach((schema) => {
          if (schema.calculate != undefined) {
            newEntity[schema.value] = schema.calculate(newEntity);
          }
        });
      },
    },
  },
  methods: {
    getPickerlocale() {
      return this.$vuetify.lang.current == "zhHans" ? "zh-cn" : "en";
    },
    dropdownChange(schema) {
      let isUpdate = this.updateGroup(schema);
      if (isUpdate) {
        this.shownSchemas = this.buildShownSchemas();
      }
      this.$emit("change", this.entity, schema);
    },
    onChange(schema) {
      if (this.autoValidate) {
        this.$refs.mainForm.validate();
      }
      this.$emit("change", this.entity, schema);
    },
    updateGroups() {
      this.itemSchemas.forEach((schema) => {
        this.updateGroup(schema);
      });
    },
    updateGroup(schema) {
      if (schema.groupHeader != undefined) {
        this.groups[schema.groupHeader] = this.entity[schema.value];
        return true;
      } else {
        return false;
      }
    },
    buildShownSchemas() {
      let filteredSchemas = [];
      let _this = this;
      this.itemSchemas.forEach((schema) => {
        if (schema.hidden) {
          //skip
        } else if (schema.group == undefined) {
          filteredSchemas.push(schema);
        } else {
          let group = schema.group;
          if (_this.groups[group] == undefined) {
            filteredSchemas.push(schema);
          } else if (_this.groups[group] == schema.groupValue) {
            filteredSchemas.push(schema);
          } else if (
            Array.isArray(schema.groupValue) &&
            schema.groupValue.filter((e) => e === _this.groups[group]).length >
              0
          ) {
            filteredSchemas.push(schema);
          } else {
            //continue
          }
        }
      });
      return filteredSchemas;
    },
    showField(schema, type, action) {
      if (schema.type != type) {
        return false;
      }
      if (schema.show != undefined) {
        if (typeof schema.show === 'function') {
          return schema.show(this.entity);
        } else if(!schema.show){
          return false;
        }
      }
      if (action != undefined && schema.externalAction == undefined) {
        return false;
      } else if (action == undefined && schema.externalAction != undefined) {
        return false;
      }

      return true;
    },
    isKey(schema) {
      if (schema["isKey"] == true) {
        return true;
      } else {
        return false;
      }
    },
    isReadonly(schema) {
      if (this.readonly) {
        return true;
      }

      if (schema["calculate"] != undefined) {
        return true;
      } else if (schema["readonly"] == undefined) {
        return this.isKey(schema);
      } else {
        return schema["readonly"];
      }
    },
    getRules(schema) {
      let rules = [];
      if (schema["required"] != undefined && schema["required"]) {
        rules.push(this.rules.required);
      }

      if (schema.type == "number") {
        rules.push(this.rules.number);
      }

      if (schema["rules"] != null) {
        schema["rules"].forEach((rule) => {
          rules.push(this.rules[rule]);
        });
      }

      return rules;
    },
    invokeAction(name) {
      this.$refs.form.validate();
      if (this.valid) {
        this.entity = this.commitEntity();
        this.$emit("dialog-action", name, this.entity);
      }
    },
    invokeItemAction(schema) {
      let entity = this.entity;
      let value = this.entity[schema.value];
      this.$emit("item-action", schema, value, (result) => {
        entity[schema.value] = result;
      });
    },
    dropdownName: function (item, locale) {
      console.log(locale);
      if (item.name != undefined) {
        if (item.name[locale] != undefined) {
          return item.name[locale];
        } else {
          return item.name;
        }
      }

      return item;
    },
    commitEntity() {
      let entity = {};
      this.itemSchemas.forEach((schema) => {
        if (schema.value == "HOLDER") {
          return;
        }
        let value = this.entity[schema.value];
        if (schema.readonly && !schema.showUpdate) {
          value = null;
        }
        entity[schema.value] = value;
        if (schema.category != undefined) {
          if (entity[schema.category] == undefined) {
            entity[schema.category] = {};
          }
          entity[schema.category][schema.value] = value;
        }
      });
      return entity;
    },
    loadEntity(entity) {
      let localEntity = {};
      this.itemSchemas.forEach((schema) => {
        if (schema.value == "HOLDER") {
          return;
        }
        let value = entity[schema.value];
        if (schema.category != undefined) {
          if (entity[schema.category] != undefined) {
            value = entity[schema.category][schema.value];
          }
        }
        localEntity[schema.value] = this.parseValue(value, schema);
        schema.menu = false;
      });
      this.$set(this, "entity", localEntity);
      this.updateGroups();
      this.shownSchemas = this.buildShownSchemas();
    },
    parseValue(value, schema) {
      if (schema.type == "date") {
        if (value == undefined) return null;
        let pattern =
          schema.pattern == undefined ? "YYYY-MM-DD" : schema.pattern;
        var stillUtc = moment.utc(value).toDate();
        return moment(stillUtc).local().format(pattern);
      } else {
        return value;
      }
    },
    dateChanged(schema) {
      this.$refs[`menu-${schema.value}`][0].isActive = false;
    },
    setErrorMessage(errorMessage) {
      this.alert = true;
      this.errorMessage = errorMessage;
    },
    resetErrorMessage() {
      this.alert = false;
    },
    // clickPasswordShow(schema) {
    //   schema['showpassword'] = schema['showpassword'] == true ? true : false;
    //   this.$refs[`menu-${schema.value}`][0].$el["appendIcon"] = schema['showpassword'] == true ? 'mdi-eye' : 'mdi-eye-off';
    //   // this.$refs[`menu-${schema.value}`][0].type = schema['showpassword'] == true ? 'text' : 'password';
    // },
  },
  mounted() {
    this.updateGroups();
    this.shownSchemas = this.buildShownSchemas();
  },
};
</script>

<style>
.field-label {
  width: 200px;
  margin-top: -12px !important;
}
.v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}
.action-btn {
  margin-top: -8px;
}
.field-img {
  margin-left: 200px;
}
</style>

<i18n>
{
  "en": {
    "required": "Required.",
    "rule_email": "E-mail must be valid",
    "rule_number": "Number must be valid",
    "no_data_text" : "No data available"
  },
  "cn": {
    "required": "必选项",
    "rule_email": "E-mail不合法",
    "rule_number": "必须是数字",
    "no_data_text" : "没有数据"
  }
}
</i18n>
