<template>
  <v-container fluid>
    <v-navigation-drawer app fixed clipped>
      <v-tabs vertical show-arrows class="mt-2">
        <v-tab
          v-for="item in settingMenus"
          :key="item.name"
          link
          @click="clickSettingMenu(item)"
          class="text-h6"
          active-class="tab-active"
        >
          {{ $t(item.title) }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>
    <div class="main-container" fill-height>
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>

export default {
  components: { },
  data: () => ({
    title: "Settings",
    select: true,
    expendOpen: false,
    settingMenus: [
      {
        name: "user",
        title: "tab_user_management",
        url: "/Setting/User",
      },
      {
        name: "template",
        title: "tab_template_management",
        url: "/Setting/Template",
      },
      {
        name: "system",
        title: "tab_system_management",
        url: "/Setting/System",
      },
      {
        name: "setting",
        title: "tab_setting_management",
        url: "/Setting/SettingPart",
      },
    ],
  }),
  mounted() {
    this.updateSchemaData();
  },
  computed: {
    
  },
  methods: {
    updateSchemaData() {
    },
    clickSettingMenu(primaryMenu) {
      this.$router.push(primaryMenu.url);
    },
  },
};
</script>

<style scoped>
.tab-active {
  background-color: #272727;
  color: white;
}
</style>
