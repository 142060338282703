<template>
  <v-container fluid>
    <!-- <v-navigation-drawer app fixed clipped width="400">
      <template v-slot:prepend :elevation="3">
        <v-list-item>
          <v-list-item-title>{{ $t("search") }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-slot:append :elevation="3">
        <v-divider></v-divider>
        <div class="d-flex pa-4">
          <v-btn color="primary" class="mr-4" @click="filter()">{{
            $t("apply_filter")
          }}</v-btn>
          <v-btn color="primary" @click="clearFilter()">{{
            $t("cancel")
          }}</v-btn>
        </div>
      </template>

      <div class="pa-2">
        <filter-part ref="filterView"> </filter-part>
      </div>
    </v-navigation-drawer> -->
    <div class="main-container" fill-height>
      <v-row class="ma-0 mb-2">
        <v-card class="form-container">
          <v-card-title class="flex justify-space-between">
            <v-btn
              color="primary"
              :outlined="selectedIdx != 0"
              @click="selectIdx(0)"
              >申请结算</v-btn
            >
            <v-btn
              color="primary"
              :outlined="selectedIdx != 1"
              @click="selectIdx(1)"
              >未结算</v-btn
            >
            <v-btn
              color="primary"
              :outlined="selectedIdx != 2"
              @click="selectIdx(2)"
              >已结算</v-btn
            >
            <v-btn
              color="primary"
              :outlined="selectedIdx != 3"
              @click="selectIdx(3)"
              >全部
            </v-btn>
          </v-card-title>
        </v-card>
      </v-row>
      <entity-view
        :item-schemas="itemSchemas"
        :readonly="readonly"
        :items="items"
        :title="$t('invoice')"
        :pagination="pagination"
        :externalActions="externalActions"
        :options="options"
        @items-refresh="itemsRefresh"
        @item-action="itemAction"
        @page-change="pageChange"
        @double-click-row="clickRow"
      >
        <template v-slot:icon="item">
          <v-icon v-if="item.value" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-if="!item.value" small color="error">
            mdi-close-circle
          </v-icon>
        </template>
      </entity-view>
    </div>
    <input-dialog></input-dialog>
  </v-container>
</template>

<script>
import EntityView from "../components/EntityView.vue";
import InputDialog from "../components/InputDialog.vue";
// import FilterPart from "./FilterPart.vue";
import InvoiceStatus from "../common/invoice_status";

export default {
  components: { EntityView, InputDialog },
  props: {
    type: {
      default: "user",
    },
  },
  data: () => ({
    title: "Lawyer",
    select: true,
    expendOpen: false,
    readonly: true,
    selectedIdx: 0,
    expendFilter: {
      effected: {
        last: 10,
      },
    },
    pagination: {
      current: 1,
      pageCount: 1,
      pageSize: 10,
    },
    itemSchemas: [
      {
        name: "id",
        value: "id",
        type: "string",
      },
      {
        name: "orderNo",
        value: "orderNo",
        type: "string",
      },
      {
        name: "orderId",
        value: "orderId",
        type: "string",
      },
      {
        name: "userId",
        value: "userId",
        type: "string",
      },
      {
        name: "userName",
        value: "userName",
        type: "string",
      },
      {
        name: "amount",
        value: "amount",
        type: "number",
      },
      {
        name: "createdAt",
        value: "createdAt",
        type: "datetime",
      },
      {
        name: "status",
        value: "status",
        type: "dropdown",
        localized: true,
        dropdown: InvoiceStatus.values,
      },
    ],
    items: [],
    externalActions: [],
  }),
  mounted() {
    this.updateSchemaData();
    this.refresh();
  },
  computed: {
    options: function () {
      return {
        dateTimePattern: this.$root.$config.dateTimePattern,
        datePattern: this.$root.$config.datePattern,
        showAction: false,
      };
    },
  },
  methods: {
    updateSchemaData() {
      this.localizeSchema(this.itemSchemas);
      // this.$refs.filterView.loadSchema(this.itemSchemas);
    },
    refresh() {
      let _this = this;
      let postData = {
        pagination: this.pagination,
        criterias: [], //this.$refs.filterView.getCriterias(),
      };
      if (postData.criterias != undefined) {
        let status = null;
        if (this.selectedIdx == 0) {
          status = "request";
        } else if (this.selectedIdx == 1) {
          status = "new";
        } else if (this.selectedIdx == 2) {
          status = "finish";
        }

        if (status != null) {
          postData.criterias.push({
            operator: "=",
            type: "string",
            value: status,
            column: "status",
            condition: "and",
          });
        }
        postData.criterias.push({
          operator: "=",
          type: "string",
          value: this.type,
          column: "type",
          condition: "and",
        });
      }
      this.post("/api/invoices", postData, {
        callback: function (data) {
          let pg = {
            pageCount: data.data.pages,
            pageSize: data.data.size,
            current: data.data.current,
          };
          _this.pagination = pg;
          _this.items = data.data.records;
        },
      });
    },
    itemsRefresh() {
      this.refresh();
    },
    clickRow(item) {
      this.$router.push({
        name: "UserInvoiceDetail",
        params: {
          id: item.id + "",
          role: "user",
        },
      });
    },
    filter() {
      this.refresh();
    },
    clearFilter() {
      this.$refs.filterView.clear();
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.refresh();
    },
    itemAction(name) {
      if (name === "insert") {
        this.itemCreate();
      }
    },
    selectIdx(idx) {
      this.selectedIdx = idx;
      this.refresh();
    },
  },
};
</script>

<style scoped>
.filter-label {
  margin-left: -12px !important;
}
</style>

<i18n>
{
    "en": {
    "c_enable": "Is Online",
    "c_userId": "UserId"
  },
  "cn": {
    "c_enable": "是否在线",
    "c_userId": "客户ID"
  }
}
</i18n>
