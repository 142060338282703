export default {
  data: [
    {
      name: "home",
      title: "home",
      url: "Home",
    },
    {
      name: "customer",
      title: "customer",
      url: "Customer",
    },
    {
      name: "lawyer",
      title: "lawyer",
      url: "Lawyer",
    },
    {
      name: "approveLawyer",
      title: "draftLawyer",
      url: "DraftLawyer",
    },
    {
      name: "order",
      title: "order",
      url: "Order",
    },
    {
      name: "payment",
      title: "payment",
      url: "Payment",
    },
    {
      name: "userInvoice",
      title: "userInvoice",
      url: "UserInvoice",
    },
    {
      name: "lawyerInvoice",
      title: "lawyerInvoice",
      url: "LawyerInvoice",
    },
    {
      name: "service",
      title: "service",
      url: "Product",
    },
    {
      name: "case",
      title: "case",
      url: "Case",
    },
    {
      name: "notice",
      title: "notice",
      url: "Notice",
    },
    {
      name: "feedback",
      title: "feedback",
      url: "Feedback",
    },
  ],
};
