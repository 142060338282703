import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import httpex from './plugins/httpex'
import localeex from './plugins/localeex'
import message from './plugins/message'
import i18n from './i18n'
import config from "./config/config";
// import message from "./config/message";
import * as echarts from 'echarts'
import moment from 'moment'
import commonType from './common/common_type';

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$config = config;
Vue.prototype.$commonType = commonType;

// Vue.prototype.$message = message;

Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss', utc = false) {
  if (utc) {
    return moment(daraStr).format(pattern);
  } else {
    var stillUtc = moment.utc(daraStr).toDate();
    return moment(stillUtc).local().format(pattern);
  }
})

new Vue({
  store,
  router,
  vuetify,
  httpex,
  message,
  localeex,
  i18n,
  render: h => h(App),
  mounted() {
    this.$root.$vuetify.lang.current = this.$i18n.locale == "cn" ? "zhHans" : "en";
  },
}).$mount('#app')